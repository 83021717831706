/* Lists */

.list-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
}

.list-group-item-action {
    width: 100%;
    color: #77838f;
    text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
    z-index: 1;
    color: #377dff;
    text-decoration: none;
    background-color: transparent;
}

.list-group-item-action:active {
    color: #377dff;
    background-color: transparent;
}

.list-group-item {
    position: relative;
    display: block;
    padding: 0.425rem 1.25rem;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid #e7eaf3;
}

.list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.list-group-item.disabled,
.list-group-item:disabled {
    color: #8c98a4;
    pointer-events: none;
    background-color: #fff;
}

.list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #377dff;
    border-color: #377dff;
}

.list-group-horizontal {
    -ms-flex-direction: row;
    flex-direction: row;
}

.list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
}

.list-group-horizontal .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
}

.list-group-horizontal .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
}

@media (min-width: 576px) {
    .list-group-horizontal-sm {
        -ms-flex-direction: row;
        flex-direction: row;
    }
    .list-group-horizontal-sm .list-group-item {
        margin-right: -1px;
        margin-bottom: 0;
    }
    .list-group-horizontal-sm .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
    }
    .list-group-horizontal-sm .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0;
    }
}

@media (min-width: 768px) {
    .list-group-horizontal-md {
        -ms-flex-direction: row;
        flex-direction: row;
    }
    .list-group-horizontal-md .list-group-item {
        margin-right: -1px;
        margin-bottom: 0;
    }
    .list-group-horizontal-md .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
    }
    .list-group-horizontal-md .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0;
    }
}

@media (min-width: 992px) {
    .list-group-horizontal-lg {
        -ms-flex-direction: row;
        flex-direction: row;
    }
    .list-group-horizontal-lg .list-group-item {
        margin-right: -1px;
        margin-bottom: 0;
    }
    .list-group-horizontal-lg .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
    }
    .list-group-horizontal-lg .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0;
    }
}

@media (min-width: 1200px) {
    .list-group-horizontal-xl {
        -ms-flex-direction: row;
        flex-direction: row;
    }
    .list-group-horizontal-xl .list-group-item {
        margin-right: -1px;
        margin-bottom: 0;
    }
    .list-group-horizontal-xl .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
    }
    .list-group-horizontal-xl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0;
    }
}

.list-group-flush .list-group-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0;
}

.list-group-flush .list-group-item:last-child {
    margin-bottom: -1px;
}

.list-group-flush:first-child .list-group-item:first-child {
    border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom: 0;
}

.list-group-item-primary {
    color: #1d4185;
    background-color: #c7dbff;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
    color: #1d4185;
    background-color: #aecbff;
}

.list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #1d4185;
    border-color: #1d4185;
}

.list-group-item-secondary {
    color: #3e444a;
    background-color: #d9dce0;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
    color: #3e444a;
    background-color: #cbcfd5;
}

.list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #3e444a;
    border-color: #3e444a;
}

.list-group-item-success {
    color: #006957;
    background-color: #b8f0e6;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
    color: #006957;
    background-color: #a3ecdf;
}

.list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #006957;
    border-color: #006957;
}

.list-group-item-info {
    color: #007483;
    background-color: #b8f6fe;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
    color: #007483;
    background-color: #9ff3fe;
}

.list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #007483;
    border-color: #007483;
}

.list-group-item-warning {
    color: #856404;
    background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404;
}

.list-group-item-danger {
    color: #73231d;
    background-color: #f6cbc7;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
    color: #73231d;
    background-color: #f2b7b1;
}

.list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #73231d;
    border-color: #73231d;
}

.list-group-item-light {
    color: #818182;
    background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182;
}

.list-group-item-dark {
    color: #101112;
    background-color: #c0c1c1;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
    color: #101112;
    background-color: #b3b4b4;
}

.list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #101112;
    border-color: #101112;
}

.list-group-item-white {
    color: #858585;
    background-color: white;
}

.list-group-item-white.list-group-item-action:hover,
.list-group-item-white.list-group-item-action:focus {
    color: #858585;
    background-color: #f2f2f2;
}

.list-group-item-white.list-group-item-action.active {
    color: #fff;
    background-color: #858585;
    border-color: #858585;
}

.list-group-item-indigo {
    color: #170b44;
    background-color: #c4bddc;
}

.list-group-item-indigo.list-group-item-action:hover,
.list-group-item-indigo.list-group-item-action:focus {
    color: #170b44;
    background-color: #b5acd3;
}

.list-group-item-indigo.list-group-item-action.active {
    color: #fff;
    background-color: #170b44;
    border-color: #170b44;
}


/*------------------------------------
  List Group
------------------------------------*/

.list-group {
    list-style: none;
}

.list-group .list-group {
    margin-left: 1rem;
}

.list-group .list-group-item {
    font-size: 0.875rem;
}

.list-group .list-group-icon {
    min-width: 1rem;
    max-width: 1rem;
    color: #77838f;
    text-align: center;
    margin-right: 0.5rem;
}

.list-group .active>.list-group-item {
    color: #377dff;
}

.list-group .active>.list-group-text {
    color: #fff;
}

.list-group .disabled .u-icon {
    color: #77838f;
    background-color: rgba(119, 131, 143, 0.1);
}


/* Border Radius */

.list-group-rounded-0 .list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.list-group-rounded-0 .list-group-item:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}


/* Transparent */

.list-group-transparent .list-group-item {
    border-color: transparent;
    background-color: transparent;
}


/* White */

.list-group-white .list-group-item,
.list-group-white .list-group-item-action {
    color: #fff;
}

.list-group-white .list-group-item[href],
.list-group-white .list-group-item-action[href] {
    color: rgba(255, 255, 255, 0.7);
}

.list-group-white .list-group-item[href]:hover,
.list-group-white .list-group-item-action[href]:hover {
    color: #fff;
}

.list-group-white.list-group-striped .list-group-item:nth-child(even) {
    background-color: rgba(255, 255, 255, 0.1);
}

.list-group-white .active>.list-group-item {
    color: #fff;
}


/* Flush */

.list-group-flush .list-group-item {
    padding-left: 0;
    padding-right: 0;
}

.list-group-flush .list-group-item.active {
    color: #377dff;
    background-color: transparent;
    border-color: transparent;
}


/* Borderless */

.list-group-borderless .list-group-item {
    border: none;
}


/* Striped */

.list-group-striped .list-group-item:nth-child(even) {
    background-color: #f8fafd;
}


/* end lists */