/* Colores */


/*
darken( $base-color, 10% )
lighten( $base-color, 10% )

saturate( $base-color, 30% )
desaturate( $base-color, 30% )

adjust-hue( $base-color, 30% )

tint( $base-color, 10% )
shade( $base-color, 10% )
*/

.neat-primary {
    background-color: $neat-color-blue;
}

.neat-primary-darken-5 {
    background-color: darken($neat-color-blue, 5%);
}

.neat-primary-lighten-5 {
    background-color: lighten($neat-color-blue, 5%);
}

.neat-primary-saturate-5 {
    background-color: saturate($neat-color-blue, 5%);
}

.neat-primary-desaturate-5 {
    background-color: desaturate($neat-color-blue, 5%);
}

.neat-primary-darken-10 {
    background-color: darken($neat-color-blue, 10%);
}

.neat-primary-lighten-10 {
    background-color: lighten($neat-color-blue, 10%);
}

.neat-primary-saturate-10 {
    background-color: saturate($neat-color-blue, 10%);
}

.neat-primary-desaturate-10 {
    background-color: desaturate($neat-color-blue, 10%);
}

.neat-primary-darken-20 {
    background-color: darken($neat-color-blue, 20%);
}

.neat-primary-lighten-20 {
    background-color: lighten($neat-color-blue, 20%);
}

.neat-primary-saturate-20 {
    background-color: saturate($neat-color-blue, 20%);
}

.neat-primary-desaturate-20 {
    background-color: desaturate($neat-color-blue, 20%);
}

.neat-primary-darken-30 {
    background-color: darken($neat-color-blue, 30%);
}

.neat-primary-lighten-30 {
    background-color: lighten($neat-color-blue, 30%);
}

.neat-primary-saturate-30 {
    background-color: saturate($neat-color-blue, 30%);
}

.neat-primary-desaturate-30 {
    background-color: desaturate($neat-color-blue, 30%);
}

.neat-primary-darken-40 {
    background-color: darken($neat-color-blue, 40%);
}

.neat-primary-lighten-40 {
    background-color: lighten($neat-color-blue, 40%);
}

.neat-primary-saturate-40 {
    background-color: saturate($neat-color-blue, 40%);
}

.neat-primary-desaturate-40 {
    background-color: desaturate($neat-color-blue, 40%);
}

.neat-primary-darken-50 {
    background-color: darken($neat-color-blue, 50%);
}

.neat-primary-lighten-50 {
    background-color: lighten($neat-color-blue, 50%);
}

.neat-primary-saturate-50 {
    background-color: saturate($neat-color-blue, 50%);
}

.neat-primary-desaturate-50 {
    background-color: desaturate($neat-color-blue, 50%);
}


/* Fin Colores */


/* Backgrounds */

.bg-dark {
    background: linear-gradient(60deg, #1e263b, rgba(30, 38, 59, 0.8));
}

.bg-secondary {
    background: linear-gradient(60deg, #545d66, #77838f);
}

.bg-neat {
    background: linear-gradient(60deg, #244fbd, rgba(51, 104, 240, 1));
}


/* color-neat: #3368f0 */

.bg-warning-1 {
    background: linear-gradient(60deg, #f5700c, #ff9800);
}

.bg-success-1 {
    background: linear-gradient(60deg, #288c6c, #4ea752);
}

.bg-danger-1 {
    background: linear-gradient(60deg, #d22824, #da3a36);
}

.bg-info-1 {
    background: linear-gradient(60deg, #029eb1, #25b1c3);
}

.bg-violet-1 {
    background: linear-gradient(60deg, #7b1fa2, #913f9e);
}

.bg-violet-2 {
    background: linear-gradient(60deg, #ab47bc, #8e24aa);
}

.bg-info-2 {
    background: linear-gradient(60deg, #26c6da, #00acc1);
}

.bg-success-2 {
    background: linear-gradient(60deg, #66bb6a, #43a047);
}

.bg-warning-2 {
    background: linear-gradient(60deg, #ffa726, #fb8c00);
}

.bg-danger-2 {
    background: linear-gradient(60deg, #ef5350, #e53935);
}

.bg-rose {
    background: linear-gradient(60deg, #ec407a, #d81b60);
}

.bg-info-new {
    background: #EFF3FD;
}


/* Fin backgrounds */

.neat-pink-light {
    color: #F04E98;
}

.new-text-dark {
    color: $new-text-dark;
}

.new-text-secondary {
    color: $new-neat-secondary;
}

.new-text-gray {
    color: $new-text-gray;
}