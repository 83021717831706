/* TABLES */


/* .table-bordered {
    border: 1px solid #e7eaf3;
}

.table-neat-bordered th,
.table-neat-bordered td {
    border: 1px solid #e7eaf3;
}

.table-neat-bordered thead th,
.table-neat-bordered thead td {
    border-bottom-width: 2px;
}

.table-neat-bordered th,
.table-neat-bordered td {
    border: 1px solid #e7eaf3 !important;
} */


/* .table-neat-bordered tbody + tbody, */


/* .table-neat-bordered td, */

.table-neat-bordered th,
.table-neat-bordered thead th {
    border-color: #8a8b8c !important;
}

.dataTables_paginate>.pagination>.disabled {
    display: none;
}

div.dataTables_wrapper div.dataTables_info {
    white-space: normal;
}

div.dataTables_wrapper div.dataTables_filter {
    text-align: left !important;
    font-size: 0.9rem;
}

.dataTables_wrapper.dt-bootstrap4>.row>div>.dt-buttons.btn-group>button {
    background-color: rgba(51, 104, 240, 1) !important;
    border-color: rgba(51, 104, 240, 0.1) !important;
    padding: 8px 14px !important;
    font-size: 0.9rem;
    /* margin: 0 3px 0 3px; */
}

.dataTables_length>label {
    font-size: 0.9rem;
}

.dataTables_length>label>select {
    width: 100px !important;
}

.dataTables_info,
.dataTables_paginate {
    font-size: 0.8rem;
}


/* select[name=payment-list-datatable_length] {
    width: 100px !important;
} */

.table-neat-vertical>tbody>tr {
    display: flex;
    justify-content: space-between;
    align-content: space-between;
}

.table-neat-vertical>tbody>tr>th,
.table-neat-vertical>tbody>tr>td {
    font-size: 0.8rem;
}

.table-neat-vertical,
.table-neat {
    width: 100% !important;
}

.table-neat .thead-neat th {
    background-color: rgba(51, 104, 240, 1) !important;
    color: #fff !important;
    /* border-bottom: 1px solid rgba( 51, 104, 240, 1) !important;
    border-color: rgba( 51, 104, 240, 1) !important; */
}

.table-neat .thead-neat-white th {
    background-color: #fff;
    color: rgba(51, 104, 240, 1) !important;
    /* border-bottom: 1px solid rgba( 51, 104, 240, 1) !important;
    border-color: rgba( 51, 104, 240, 1) !important; */
}

.table-neat,
.table-neat>th,
.table-neat>td {
    background-color: #c7dbff;
    background-color: #fff !important;
}

table.table-neat>thead>tr>th {
    text-align: center !important;
}

table.table-neat>tbody>tr>td {
    font-size: 0.9rem;
    text-align: center !important;
}

.table-hover .table-neat:hover {
    background-color: #aecbff;
}

.table-hover .table-neat:hover>td,
.table-hover .table-neat:hover>th {
    background-color: #aecbff;
}

@media (min-width: 400px) and (max-width: 767px) {
    div.dataTables_length {
        width: 50%;
    }
    div.dataTables_filter {
        width: 60%;
    }
}

@media (max-width: 767px) {
    .dataTables_paginate {
        display: flex;
        align-content: center;
        justify-content: center;
    }
    div.dataTables_wrapper div.dataTables_paginate {
        font-size: 0.85rem;
    }
    div.dataTables_wrapper div.dataTables_info {
        padding-top: 0.85em;
        padding-bottom: 0.85em;
    }
    div.table-responsive>div.dataTables_wrapper>div.row>div[class^='col-']:last-child {
        padding-left: 0 !important;
    }
    .dataTables_filter>label,
    .dataTables_length>label {
        font-size: 0.8rem;
    }
    /* table.table-neat>thead>tr>th, */
    table.table-neat>tbody>tr>td {
        font-size: 0.8rem;
    }
    /* table.table-neat>thead>tr>th {
        padding: 0;
    } */
    .table-neat-vertical>tbody>tr>th {
        font-size: 0.7rem;
    }
    .table-neat-vertical>tbody>tr>td {
        font-size: 0.75rem;
    }
}


/* @media (max-width: 450px) {
    div.dataTables_wrapper div.dataTables_filter input {
        width: 60% !important;
    }
    div.dataTables_wrapper div.dataTables_length select {
        width: 50% !important;
    }
}
 */

.neat-table-container {
    .mat-card {
        box-shadow: none;
        padding-bottom: 0;
    }
    .mat-table-responsive {
        @media (max-width: 991px) {
            display: block;
            width: 100%;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
        }
        .mat-table {
            @media (max-width: 991px) {
                min-width: 800px;
            }
            thead {
                tr {
                    th {
                        text-align: center;
                        .mat-sort-header-container {
                            justify-content: center;
                        }
                        @media (max-width: 991px) {
                            // font-size: 0.625rem;
                        }
                    }
                }
            }
            tbody {
                tr {
                    td {
                        text-align: center;
                        @media (max-width: 991px) {
                            font-size: 0.75rem;
                        }
                    }
                    &.text-white {
                        td {
                            color: #fff !important;
                        }
                    }
                }
            }
        }
    }
}


/* FIN TABLES */