/*******************
Main sidebar
******************/

/* .sidebar-nav #sidebarnav,
.sidebar-nav #sidebarnav>li>ul {
    background: linear-gradient(60deg, #244fbd, rgba(51, 104, 240, 1));
} */

.left-sidebar {
    z-index: 2;
    position: fixed;
    width: 12.5rem;
    height: 100vh;
    background: var(--primary-100, hsl(223, 86%, 57%));

    @media (max-width: 768px) {
        ul li a.active {
            color: #fff !important;
            background-color: #104be0;
        }
    }
    @media (min-width: 769px) {
        padding: 0.3rem 1.25rem;
        ul li a.active span i {
            box-shadow: 0px 4px 4px 0px #00000040;
            border-radius: 50%;
            background-color: #104be0;
            padding: 6px;
            color: #fff;
        }
    }
    .navbar-nav {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
    }
    .nav-item {
        width: 100%;
        padding: 0 0.75rem;
        cursor: pointer;
        @media (max-width: 768px) {
            padding: 0;
            height: 100%;
            &.active {
                i {
                    font-weight: 700 !important;
                    color: var(--neutral-00, #FFF) !important;
                }
                span {
                    font-weight: 700 !important;
                    color: var(--neutral-00, #FFF) !important;
                }
            }
        }
        @media (min-width: 769px) {
            &:hover {
                i {
                    color: var(--neutral-00, #FFF) !important;
                }
                span {
                    color: var(--neutral-00, #FFF) !important;
                }
            }
            &.active {
                border-radius: 8px !important;
                background: rgba(92, 135, 243, 0.60) !important;
            }
        }
        &.active {
            i {
                color: var(--neutral-00, #FFF) !important;
            }
            span {
                color: var(--neutral-00, #FFF) !important;
            }
        }
    } 
    .nav-item:nth-last-child(4){
        @media only screen and (min-width: 769px){
            margin-top: auto;
        }
    }
    .nav-link {
        display: flex;
        align-items: center;
        padding: 0 0.75px !important;
        height: 3.125rem;
        color: white;
        text-decoration: none;
        @media (max-width: 768px) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: auto;
        }
      }

    //   .nav-link-opacity {
    //     filter: grayscale(100%) opacity(0.7);
    //     transition: var(--transition-speed);
    //   }
      
    //   .nav-link:hover {
    //     filter: grayscale(0%) opacity(1);
    //     background: var(--bg-secondary);
    //     color: var(--text-secondary);
    //   }
      
      .link-text {
        display: none;
        margin-left: 1rem;
      }
      
      .nav-link svg {
        width: 2rem;
        min-width: 2rem;
        margin: 0 1.5rem;
      }

      
.fa-primary {
    color: #ff7eee;
  }
  
  .fa-secondary {
    color: #df49a6;
  }
  
  .fa-primary,
  .fa-secondary {
    transition: var(--transition-speed);
  }
  
  .logo {
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    color: var(--text-secondary);
    background: var(--bg-secondary);
    font-size: 1.5rem;
    letter-spacing: 0.3ch;
    width: 100%;
  }
  
  .logo svg {
    transform: rotate(0deg);
    transition: var(--transition-speed);
  }
  
  .logo-text
  {
    display: inline;
    position: absolute;
    left: -999px;
    transition: var(--transition-speed);
  }

  .user-img {
      width: 35px;
      border-radius: 100%;
  }

}

@media only screen and (min-width: 769px) and (max-width: 1720px) {
    .left-sidebar {
        .mat-button {
            line-height: 32px;
        }
    }
  }

/* Small screens */
@media only screen and (max-width: 768px) {
    .left-sidebar {
    //   overflow: auto;
      bottom: -1px;
      width: 100%;
      height: 3.5rem;
      .logo {
        display: none;
      }
      .navbar-nav {
        flex-direction: row;
      }
      .nav-link {
        justify-content: center;
        height: 100%;
      }
      main {
        margin: 0;
      }
      .mat-button {
        line-height: unset;
      }
      span {
          font-size: 9px;
      }
      .user-img {
        width: 23px;
        border-radius: 100%;
    }
    }
  }
  

.left-sidebar::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
    border-radius: 5px;
}

.left-sidebar::-webkit-scrollbar
{
    width: 6px;
    background-color: #F5F5F5;
}

.left-sidebar::-webkit-scrollbar-thumb
{
    border-radius: 5px;
    background-image: -webkit-gradient(linear,
                                       left bottom,
                                       left top,
                                       color-stop(0.44, #3368f0),
                                       color-stop(0.72,  #3368f0),
                                       color-stop(0.86,  #3368f0));
}   


/*******************
use profile section
******************/

.sidebar-nav .user-profile {
    >a {
        img {
            width: 30px;
            border-radius: 100%;
            margin-right: 10px;
        }
    }
    >ul {
        padding-left: 40px;
    }
}


/*******************
sidebar navigation
******************/

.scroll-sidebar {
    height: calc(100%);
    &.ps .ps__scrollbar-y-rail {
        left: 2px;
        right: auto;
        background: none;
        width: 6px;
        /* If using `left`, there shouldn't be a `right`. */
    }
}

.collapse {
    -webkit-transition: height 0.35s ease;
    -o-transition: height 0.35s ease;
    transition: height 0.35s ease;
}

.collapse.in {
    display: block;
    -webkit-transition: height 0.35s ease;
    -o-transition: height 0.35s ease;
    transition: height 0.35s ease;
}

.sidebar-nav {
    background: $sidebar;
    padding: 15px 0 0 0px;
    ul {
        margin: 0px;
        padding: 0px;
        li {
            list-style: none;
            a {
                color: $sidebar-text;
                padding: 8px 35px 8px 15px;
                display: flex;
                font-size: 14px;
                font-weight: 400;
            }
            ul {
                padding-left: 28px;
                li a {
                    padding: 7px 35px 7px 15px;
                    font-size: 0.75rem;
                    margin-bottom: 0.5rem;
                }
                ul {
                    padding-left: 15px;
                }
            }
            &.nav-small-cap {
                font-size: 12px;
                margin-bottom: 0px;
                padding: 14px 14px 14px 20px;
                color: $light;
                // font-weight: 500;
            }
            &.nav-devider {
                height: 1px;
                background: lighten($border, 20%);
                display: block;
                margin: 15px 0;
            }
        }
    }
    >ul>li>a {
        i {
            width: 31px;
            font-size: 24px;
            display: inline-block;
            vertical-align: middle;
            color: $sidebar-icons;
        }
        // .label {
        //     float: right;
        //     // margin-top: 6px;
        // }
        &.active {
            font-weight: 400;
            background: $white;
            color: $themecolor-alt;
        }
    }
    >ul>li {
        margin: 0 0.5rem !important;
        margin-bottom: 5px;
        &.active>a {
            color: $themecolor;
            // font-weight: 500;
            i {
                color: $themecolor;
            }
        }
        a {
            i {
                margin-right: 0.5rem !important;
            }
        }
    }
    .waves-effect {
        transition: none;
        -webkit-transition: none;
        -o-transition: none;
    }
}

.sidebar-nav .has-arrow {
    position: relative;
    &::after {
        position: absolute;
        content: '';
        width: 7px;
        height: 7px;
        border-width: 1px 0 0 1px;
        border-style: solid;
        border-color: $sidebar-text;
        right: 1em;
        -webkit-transform: rotate(135deg) translate(0, -50%);
        -ms-transform: rotate(135deg) translate(0, -50%);
        -o-transform: rotate(135deg) translate(0, -50%);
        transform: rotate(135deg) translate(0, -50%);
        -webkit-transform-origin: top;
        -ms-transform-origin: top;
        -o-transform-origin: top;
        transform-origin: top;
        top: 47%;
        -webkit-transition: all 0.3s ease-out;
        -o-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
    }
}

.sidebar-nav .active>.has-arrow::after,
.sidebar-nav li>.has-arrow.active::after,
.sidebar-nav .has-arrow[aria-expanded='true']::after {
    -webkit-transform: rotate(-135deg) translate(0, -50%);
    -ms-transform: rotate(-135deg) translate(0, -50%);
    -o-transform: rotate(-135deg) translate(0, -50%);
    top: 45%;
    width: 7px;
    transform: rotate(-135deg) translate(0, -50%);
}


/****************
When click on sidebar toggler and also for tablet
*****************/

@media (min-width: 768px) {
    .mini-sidebar .sidebar-nav #sidebarnav li {
        position: relative;
    }
    .mini-sidebar .sidebar-nav #sidebarnav>li>ul {
        position: absolute;
        left: 70px;
        top: 46px;
        width: 220px;
        padding-bottom: 10px;
        z-index: 1001;
        // background: darken($sidebar-alt, 50%);
        background: darken($sidebar-alt, 50%);
        display: none;
        padding-left: 1px;
        color: $neat-color-blue;
    }
    .mini-sidebar .user-profile .profile-img {
        width: 45px;
        .setpos {
            top: -35px;
        }
    }
    .mini-sidebar .sidebar-nav #sidebarnav>li:hover>ul {
        height: auto !important;
        overflow: auto;
    }
    .mini-sidebar .sidebar-nav #sidebarnav>li:hover>ul,
    .mini-sidebar .sidebar-nav #sidebarnav>li:hover>ul.collapse {
        display: block;
        a {
            color: $neat-color-blue;
        }
    }
    .mini-sidebar .sidebar-nav #sidebarnav>li>a.has-arrow:after {
        display: none;
    }
}


/****************
When click on sidebar toggler and also for tablet
*****************/

@media (min-width: 768px) {
    .mini-sidebar .sidebar-nav #sidebarnav li {
        position: relative;
    }
    .mini-sidebar .sidebar-nav #sidebarnav>li>ul {
        position: absolute;
        left: 70px;
        top: 60px;
        width: 220px;
        padding-bottom: 10px;
        z-index: 1001;
        background: #F9F9F9 ;
        display: none;
        padding-left: 1px;
    }
    .mini-sidebar .user-profile .profile-img {
        width: 45px;
    }
    .mini-sidebar .user-profile .profile-img .setpos {
        top: -35px;
    }
    .mini-sidebar .sidebar-nav #sidebarnav>li:hover>ul {
        height: auto !important;
        overflow: auto;
    }
    .mini-sidebar .sidebar-nav #sidebarnav>li:hover>ul,
    .mini-sidebar .sidebar-nav #sidebarnav>li:hover>ul.collapse {
        display: block;
    }
    .mini-sidebar .sidebar-nav #sidebarnav>li>a.has-arrow:after {
        display: none;
    }
    .mini-sidebar .sidebar-nav #sidebarnav .user-profile>a {
        padding: 12px 20px;
    }
    .mini-sidebar .scroll-sidebar {
        padding-bottom: 0px;
        position: absolute;
    }
    .mini-sidebar .hide-menu,
    .mini-sidebar .nav-small-cap,
    .mini-sidebar .sidebar-footer,
    .mini-sidebar .user-profile .profile-text,
    .mini-sidebar>.label {
        display: none;
    }
    .mini-sidebar .nav-devider {
        width: 70px;
    }
    .mini-sidebar .sidebar-nav {
        background: transparent;
    }
    .mini-sidebar .sidebar-nav #sidebarnav>li>a {
        padding: 12px 24px;
        width: 70px;
    }
    .mini-sidebar .sidebar-nav #sidebarnav>li:hover>a {
        width: 290px;
        background:#F9F9F9 ;
        &.active {
            color: #fff !important;
        }
    }
    .mini-sidebar .sidebar-nav #sidebarnav>li:hover>a .hide-menu {
        display: inline;
    }
    // .mini-sidebar .sidebar-nav #sidebarnav>li>a .label {
    //     .label-text {
    //         display: none;
    //     }
    //     // .label-number {
    //     //     display: flex;
    //     //     justify-content: center;
    //     // }
    // }
    /*
    .mini-sidebar .sidebar-nav #sidebarnav>li:hover>a .label {
        display: flex;
    } */
}

.mini-sidebar .top-navbar .navbar-header {
    width: 70px;
    text-align: center;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-left: 0px;
}


/*-- ==============================================================
 Ipad & above all(768px)
 ============================================================== */

@media (min-width: 768px) {
    .navbar-header {
        width: 110px;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
    }
    .navbar-header .navbar-brand {
        padding-top: 0px;
    }
    .mini-sidebar .page-wrapper {
        // margin-left: 70px;
    }
}

@media only screen and (max-width: 768px) {
    .page-wrapper {
      margin-bottom: 3.5rem;
    }
}


/*-- ==============================================================
 Phone and below ipad(767px)
 ============================================================== */

@media (max-width: 767px) {
    .container-fluid {
        padding: 25px 15px 25px 15px;
    }
    /*Header*/
    .topbar {
        position: fixed;
        width: 100%;
    }
    .topbar .top-navbar {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-align-items: center;
    }
    .topbar .top-navbar .navbar-collapse {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        width: 100%;
    }
    .topbar .top-navbar .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
    }
    .topbar .top-navbar .navbar-nav>.nav-item.show {
        position: static;
    }
    .topbar .top-navbar .navbar-nav>.nav-item.show .dropdown-menu {
        width: 100%;
        margin-top: 0px;
    }
    .topbar .top-navbar .navbar-nav>.nav-item>.nav-link {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
    .topbar .top-navbar .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .mega-dropdown .dropdown-menu {
        height: 480px;
        overflow: auto;
    }
    /*Sidebar and wrapper*/
    .mini-sidebar .page-wrapper {
        margin-left: 0px;
        // padding-top: 70px;
    }
}

.mini-sidebar {
    transition: all 0.3s ease;
}

.collapse:not(.show) {
    display: none;
}

.collapse.in {
    display: block !important;
}

.label {
    display: flex;
    justify-content: center;
    align-items: center;
    // z-index: 1;
    pointer-events: none;
}

.label-new {
    position: absolute;
    right: 40px;
    top: 13px;
    border-radius: 20px;
    font-size: .625rem;
    font-weight: 600;
}

.label-text {
    width: 58px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.label-number {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
}

.label-neat {
    background: $neat-color-blue;
    color: #fff;
}

.label-neat-white {
    background: #fff;
    color: $neat-color-blue;
}

.label-neat-yellow {
    background-image: linear-gradient( to right, darken($warning, 5%), $warning);
    color: $neat-color-blue;
}

.label-neat-success {
    background: $success;
    color: $neat-color-blue;
}

.label-neat-white-border {
    background: #fff;
    color: $neat-color-blue;
    border: 1px solid $neat-color-blue;
}

.label-neat-border {
    background: $neat-color-blue;
    color: #fff;
    border: 1px solid #fff;
}

.sidebar-nav>ul>li>a {
    // z-index: 2;
    &.mat-button-base {
        display: flex !important;
        justify-content: flex-start !important;
    }
    color: #ffffff;
    border-color: $neat-color-blue;
    margin-bottom: 0.5rem;
}


/* .sidebar-nav>ul>li ul>li>a {
    z-index: 2;
} */

.mini-sidebar .sidebar-nav>ul>li>a {
    &.mat-button-base {
        justify-content: flex-start !important;
    }
}

.mini-sidebar .sidebar-nav>ul>li:hover>a {
    &.mat-button-base {
        justify-content: flex-start !important;
    }
}

.sidebar-nav>ul>li:hover>a,
.sidebar-nav>ul>li.active>a {
    color: #ffffff;
    border-color: $neat-color-blue;
    // margin-left: 7px !important;
}


/* .mini-sidebar .sidebar-nav>ul>li:hover>a,
.mini-sidebar .sidebar-nav>ul>li.active>a {
    margin: 0px 0px !important;
} */

.sidebar-nav>ul>li.active>a i {
    color: #ffffff;
}

.sidebar-nav ul li a.active,
.sidebar-nav ul li a:hover {
    color: #ffffff;
    // background-color: darken($neat-color-blue, 10%);
}

.sidebar-nav ul li a.base-active.active,
.sidebar-nav ul li a.base-active:hover {
    color: #ffffff;
    background-color: darken($neat-color-blue, 10%);
}

.mini-sidebar .sidebar-nav ul li a.active,
.mini-sidebar .sidebar-nav ul li a:hover {
    color: #ffffff;
}

.mini-sidebar .sidebar-nav ul li a.base-active.active,
.mini-sidebar .sidebar-nav ul li a.base-active:hover {
    background-color: darken($neat-color-blue, 10%);
    color: #ffffff;
}

.sidebar-nav ul li a.active i,
.sidebar-nav ul li a:hover i {
    color: #ffffff;
}

.sidebar-nav ul li a.child-link.active,
.sidebar-nav ul li a.child-link:hover {
    background-color: darken($neat-color-blue, 10%) !important;
    color: #fff !important;
}


/* .sidebar-nav ul li a.child-link.active {
    color: darken($neat-color-blue, 10%) !important;
    font-weight: 600;
    font-size: calc(100% + 2px);
} */

.mini-sidebar .sidebar-nav {
    >ul>li {
        margin: 0 0 !important;
        a {
            i {
                margin-right: 0 !important;
            }
        }
    }
}