/*------------------------------------
  Transitions
------------------------------------*/


/* 3D Hover */

.transition-3d-hover {
    transition: all 0.2s ease-in-out;
}

.transition-3d-hover:hover,
.transition-3d-hover:focus {
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
}


/* TRANSICIONES */

.transition-ease-height-035 {
    -webkit-transition: height 0.35s ease;
    -o-transition: height 0.35s ease;
    transition: height 0.35s ease;
}

.transition-ease-03 {
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.transition-ease-05 {
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.transition-ease-07 {
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.transition-ease-in-03 {
    -webkit-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
}

.transition-ease-in-05 {
    -webkit-transition: all 0.5s ease-in;
    -o-transition: all 0.5s ease-in;
    transition: all 0.5s ease-in;
}

.transition-ease-out-05 {
    -webkit-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}


/* TRANSICIONES */


/*------------------------------------
  Transitions
------------------------------------*/


/* ANIMACIONES */

@media screen and (max-width: 991px) {
    .wow {
        visibility: visible !important;
        -webkit-animation-name: none !important;
        -o-animation-name: none !important;
        animation-name: none !important;
        visibility: visible !important;
        -webkit-animation: none !important;
        -moz-animation: none !important;
        -o-animation: none !important;
        -ms-animation: none !important;
        animation: none !important;
    }
}


/* Animaciones */

.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.fast {
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.fadeIn {
    animation-name: fadeIn;
}


/* ANIMACIONES */