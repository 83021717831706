@import 'assets/scss/variables';
@import 'bootstrap/scss/bootstrap';
@import 'styles/typography';

.bg-ds-warning-1 {
  background-color: $new-warning-1;
}

.text-ds-warning-1 {
  color: $new-warning-1;
}

.text-ds-warning-2 {
  color: $new-warning-2;
}

.text-ds-warning-3 {
  color: $new-warning-3;
}

.border-ds-warning-1 {
  border: 1px solid $new-warning-1;
}

.border-ds-warning-2 {
  border: 1px solid $new-warning-2;
}

.border-ds-warning-3 {
  border: 1px solid $new-warning-3;
}

$preset-map: (
  'h1': ('desktop': ('size': '4xl', 'weight': 'bold'), 'mobile': ('size': '2xl', 'weight': 'bold')),
  'h2': ('desktop': ('size': '3xl', 'weight': 'bold'), 'mobile': ('size': 'xl', 'weight': 'bold')),
  'h3': ('desktop': ('size': '2xl', 'weight': 'bold'), 'mobile': ('size': 'lg', 'weight': 'bold')),
  'h4': ('desktop': ('size': 'xl', 'weight': 'bold'), 'mobile': ('size': 'lg', 'weight': 'semibold')),
  'subtitle-bold': ('desktop': ('size': 'lg', 'weight': 'bold'), 'mobile': ('size': 'md', 'weight': 'bold')),
  'subtitle-semibold': ('desktop': ('size': 'lg', 'weight': 'semibold'), 'mobile': ('size': 'md', 'weight': 'semibold')),
  'subtitle-medium': ('desktop': ('size': 'lg', 'weight': 'medium'), 'mobile': ('size': 'md', 'weight': 'medium')),
  'body-bold': ('desktop': ('size': 'md', 'weight': 'bold'), 'mobile': ('size': 'sm', 'weight': 'bold')),
  'body-semibold': ('desktop': ('size': 'md', 'weight': 'semibold'), 'mobile': ('size': 'sm', 'weight': 'semibold')),
  'body-medium': ('desktop': ('size': 'md', 'weight': 'medium'), 'mobile': ('size': 'sm', 'weight': 'medium')),
  'body-regular': ('desktop': ('size': 'md', 'weight': 'regular'), 'mobile': ('size': 'sm', 'weight': 'regular')),
  'label-bold': ('desktop': ('size': 'sm', 'weight': 'bold'), 'mobile': ('size': 'xs', 'weight': 'bold')),
  'label-semibold': ('desktop': ('size': 'sm', 'weight': 'semibold'), 'mobile': ('size': 'xs', 'weight': 'semibold')),
  'label-medium': ('desktop': ('size': 'sm', 'weight': 'medium'), 'mobile': ('size': 'xs', 'weight': 'medium')),
  'small-label-semibold': ('desktop': ('size': 'xs', 'weight': 'semibold'), 'mobile': ('size': '2xs', 'weight': 'semibold')),
  'small-label-medium': ('desktop': ('size': 'xs', 'weight': 'medium'), 'mobile': ('size': '2xs', 'weight': 'medium')),
  'tiny-label-semibold': ('desktop': ('size': '2xs', 'weight': 'semibold'), 'mobile': ('size': '3xs', 'weight': 'semibold')),
  'tiny-label-medium': ('desktop': ('size': '2xs', 'weight': 'medium'), 'mobile': ('size': '3xs', 'weight': 'medium')),
  'tiny-label-regular': ('desktop': ('size': '2xs', 'weight': 'regular'), 'mobile': ('size': '3xs', 'weight': 'regular'))
);

@each $name, $config in $preset-map {
  .#{$name} {
    @include text-style(map-get(map-get($config, 'desktop'), 'size'),
      map-get(map-get($config, 'desktop'), 'weight'));
  }

  @media (max-width: map-get($grid-breakpoints, 'lg')) {
    .#{$name} {
      @include text-style(map-get(map-get($config, 'mobile'), 'size'),
        map-get(map-get($config, 'mobile'), 'weight'));
    }
  }
}
