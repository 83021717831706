/* NAVBAR */

.navbar {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0.12rem 1rem;
    font-size: 15px;
}

.navbar>.container,
.navbar>.container-fluid {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.navbar-brand {
    display: inline-block;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
}

.navbar-brand:focus,
.navbar-brand:hover {
    text-decoration: none;
}

.navbar-nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.navbar-nav .nav-link .nav-login {
    padding-right: 0;
    padding-left: 0;
}

.navbar-nav .dropdown-menu {
    position: static;
    float: none;
}

.navbar-text {
    display: inline-block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.navbar-collapse {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-align: center;
    align-items: center;
}

.navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

.navbar-toggler:focus,
.navbar-toggler:hover {
    text-decoration: none;
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: '';
    background: no-repeat center center;
    background-size: 100% 100%;
}

@media (max-width: 575.98px) {
    .navbar-expand-sm>.container,
    .navbar-expand-sm>.container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 576px) {
    .navbar-expand-sm {
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }
    .navbar-expand-sm .navbar-nav {
        -ms-flex-direction: row;
        flex-direction: row;
    }
    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .navbar-expand-sm .navbar-nav .nav-link .nav-login{
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
    .navbar-expand-sm>.container,
    .navbar-expand-sm>.container-fluid {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }
    .navbar-expand-sm .navbar-collapse {
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }
    .navbar-expand-sm .navbar-toggler {
        display: none;
    }
}

@media (max-width: 767.98px) {
    .navbar-expand-md>.container,
    .navbar-expand-md>.container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 768px) {
    .navbar-expand-md {
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }
    .navbar-expand-md .navbar-nav {
        -ms-flex-direction: row;
        flex-direction: row;
    }
    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .navbar-expand-md .navbar-nav .nav-link .nav-login {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
    .navbar-expand-md>.container,
    .navbar-expand-md>.container-fluid {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }
    .navbar-expand-md .navbar-collapse {
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }
    .navbar-expand-md .navbar-toggler {
        display: none;
    }
}

@media (max-width: 991.98px) {
    .navbar-expand-lg>.container,
    .navbar-expand-lg>.container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 992px) {
    .navbar-expand-lg {
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }
    .navbar-expand-lg .navbar-nav {
        -ms-flex-direction: row;
        flex-direction: row;
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .navbar-expand-lg .navbar-nav .nav-link .nav-login {
        padding-right: 1.3rem !important;
        padding-left: 1.3rem !important;
    }
    .navbar-expand-lg>.container,
    .navbar-expand-lg>.container-fluid {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }
    .navbar-expand-lg .navbar-collapse {
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }
    .navbar-expand-lg .navbar-toggler {
        display: none;
    }
}

@media (max-width: 1199.98px) {
    .navbar-expand-xl>.container,
    .navbar-expand-xl>.container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 1200px) {
    .navbar-expand-xl {
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }
    .navbar-expand-xl .navbar-nav {
        -ms-flex-direction: row;
        flex-direction: row;
    }
    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .navbar-expand-xl .navbar-nav .nav-link .nav-login {
        padding-right: 1.3rem !important;
        padding-left: 1.3rem !important;
    }
    .navbar-expand-xl>.container,
    .navbar-expand-xl>.container-fluid {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }
    .navbar-expand-xl .navbar-collapse {
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }
    .navbar-expand-xl .navbar-toggler {
        display: none;
    }
}

.navbar-expand {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.navbar-expand>.container,
.navbar-expand>.container-fluid {
    padding-right: 0;
    padding-left: 0;
}

.navbar-expand .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute;
}

.navbar-expand .navbar-nav .nav-link .nav-login {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}

.navbar-expand>.container,
.navbar-expand>.container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
}

.navbar-expand .navbar-toggler {
    display: none;
}

.navbar-light .navbar-brand {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
    color: lighten(#000, 10%);
}

.nav-login{
    color : #2D5DD8;
}

.nav-login:focus,
.nav-login:hover {
    color : #2D5DD8
}

.li-hover:hover {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    // color: lighten(#000, 10%);
}

.navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show>.nav-link {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
    color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
    color: lighten(#000, 10%);
}

.navbar-dark .navbar-brand {
    color: #515151;
}

.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
    color: darken(#000, 10%);
}

.navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
    // color: darken(#000, 10%);
}

.navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show>.nav-link {
    color: #515151;
}

.navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 0.5);
    border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
    color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
    color: #515151;
}

.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
    color: #515151;
}

.navbar.navbar-landing {
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    font-weight: 500;
}

.navbar .breadcrumb {
    margin: 0;
    padding: 0.3rem 0 0 1rem;
    background-color: inherit;
    font-size: 15px;
    font-weight: 300;
}

.navbar .breadcrumb .breadcrumb-item {
    color: #515151;
}

.navbar .breadcrumb .breadcrumb-item.active {
    color: rgba(255, 255, 255, 0.65);
}

.navbar .breadcrumb .breadcrumb-item:before {
    color: rgba(255, 255, 255, 0.65);
}

.navbar .navbar-toggler {
    outline: 0;
    border-width: 0;
}

.navbar .nav-flex-icons {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
}

@media (max-width: 992px) {
    .navbar .container {
        width: 100%;
    }
    .navbar .container .navbar-toggler-right {
        right: 0;
    }
}

.navbar .nav-item .nav-link .nav-login {
    display: block;
}

.navbar .nav-item .nav-link.disabled:active {
    pointer-events: none;
}

.navbar .nav-item .nav-link .fab,
.navbar .nav-item .nav-link .far,
.navbar .nav-item .nav-link .fas {
    padding-right: 3px;
    padding-left: 3px;
}

@media (max-width: 992px) {
    .navbar .nav-item .nav-link .nav-login {
        padding: 8px;
    }
}

.navbar .dropdown-menu a {
    padding: 10px;
    font-size: 0.9375rem;
    font-weight: 500;
    color: #515151;
}

@media (min-width: 600px) {
    .navbar.scrolling-navbar {
        -webkit-transition: background 0.5s ease-in-out, padding 0.5s ease-in-out;
        -o-transition: background 0.5s ease-in-out, padding 0.5s ease-in-out;
        transition: background 0.5s ease-in-out, padding 0.5s ease-in-out;
    }
    .navbar.scrolling-navbar .navbar-nav>li {
        -webkit-transition-duration: 1s;
        -o-transition-duration: 1s;
        transition-duration: 1s;
    }
    .navbar.scrolling-navbar.top-nav-collapse {
        padding-top: 5px;
        padding-bottom: 5px;
    }
}

.navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
}

.navbar .nav-item.avatar {
    padding: 0;
}

.navbar .nav-item.avatar.active {
    background-color: transparent !important;
}

.navbar .nav-item.avatar .dropdown-toggle {
    padding: 0;
}

.navbar .nav-item.avatar .dropdown-toggle img {
    height: 35px;
}

.navbar .nav-item.avatar .dropdown-toggle:after {
    display: none;
}

.navbar .mega-dropdown {
    position: static !important;
}

.navbar .mega-dropdown .dropdown-menu.mega-menu {
    width: 100%;
    border: none;
    -webkit-border-radius: 0;
    border-radius: 0;
}

.navbar .mega-dropdown .dropdown-menu.mega-menu .sub-menu .news-title {
    font-size: 1.1rem;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
}

.navbar .mega-dropdown .dropdown-menu.mega-menu .sub-menu .news-title.smaller {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.4;
}

.navbar .mega-dropdown .dropdown-menu.mega-menu .sub-menu .sub-title {
    border-bottom: 1px solid #e0e0e0;
}

.navbar .mega-dropdown .dropdown-menu.mega-menu .sub-menu ul li a {
    width: 100%;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.navbar .mega-dropdown .dropdown-menu.mega-menu .sub-menu ul li a:hover {
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.navbar .mega-dropdown .dropdown-menu.mega-menu.v-1 .sub-menu .news-single {
    border-bottom: 1px solid #e0e0e0;
}

.navbar .mega-dropdown .dropdown-menu.mega-menu.v-1 .sub-menu .news-title {
    color: #4f4f4f !important;
}

.navbar .mega-dropdown .dropdown-menu.mega-menu.v-1 .sub-menu .news-title:hover {
    color: #2196f3 !important;
}

.navbar .mega-dropdown .dropdown-menu.mega-menu.v-1 .sub-menu .m-sm {
    margin-bottom: -6px;
    font-size: 0.9rem;
    color: #2196f3 !important;
}

.navbar .mega-dropdown .dropdown-menu.mega-menu.v-1 .sub-menu .m-sm:hover {
    color: #2D5DD8 !important;
}

.navbar .mega-dropdown .dropdown-menu.mega-menu.v-2 .sub-menu .news-title {
    color: #515151 !important;
}

.navbar .mega-dropdown .dropdown-menu.mega-menu.v-2 .sub-menu ul li a {
    color: #515151 !important;
}

.navbar .mega-dropdown .dropdown-menu.mega-menu.v-2 .sub-menu ul li a:hover {
    color: #515151 !important;
}

.navbar .mega-dropdown .dropdown-menu.mega-menu.v-2 .sub-menu .sub-title {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
}

.navbar .mega-dropdown .dropdown-menu.mega-menu.v-3 .sub-menu ul li a {
    color: #515151 !important;
}

.navbar .mega-dropdown .dropdown-menu.mega-menu.v-3 .sub-menu ul li a:hover {
    color: #515151 !important;
}

.navbar .mega-dropdown .dropdown-menu.mega-menu.v-3 .sub-menu .news-title {
    color: #515151 !important;
}

.navbar .mega-dropdown .dropdown-menu.mega-menu.v-3 .sub-menu .news-title:hover {
    color: #e0e0e0 !important;
}

@media (max-width: 1024px) {
    .navbar .mega-dropdown .dropdown-menu.mega-menu {
        max-height: 300px;
        overflow-x: hidden;
        overflow-y: auto;
    }
}

.navbar .dropdown.multi-level-dropdown .dropdown-menu .dropdown-submenu {
    position: relative;
}

.navbar .dropdown.multi-level-dropdown .dropdown-menu .dropdown-submenu .dropdown-menu {
    top: 0;
    left: 100%;
}

.navbar .dropdown.multi-level-dropdown .dropdown-menu .dropdown-submenu .dropdown-menu.r-100 {
    right: 100%;
}

.navbar .dropdown.multi-level-dropdown .dropdown-menu .dropdown-submenu .dropdown-menu.l-auto {
    left: auto;
}

.navbar .dropdown.multi-level-dropdown .dropdown-menu .dropdown-item {
    width: 100%;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.navbar .dropdown.multi-level-dropdown .dropdown-menu .dropdown-item:hover {
    background-color: rgba(0, 0, 0, 0.2) !important;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-border-radius: 0;
    border-radius: 0;
}

@media (max-width: 992px) {
    .navbar {
        position: relative;
    }
}

.navbar .button-collapse {
    padding-top: 1px;
}

.navbar-toggler {
    border: none;
    background: transparent !important;
}

.navbar-toggler:focus {
    outline: none;
    background: transparent !important;
}

.navbar-toggler .icon-bar {
    background-color: #fff;
    transform: rotate(0deg) translate(0px, 0px);
    transition: ease all 0.2s;
}

.navbar.navbar-neat .navbar-toggler .icon-bar {
    color: rgb(172, 170, 170);
}

.navbar.scrolled .navbar-toggler .icon-bar {
    background-color: #ffc515;
}

.navbar-toggler .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
}

.navbar-toggler .icon-bar+.icon-bar {
    margin-top: 4px;
}

.icon-bar:nth-child(2) {
    width: 16px;
    transition: ease all 0.2s;
}

.navbar-toggler:hover>.icon-bar:nth-child(2) {
    width: 22px;
    transition: ease all 0.2s;
}

.navbar-toggler:active>.icon-bar:nth-child(2) {
    width: 22px;
    transition: ease all 0.2s;
}

.navbar-toggler:not(.collapsed) .icon-bar:nth-child(1) {
    transform: rotate(45deg) translate(5px, 4px);
    transition: ease all 0.2s;
}

.navbar-toggler:not(.collapsed) .icon-bar:nth-child(2) {
    opacity: 0;
    transition: ease all 0.2s;
}

.navbar-toggler:not(.collapsed) .icon-bar:nth-child(3) {
    transform: rotate(-45deg) translate(4px, -4px);
    transition: ease all 0.2s;
}

.top-nav-collapse {
    transition: 0.5s ease-in;
}

.navbar.navbar-landing:not(.top-nav-collapse) {
    transition: 0.5s ease-in;
    background: transparent !important;
    box-shadow: none;
}

.navbar.bg-neat-white {
    background: #fff;
}

.navbar.navbar-neat-white .navbar-nav .nav-item .nav-link.disbled {
    color: darken(#000, 20%);
}

.navbar.navbar-neat-white .navbar-nav .nav-item .nav-link.disbled:hover {
    color: darken(#000, 20%);
}

.navbar.navbar-neat-white .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    cursor: pointer;
}

// .navbar.navbar-neat-white .breadcrumb .nav-item .nav-link,
// .navbar.navbar-neat-white .navbar-nav .nav-item .nav-link {
//     color: $neat-color-blue;
//     -webkit-transition: 0.35s;
//     -o-transition: 0.35s;
//     transition: 0.35s;
// }

.navbar.navbar-neat-white .breadcrumb .nav-item .nav-link:hover,
.navbar.navbar-neat-white .navbar-nav .nav-item .nav-link:hover {
    // color: darken(black, 10%);
}

.navbar.navbar-neat-white .breadcrumb .nav-item.active>.nav-link,
.navbar.navbar-neat-white .navbar-nav .nav-item.active>.nav-link {
    background-color: rgba(51, 104, 240, 0.1);
}

.navbar.navbar-neat-white .breadcrumb .nav-item.active>.nav-link:hover,
.navbar.navbar-neat-white .navbar-nav .nav-item.active>.nav-link:hover {
    // color: darken($neat-color-blue, 25%);
}

.navbar.navbar-neat-white .navbar-toggler {
    // color: $neat-color-blue;
}

.navbar.navbar-neat-white .breadcrumb .nav-item .nav-link,
.navbar.navbar-neat-white .navbar-nav .nav-item .nav-link {
    color: #6D6D6D;
    -webkit-transition: 0.35s;
    -o-transition: 0.35s;
    transition: 0.35s;
}

.navbar:not(.top-nav-collapse).navbar-neat-white .breadcrumb .nav-item .nav-link:hover,
.navbar:not(.top-nav-collapse).navbar-neat-white .navbar-nav .nav-item .nav-link:hover {
    // color: lighten($neat-color-blue, 15%);
}

.navbar:not(.top-nav-collapse).navbar-neat-white .breadcrumb .nav-item.active>.nav-link,
.navbar:not(.top-nav-collapse).navbar-neat-white .navbar-nav .nav-item.active>.nav-link {
    background-color: rgba(51, 104, 240, 0.1);
}

.navbar:not(.top-nav-collapse).navbar-neat-white .breadcrumb .nav-item.active>.nav-link:hover,
.navbar:not(.top-nav-collapse).navbar-neat-white .navbar-nav .nav-item.active>.nav-link:hover {
    // color: #515151;
}

.navbar:not(.top-nav-collapse).navbar-neat-white .navbar-toggler {
    color: #515151;
}

.navbar:not(.top-nav-collapse),
.navbar.top-nav-collapse,
.navbar.navbar-neat-white,
.navbar:not(.top-nav-collapse).navbar-neat-white {
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.custom-logo {
    width: 7.5rem;
    z-index: 9;
}

.navbar:not(.top-nav-collapse).navbar-neat-white .navbar-brand>.custom-logo {
    -webkit-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.navbar.navbar-neat-white .navbar-brand>.custom-logo {
    -webkit-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
}

.navbar:not(.top-nav-collapse).navbar-neat-white .navbar-brand>img.custom-logo {
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

// .navbar:not(.top-nav-collapse).navbar-neat-white .navbar-brand>img#img-logo-color {
//     display: none;
// }

.navbar:not(.top-nav-collapse).navbar-neat-white .navbar-brand>img#img-logo-blanco {
    display: block;
    padding: 6px;
}

// .navbar.navbar-neat-white .navbar-brand>img#img-logo-blanco {
//     display: none;
// }

.navbar.navbar-neat-white .navbar-brand>img#img-logo-color {
    display: block;
    padding: 6px;
}

.navbar.navbar-neat-white .breadcrumb .nav-item .nav-link .nav-login,
.navbar.navbar-neat-white .navbar-nav .nav-item .nav-link .nav-login {
    letter-spacing: 1.4px;
}

// .navbar:not(.top-nav-collapse).navbar-neat-white .icon-bar {
//     background-color: #515151;
// }


.navbar.navbar-neat-white .navbar-nav .nav-item.active,
.navbar:not(.top-nav-collapse).navbar-neat-white .navbar-nav .nav-item.active {
    background: $neat-color-blue !important;
    color: #fff !important;
    // -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    // box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.navbar.navbar-neat-white .navbar-nav .nav-item.active .nav-link,
.navbar:not(.top-nav-collapse).navbar-neat-white .navbar-nav .nav-item.active .nav-link {
    color: #fff !important;
}

@media (max-width: 991px) {
    .navbar:not(.top-nav-collapse).navbar-neat-white .navbar-collapse.show {
        background-color: #fff;
        .navbar-nav {
            .nav-item {
                .nav-link {
                    // color: $neat-color-blue;
                    color: #515151;
                }
                .nav-login {
                    // color: $neat-color-blue;
                }
            }
        }
    }
}


/* FIN NAVBAR */