$text-sizes: (
    "4xl": (font-size: 40px, line-height: 48px),
    "3xl": (font-size: 36px, line-height: 44px),
    "2xl": (font-size: 24px, line-height: 32px),
    "xl": (font-size: 20px, line-height: 28px),
    "lg": (font-size: 18px, line-height: 22px),
    "md": (font-size: 16px, line-height: 20px),
    "sm": (font-size: 14px, line-height: 18px),
    "xs": (font-size: 13px, line-height: 17px),
    "2xs": (font-size: 12px, line-height: 16px),
    "3xs": (font-size: 10px, line-height: 14px),
);

$text-weights: (
    "bold": 700,
    "semibold": 600,
    "medium": 500,
    "regular": 400,
);

@mixin text-style($size, $weight: "regular") {
    $size-settings: map-get($map: $text-sizes, $key: $size);
    $weight-value: map-get($map: $text-weights, $key: $weight );

    font-size: map-get($map: $size-settings, $key: font-size) !important;
    line-height: map-get($size-settings, line-height) !important;
    font-weight: $weight-value !important;
}