@import 'assets/scss/variables';
@import 'assets/scss/dashboard/sidebar';
@import 'assets/scss/dashboard/common';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~@ngx-share/button/themes/circles/circles-dark-theme';
/* set the overriding variables */
$grid-breakpoints: (
  xxxs: 0,
  xxs: 320px,
  xs: 568px,
  sm: 667px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1600px
);
// $container-max-widths: (
//   xxxs: 0,
//   xxs: 320px,
//   xs: 568px,
//   sm: 667px,
//   md: 768px,
//   lg: 992px,
//   xl: 1200px,
//   xxl: 1440px,
//   xxxl: 1600px
// );
/* override the !default vars with the values we set above */
@import "bootstrap";
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/badge';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/popover';
@import '~bootstrap/scss/transitions';
@import 'assets/scss/reset';
@import 'assets/scss/tooltip';
@import 'assets/scss/navbar';
@import 'assets/scss/dropdown';
@import 'assets/scss/utils';
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import 'assets/scss/buttons';
@import 'assets/scss/cards';
@import 'assets/scss/tables';
@import 'assets/scss/colors';
@import 'assets/scss/lists';
@import 'assets/scss/transitions';
@import 'assets/scss/form';
@import '~ngx-sharebuttons/themes/material/material-dark-theme';
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url('https://fonts.googleapis.com/css2?family=Material+Icons+Outlined');
@import '~neat-design-system-base/build/css/colors.scss';
*,
*::before,
*::after {
    box-sizing: border-box;
    /* @media (max-width: 991px) {
        touch-action: pan-y;
        -ms-touch-action: pan-y;
        -webkit-overflow-scrolling: touch;
        overflow: touch;
    } */
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

:root {
    --primary-5: #{$primary-5};
    --primary-10: #{$primary-10};
    --primary-20: #{$primary-20};
    --primary-40: #{$primary-40};
    --primary-60: #{$primary-60};
    --primary-80: #{$primary-80};
    --primary-100: #{$primary-100};
  
    --secondary-5: #{$secondary-5};
    --secondary-10: #{$secondary-10};
    --secondary-20: #{$secondary-20};
    --secondary-40: #{$secondary-40};
    --secondary-60: #{$secondary-60};
    --secondary-80: #{$secondary-80};
    --secondary-100: #{$secondary-100};
  
    --neutral-0: #{$neutral-0};
    --neutral-5: #{$neutral-5};
    --neutral-10: #{$neutral-10};
    --neutral-20: #{$neutral-20};
    --neutral-40: #{$neutral-40};
    --neutral-60: #{$neutral-60};
    --neutral-80: #{$neutral-80};
    --neutral-100: #{$neutral-100};
  
    --feedback-info-bg: #{$feedback-info-bg};
    --feedback-info-text: #{$feedback-info-text};
    --feedback-info-link: #{$feedback-info-link};
  
    --feedback-success-bg: #{$feedback-success-bg};
    --feedback-success-text: #{$feedback-success-text};
    --feedback-success-link: #{$feedback-success-link};
  
    --feedback-warning-bg: #{$feedback-warning-bg};
    --feedback-warning-text: #{$feedback-warning-text};
    --feedback-warning-link: #{$feedback-warning-link};
  
    --feedback-error-bg: #{$feedback-error-bg};
    --feedback-error-text: #{$feedback-error-text};
    --feedback-error-link: #{$feedback-error-link};
  
    --bootstrap-primary: #{$bootstrap-primary};
    --bootstrap-secondary: #{$bootstrap-secondary};
    --bootstrap-success: #{$bootstrap-success};
    --bootstrap-warning: #{$bootstrap-warning};
    --bootstrap-danger: #{$bootstrap-danger};
    --bootstrap-info: #{$bootstrap-info};
    --bootstrap-light: #{$bootstrap-light};
    --bootstrap-dark: #{$bootstrap-dark};
    --bootstrap-white: #{$bootstrap-white};
    --white: #{$white};
    --black: #{$black};
  }

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block;
}

body {
    margin: 0;
    font-family: 'Poppins', Helvetica, Arial, sans-serif;
    /* font-size: 1rem; */
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff;
}

* {
    font-family: 'Poppins', Helvetica, Arial, sans-serif;
}

html,
body {
    height: 100%;
}

.neat-bg {
    background: $neat-color-blue;
}

.close {
    float: right;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1;
    color: #1e2022;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
}

.close:hover {
    color: #1e2022;
    text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
    opacity: 0.75;
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

a.close.disabled {
    pointer-events: none;
}

.dummie-style {
    color: blue;
}

.custom-btn {
    background: #026BEA;
    border: 0.648961px solid #026BEA;
    box-sizing: border-box;
    box-shadow: 0px 3.2448px 6.48961px rgba(0, 0, 0, 0.15);
    border-radius: 64.8961px;
}

.cdk-overlay-container {
    z-index: 1050;
}

.mat-dialog-title {
    // font: 500 20px/32px Poppins, "Helvetica Neue", sans-serif;
    font-family: $mainfont;
}

// .mat-dialog-content {
//     overflow: auto;
// }
// .mat-dialog-content {
//     &.ps .ps__scrollbar-y-rail {
//         left: 2px;
//         right: auto;
//         background: none;
//         width: 6px;
//     }
// }
.cdk-global-overlay-wrapper {
    // display: flex;
    // position: absolute;
    // z-index: 1000;
    // pointer-events: auto;
    overflow: auto;
}

.dialog-header {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    h6 {
        color: $neat-color-blue;
        font-weight: 500;
    }
    button {
        height: 0;
    }
}

.swal2-container {
    z-index: 2147483646 !important;
}

.swal2-content {
    /* color: rgb(145, 3, 3); */
    font-size: 0.875rem !important;
}

.swal2-close {
    margin-top: 20px;
    margin-right: 20px;
    font-size: 20px !important; /* Ajusta este valor según el tamaño deseado */
    line-height: 20px !important; /* Asegura que el icono esté centrado */
}


/* .swal2-header {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    justify-content: center !important;
    .swal2-icon,
    .swal2-image {
        flex: 1 0 50% !important;
    }
} */

.neat-video-dialog-container .mat-dialog-container {
    padding: 0 !important;
    background-color: transparent !important;
}

.neat-club-terms-modal .mat-dialog-container {
    padding: unset !important;
    position: relative;
}

.neat-club-modal .mat-dialog-container {
    border-radius: 15px;
    background: #151F6D;
    overflow: hidden;
}

.dialog-modal-border-radius .mat-dialog-container {
    border-radius: 16px !important;
}

.dialog-retry-transfer .mat-dialog-container {
    border-radius: 16px !important;
    padding-top: 0 !important;
    position: relative;
    overflow: hidden;
}

.automate-payment .mat-dialog-container {
    border-radius: 20px !important;
}

.neat-promotion .mat-dialog-container {
    padding: 2px !important;
    background: #3368f0;
}


.payment-methods-modal .mat-dialog-container {
    padding: unset !important;
    position: relative;
}

.cuenta-por-rut .mat-dialog-container {
    @media (max-width: 767px) {
        padding: 18px;    
    }
}

.onboarding-modal .mat-dialog-container {
    // overflow: inherit !important;
    @media only screen and (max-device-width: 350px){
        height: 100vh !important;
        overflow-y: auto !important;
    }
    @media (min-device-width: 351px){
        display: inline-table !important;
    }
}

.onboarding-modal-auto .mat-dialog-container {
    // overflow: inherit !important;
    @media only screen and (max-device-width: 350px){
        height: auto !important;
        overflow-y: auto !important;
    }
    @media (min-device-width: 351px){
        display: inline-table !important;
    }
}

.neat-prices-dialog-container .mat-dialog-container {
    padding: 0 !important;
    .mat-dialog-content {
        max-height: 95vh;
        @media (max-width: 768px){
            max-height: 65vh;
        }
    }
}

.entity-added-dialog .mat-dialog-container {
    @media (max-width: 767px) {
        padding: 14px;
        z-index: 1080; 
    }
}

.custom-scroll::-webkit-scrollbar {
    height: 10px;
    width: 10px;
    background-color: $primary-80;
}

.custom-scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
    border-radius: 10px;
}

.custom-scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: $primary-80;
}

.mat-dialog-container::-webkit-scrollbar {
    width: 10px;
    background-color: #F5F5F5;
}

.mat-dialog-container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
    border-radius: 10px;
}

.mat-dialog-container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: $primary-80;
}


.cdk-global-scrollblock {
    position: unset !important;
    width: 100% !important;
    overflow-y: hidden !important;
    filter: unset !important;
}


mat-card {
    .premium-card-container-title {
        align-items: center !important;
        all: unset;
        background: linear-gradient(60deg, #244fbd, #3368f0);
        box-shadow: 1px 2px 7px rgba(0, 0, 0, 0.35);
        font-family: 'Poppins', Helvetica, Arial, sans-serif;
        color: #fff !important;
        display: block;
        display: flex;
        justify-content: center;
        padding: 10px 15px;
        position: absolute;
        right: -15px;
        top: -20px;
        z-index: 5;
        &::before {
            all: unset;
            border-right: 15px solid transparent;
            // border-top: 15px solid #001d3a;
            border-top: 15px solid #001cca;
            content: '';
            height: 0;
            position: absolute;
            right: 0;
            top: 100%;
            width: 0;
        }
        p {
            all: unset;
            filter: brightness(100000);
            font-size: 14px;
            font-weight: 500;
            line-height: 0;
            margin: 0 0 0 4px;
            text-transform: uppercase;
        }
    }
}

.u-sidebar--account__toggle-img {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
}

.img-lazyloaded {
    transition: opacity 1s;
    opacity: 0;
    &.ng-lazyloaded {
        opacity: 1;
    }
}

picture.picture-lazyloaded {
    img {
        transition: opacity 1s;
        opacity: 0;
        @media (max-width: 767px) {
            max-height: 475px !important;
        }
    }
    img.ng-lazyloaded {
        opacity: 1;
    }
}

.material-icons {
    font-family: 'Material Icons' !important;
}

/*scrollbar styling*/

html::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
    border-radius: 10px;
}

html::-webkit-scrollbar
{
    width: 10px;
    background-color: #F5F5F5;
}

html::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    background: $primary-80;
}

/* img {
    max-width: 100%;
    height: auto;
} */

.flex-center {
    display: flex; 
    align-items: center; 
    justify-content: center;
}

.c-pointer {
    cursor: pointer;
}

// intercom fix for mobile

.intercom-lightweight-app-launcher {
    @media (max-width: 767px) {
        bottom: 60px !important;
        right: 15px !important;
    }
}

// new styles 27-01-2021

.new-title {
    color: #2F2F2F;
    font-family: Poppins;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    @media (max-width: 767px) { 
    }
}

.new-subtitle {
    display: block !important;
    color: #808080;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    @media (max-width: 767px) {
    }
}

.new-space-bottom-1 {
    padding-bottom: 1rem !important;
}

.new-card {
    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    display: block;
    position: relative;
    padding: 16px;
    border-radius: 4px;
}

.new-card-shadow {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

@media (min-width: 768px) {
    .d-md-contents {
        display: contents !important;
    }
}

// design system 

@mixin text($colour, $size) {
    color: $colour;
    font-size: $size;
}

@mixin spacer-vertical($spaceTop, $spaceBottom) {
    margin-top: $spaceTop;
    margin-bottom: $spaceBottom;
}

@mixin spacer-horizontal($spaceLeft, $spaceRight) {
    margin-left: $spaceLeft;
    margin-right: $spaceRight;
}


.heading-h1 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 42px;
    line-height: 110%;
    letter-spacing: -0.02em;
    color: #282828;
    @media (max-width: 767px) {
        font-size: 36px;
        line-height: unset;
        text-align: center;
    }
}

.heading-h2 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 300;
    font-size: 58px;
    line-height: 110%;
    align-items: center;
    letter-spacing: -0.005em;
}

.heading-h3 {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 46px;
    line-height: 110%;
    align-items: center;
    @media (max-width: 767px) {
        font-size: 32px;
    }
}

.heading-h4 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 300;
    font-size: 33px;
    line-height: 110%;
    align-items: center;
    letter-spacing: -0.25px;
}

.heading-h5 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 110%;
    align-items: center;
}

.heading-h6 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 110%;
    align-items: center;
    letter-spacing: 0.15px;
}

.subtitle {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 130%;
    align-items: center;
    letter-spacing: 0.0015em;

}

.subtitle-2 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 130%;
    align-items: center;
    letter-spacing: 0.001em;
}

.body-1 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 130%;
    align-items: center;
    letter-spacing: 0.005em;
    @media (max-width: 767px) {
        font-size: 12px;
    }
}

.fw-600 {
    font-weight: 600;
}

.body-2 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 130%;
    align-items: center;
    letter-spacing: 0.0025em;
}

.white {
    color: #ffffff;
}

.blue {
    color: #3368f0;
}


.black {
    color: #282828;
}

.navy-neat {
    color: #3B4086 !important;
}

.bg-navy-neat {
    background-color: #3B4086;
}

.gray {
    color: #808080;
}

.gray-2 {
    color: #627178;
}

.neat-btn {
    cursor: pointer;
    background-color: transparent;
    border: 1px solid transparent;
    outline: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    display: flex;
    min-width: 150px;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    &__primary {
        color: #FFF;
        background: #4168E8;
        font-family: Poppins;
    }
    &__secondary {
        color: #3369f0;
        border-color: rgba(21, 31, 109, 0.2) !important;
    }
    &__terciary {
        border: 1px solid #D0D2E2;
        background:  #F5F8FE;
        color: #3369f0;
        border-color: rgba(21, 31, 109, 0.2) !important;
        text-align: center;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 125% */
    }
    &__medium {
        padding: 0.4rem 0.8rem;
        font-size: 0.9rem;
    }
}


.neat-btn:disabled {
    pointer-events: none;
    opacity: 0.35;
}

.primary-btn {
    background: #026BEA !important;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
    border-radius: 100px !important;
    align-items: center;
    padding: 8px 16px;
    color: white !important;
}

//others

.container-margin-horizontal {
    @media (max-width: 767px) {
        @include spacer-horizontal(2.5%,2.5% );
    }
}

.margin-vertical-30 {
    @include spacer-vertical(30px, 30px);
}

.bills-text {
    @include text(#3368f0, 0.65rem !important);
}

.alert-full-width {
    display: block;
    margin-right: -15px;
    margin-left: -15px;
}


// responsive every resolutions

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
    /* CSS */
    
  }
  
  /* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */
  
  @media (min-width: 1025px) and (max-width: 1280px) {
    
    /* CSS */
    
  }
  
  /* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */
  
  @media (min-width: 768px) and (max-width: 1024px) {
    
    /* CSS */
    
  }
  
  /* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */
  
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    
    /* CSS */
    
  }
  
  /* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */
  
  @media (min-width: 481px) and (max-width: 767px) {
    
    /* CSS */
    
  }
  
  /* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */
  
  @media (min-width: 320px) and (max-width: 480px) {
    
    /* CSS */
    
  }
 
  .secundary-btn {
    padding: 4.3834px 13.3626px;
    background: #F7F9FD;
    border: 0.648961px solid #026BEA;
    box-sizing: border-box;
    box-shadow: 0px 3.2448px 6.48961px rgba(0, 0, 0, 0.15);
    border-radius: 64.8961px;
    color: #3368f0;    
  }

  .off-white {
    color: #F7F9FD;
  }

  .modal-success {
    .progress-bar-background {
        color:#000 !important;
        background-color:#B7D3F9 !important;
        border-radius: 4px;
        height: 5px;
    }
    
    .progress-bar-container {
        padding: 0.01em 16px;
        height: 5px;
        border-radius: 4px;
        color:#B7D3F9 !important;
    }
    
    .blue-background {
        background-color:#3369F0 !important;
        height: 5px;
    }

    .category-text {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 130%;
        letter-spacing: 0.0015em;
        color: #3B4086;
        white-space: pre;
        b {
        font-weight: 700;
        }
        @media (max-width: 767px) {
        font-size: 12px;
        }
    }

    .box {
        background: #F4F8FE; 
        box-shadow: 0px 0px 10px rgba(51, 104, 240, 0.1); 
        border-radius: 6px; 
        padding: 23px; 
        @media (max-width: 767px) {
        padding: 13px; 
        h1 {
            margin-bottom: 0 !important;  
        }
        }
    }

    .starter {
        position: relative;
        display: inline-block;
        .level {
        padding-left: 8px;
        padding-top: 2px;
        padding-bottom: 3px;
        padding-right: 8px;
        background-image: linear-gradient(to right, rgba(51, 105, 240, 1), rgba(48, 151, 211, 1));
        border-radius: 53px;
        margin-right: 2px;
        color: rgba(244, 248, 254, 1);
        font-weight: 600;
        }
        .fill-background {
        background-image: linear-gradient(to right, rgba(51, 105, 240, 1), rgba(48, 151, 211, 1));
        height: 5px;
        }
        .primary-btn {
        font-weight: 600;
        color: rgba(244, 248, 254, 1);
        background-image: linear-gradient(to right, rgba(51, 105, 240, 1), rgba(48, 151, 211, 1));
        }
    }
    
    .starter::before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 6px;
        padding: 3px; /* control the border thickness */
        background: linear-gradient(to right, rgba(51, 105, 240, 1), rgba(48, 151, 211, 1));
        -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
                mask-composite: exclude;
        pointer-events: none;
    }


    .gold {
        position: relative;
        display: inline-block;
        .level {
        padding-left: 8px;
        padding-top: 2px;
        padding-bottom: 3px;
        padding-right: 8px;
        background-image: linear-gradient(to right, rgba(205, 150, 60, 1) , rgba(250, 249, 157, 1));
        border-radius: 53px;
        margin-right: 2px;
        color: #2F2F2F;
        font-weight: 600;
        }
        .fill-background {
        background-image: linear-gradient(to right, rgba(205, 150, 60, 1) , rgba(250, 249, 157, 1));
        height: 5px;
        }
        .primary-btn {
        font-weight: 600;
        color: #2F2F2F;
        background-image: linear-gradient(to right, rgba(205, 150, 60, 1) , rgba(250, 249, 157, 1));
        }
        .discount {
        margin-left: 9px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 19px;
        display: flex;
        align-items: center;
        color: #CD963C;
        white-space: pre;
        }
    }
    
    .gold::before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 6px;
        padding: 3px; /* control the border thickness */
        background: linear-gradient(to right, rgba(205, 150, 60, 1) , rgba(250, 249, 157, 1));
        -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
                mask-composite: exclude;
        pointer-events: none;
    }

    .platinum {
        position: relative;
        display: inline-block;
        .level {
        padding-left: 8px;
        padding-top: 2px;
        padding-bottom: 3px;
        padding-right: 8px;
        background-image: linear-gradient(to right, rgba(157, 157, 157, 1), rgba(193, 214, 222, 1));
        border-radius: 53px;
        margin-right: 2px;
        color: #2F2F2F;
        font-weight: 600;
        }
        .fill-background {
        background-image: linear-gradient(to right, rgba(157, 157, 157, 1), rgba(193, 214, 222, 1));
        height: 5px;
        }
        .primary-btn {
        font-weight: 600;
        color: #2F2F2F;
        background-image: linear-gradient(to right, rgba(157, 157, 157, 1), rgba(193, 214, 222, 1));
        }
        .discount {
        margin-left: 9px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 19px;
        display: flex;
        align-items: center;
        color: #9D9D9D;
        white-space: pre;
        }
    }
    
    .platinum::before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 6px;
        padding: 3px; /* control the border thickness */
        background: linear-gradient(to right, rgba(157, 157, 157, 1), rgba(193, 214, 222, 1));
        -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
                mask-composite: exclude;
        pointer-events: none;
    }
    
    
    .black {
        position: relative;
        display: inline-block;
        .level {
        padding-left: 8px;
        padding-top: 2px;
        padding-bottom: 3px;
        padding-right: 8px;
        background-image: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(69, 68, 68, 1));
        border-radius: 53px;
        margin-right: 2px;
        color: #FCFDFF;
        font-weight: 600;
        }
        .fill-background {
        background-image: linear-gradient(to right, rgba(0, 0, 0, 1) , rgba(69, 68, 68, 1));
        height: 5px;
        }
        .primary-btn {
        font-weight: 600;
        color: #FCFDFF;
        background-image: linear-gradient(to right, rgba(0, 0, 0, 1) , rgba(69, 68, 68, 1));
        }
        .discount {
        margin-left: 9px;
        font-weight: 600;
        font-size: 12px;
        line-height: 19px;
        display: flex;
        align-items: center;
        background: linear-gradient(90deg, #000000 0%, #454444 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        white-space: pre;
        }
    }
    
    .black::before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 6px;
        padding: 3px; /* control the border thickness */
        background: linear-gradient(to right, rgba(0, 0, 0, 1) , rgba(69, 68, 68, 1));
        -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
                mask-composite: exclude;
        pointer-events: none;
    }
    
    .diamond {
        position: relative;
        display: inline-block;
        .level {
        padding-left: 8px;
        padding-top: 2px;
        padding-bottom: 3px;
        padding-right: 8px;
        background-image: linear-gradient(to right, #ba4058, #eb5e4f);
        border-radius: 53px;
        margin-right: 2px;
        color: #FCFDFF;
        font-weight: 600;
        }
        .fill-background {
        background-image: linear-gradient(to right, rgba(186, 64, 88, 1) , rgba(235, 94, 79, 1));
        height: 5px;
        }
        .primary-btn {
        font-weight: 600;
        color: #FCFDFF;
        background-image: linear-gradient(to right, rgba(186, 64, 88, 1) , rgba(235, 94, 79, 1));
        }
        .discount {
        margin-left: 9px;
        font-weight: 600;
        font-size: 12px;
        line-height: 19px;
        display: flex;
        align-items: center;
        background: linear-gradient(270deg, #EB5E4F 0%, #BA4058 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        white-space: pre;
        }
    }
    
    .diamond::before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 6px;
        padding: 3px; /* control the border thickness */
        background: linear-gradient(to right, rgba(186, 64, 88, 1) , rgba(235, 94, 79, 1));
        -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
                mask-composite: exclude;
        pointer-events: none;
    }
    
    .infinite {
        position: relative;
        display: inline-block;
        .level {
        padding-left: 8px;
        padding-top: 2px;
        padding-bottom: 3px;
        padding-right: 8px;
        background-image: linear-gradient(to right, rgba(29, 74, 137, 1), rgba(49, 109, 201, 1));
        border-radius: 53px;
        margin-right: 2px;
        color: #FCFDFF;
        font-weight: 600;
        }
        .fill-background {
        background-image: linear-gradient(to right, rgba(29, 74, 137, 1), rgba(49, 109, 201, 1));
        height: 5px;
        }
        .primary-btn {
        font-weight: 600;
        color: #FCFDFF;
        background-image: linear-gradient(to right, rgba(29, 74, 137, 1), rgba(49, 109, 201, 1));
        }
        .discount {
        margin-left: 9px;
        font-weight: 600;
        font-size: 12px;
        line-height: 19px;
        background: linear-gradient(270deg, #316DC9 0%, #1D4A89 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        white-space: pre;
        }
    }
    
    .infinite::before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 6px;
        padding: 3px; /* control the border thickness */
        background: linear-gradient(to right, rgba(29, 74, 137, 1), rgba(49, 109, 201, 1));
        -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
                mask-composite: exclude;
        pointer-events: none;
    }
    
    
    .prime {
        position: relative;
        display: inline-block;
        .level {
        padding-left: 8px;
        padding-top: 2px;
        padding-bottom: 3px;
        padding-right: 8px;
        background-image: linear-gradient(to right, rgba(33, 2, 49, 1), rgba(107, 25, 142, 1));
        border-radius: 53px;
        margin-right: 2px;
        color: rgba(252, 253, 255, 1);
        font-weight: 600;
        }
        .fill-background {
        background-image: linear-gradient(to right, rgba(33, 2, 49, 1) , rgba(107, 25, 142, 1));
        height: 5px;
        }
        .primary-btn {
        font-weight: 600;
        color: rgba(252, 253, 255, 1);
        background-image: linear-gradient(to right, rgba(33, 2, 49, 1) , rgba(107, 25, 142, 1));
        }
        .discount {
        margin-left: 9px;
        font-weight: 600;
        font-size: 12px;
        line-height: 19px;
        display: flex;
        align-items: center;
        background: linear-gradient(90deg, #210231 0%, #6B198E 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        white-space: pre;
        }
    }
    
    .prime::before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 6px;
        padding: 3px; /* control the border thickness */
        background: linear-gradient(to right, rgba(33, 2, 49, 1), rgba(107, 25, 142, 1));
        -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
                mask-composite: exclude;
        pointer-events: none;
    }
    
    
    .months {
        font-weight: 400;
        font-size: 12px;
        line-height: 19px;
        display: flex;
        align-items: center;
        color: #3B4086;
        white-space: pre;
    }
  }
  
.mat-button-focus-overlay {
    background-color: transparent !important;
}
.modal-text-content-1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    width: 100%;
    font-size: 14px;
    display: block;
    line-height: 22.652px;
    letter-spacing: 0.001em;
    color: #3B4086;
}

.custom-modal-text-falabella {
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    text-align: center;
    color: #2F2F2F;
    @media (max-width: 767px) {
        font-size: 14px !important;
    }
}


.swal2-content .swalCustomClass {
    span {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        width: 100%;
        font-size: 14px;
        display: block;
        line-height: 22.652px;
        letter-spacing: 0.001em;
        color: #3B4086;
        text-align: left;
        li {
            padding-bottom: 10px;
        }
    }
}


.modal-text-error-1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 0.001em;
    color: #3B4086;
}

.modal-text-error-bold-1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    display: block;
    line-height: 130%;
    letter-spacing: 0.001em;
    color: #3B4086;
}

.font-weight-500 {
    font-weight: 500 !important;
}


.big-weight-700 {
    font-size: 16px !important;
    font-weight: 700 !important;
    @media screen and (max-width: 767px) {  
        font-size: 14px !important;
        font-weight: 600 !important;
    }
}

.typeform {
    div {
        width: 100%;
        margin-left: auto !important;
        border: 2px #ccc solid !important;
        position: absolute !important;
        right: 0 !important;
        z-index: 1000 !important;
        @media (max-width: 768px){
            width: 100%;
        }
    }
    iframe {
        border-radius: 0 !important;
        width: 100%!important;
        min-height: 100vh!important;
        height: 100%!important;
    }
}

.main-secundary-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    letter-spacing: 0.004em;
    color: #808080;
    @media (max-width: 768px) {
        font-size: 10px;
        line-height: 17px;
    }
}

.custom-text-14 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 0.004em;
    color: #2F2F2F;
    @media (max-width: 768px) {
        font-size: 12px;
        line-height: 21px;
    }
}

.custom-text-12 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    letter-spacing: 0.004em;
    color: #2F2F2F;
    @media (max-width: 768px) {
        font-size: 10px;
        line-height: 18px;
    }
}

.blue-pointer-text {
    color: #3368f0;
    cursor: pointer;
}

.dark-blue {
    color: #3B4086;
}
  
.extra-bold-neat-text {
    font-weight: 700;
    font-size: 18px;
    line-height: 29px;
    letter-spacing: 0.004em;
    color: #3369F0;
    @media (max-width: 768px) {
        font-size: 16px;
        line-height: 27px;
    }
}

.payment-method-box-big {
  background: linear-gradient(180deg, #3097D3 0%, #3369F0 100%);
  border-radius: 16px;
  width: 320px;
  height: 160px;
  position: relative;
  cursor: pointer;
  margin-bottom: 6px;
  .bank-logo {
    position: absolute; 
    top: 9%; 
    right: 0;
  }
  .brand-logo {
    width: 51px; 
    height: 34px; 
    position: absolute; 
    top: 9%; 
    right: 0;
  }
  .card-brand {
    text-transform: lowercase;
    text-transform: capitalize;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #FCFDFF;
    position: absolute; 
    left: 6%; 
    top: 12%;
    max-width: 90px;
    text-align: left;
  }
  .card-type {
    text-transform: capitalize;
    font-weight: 300;
    font-size: 16px;
    line-height: 110%;
    color: #F4F8FE;
    position: absolute; 
    left: 6%; 
    bottom: 12%
  }
  .card-number {
    font-weight: 300;
    font-size: 16px;
    line-height: 110%;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #FCFDFF;
    position: absolute; 
    right: 6%; 
    bottom: 12%;
  }
  @media (max-width: 767px) {
    min-width: 270px;
    width: 100%;
    max-width: 320px;
  }
  @media (max-width: 500px) {
    max-width: 100%;
  }
}


.payment-method-box-small {
    border: 3px solid transparent;
    cursor: pointer;
    width: max-content;
    height: 76px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 12px;
    background: linear-gradient(180deg, #3097D3 0%, #3369F0 100%);
    border-radius: 16px;
    .card-data {
        .card-brand {
            font-weight: 600!important;
            font-size: 14px!important;
            line-height: 110%!important;
            text-transform: capitalize!important;
            color: #FCFDFF!important;
            max-width: 90px !important;
            text-align: left !important;
            @media screen and (max-width: 767px) {
                font-size: 10px!important;
            }
        }
        .card-type {
            font-weight: 300!important;
            font-size: 12px!important;
            line-height: 110%!important;
            text-transform: capitalize!important;
            color: #FCFDFF!important;
            @media screen and (max-width: 767px) {
                font-size: 10px!important;
            }
        }
        .card-number {
            font-weight: 300!important;
            font-size: 12px!important;
            line-height: 110%!important;
            letter-spacing: 0.03em!important;
            text-transform: uppercase!important;
            color: #FCFDFF!important;        
            @media screen and (max-width: 767px) {
                font-size: 10px!important;
            }
        }
    }
}

.banco_estado {
    .payment-method-box-big, .payment-method-box-small {
        background: #616A72 !important;
    }
}

.banco_de_chile {
    .payment-method-box-big, .payment-method-box-small {
        background: #071B49 !important;
    }
}

.bci-prepago {
    .payment-method-box-big, .payment-method-box-small {
        background: #613DD2 !important;
    }
}

.bci {
    .payment-method-box-big, .payment-method-box-small {
        background: #F9D949 !important;
        .card-brand {
            color: #3B4086 !important;
          }
          .card-type {
            color: #3B4086 !important;
          }
          .card-number {
            color: #3B4086 !important;
          }
    }
}

.santander-prepago {
    .payment-method-box-big, .payment-method-box-small {
        background: #6200A7 !important;
    }
}

.santander {
    .payment-method-box-big, .payment-method-box-small {
        background: #EC0000 !important;
    }
}

.falabella {
    .payment-method-box-big, .payment-method-box-small {
        background: #007937 !important;
    }
}

.itau {
    .payment-method-box-big, .payment-method-box-small {
        background: #DC762D !important;
    }
}

.scotiabank-redcompra {
    .payment-method-box-big, .payment-method-box-small {
        background: #D43934 !important;
    }
}

.scotiabank {
    .payment-method-box-big, .payment-method-box-small {
        background: #EC0000 !important;
    }
}


.ripley-prepago {
    .payment-method-box-big, .payment-method-box-small {
        background: #523178 !important;
    }
}

.ripley {
    .payment-method-box-big, .payment-method-box-small {
        background: #8A4595 !important;
    }
}

.tricot {
    .payment-method-box-big, .payment-method-box-small {
        background: #5799AB !important;
    }
}

.tenpo {
    .payment-method-box-big, .payment-method-box-small {
        background: #121212 !important;
    }
}

.caja_los_heroes {
    .payment-method-box-big, .payment-method-box-small {
        background: #5799AB !important;
    }
}

.walmart {
    .payment-method-box-big, .payment-method-box-small {
        background: #007DC3 !important;
    }
}

.mercado_pago {
    .payment-method-box-big, .payment-method-box-small {
        background: #009FE2 !important;
    }
}

.salcobrand {
    .payment-method-box-big, .payment-method-box-small {
        background: #5799AB !important;
    }
}

.la_polar {
    .payment-method-box-big, .payment-method-box-small {
        background: #FE0000 !important;
    }
}

.global {
    .payment-method-box-big, .payment-method-box-small {
        background: #5799AB !important;
    }
}

.coopeuch {
    .payment-method-box-big, .payment-method-box-small {
        background: #F42534 !important;
    }
}

.cencosud {
    .payment-method-box-big, .payment-method-box-small {
        background: #095AA5 !important;
    }
}

.banco_security {
    .payment-method-box-big, .payment-method-box-small {
        background: #792995 !important;
    }
}

.consorcio {
    .payment-method-box-big, .payment-method-box-small {
        background: #58CADF !important;
    }
}

.internacional {
    .payment-method-box-big, .payment-method-box-small {
        background: #1364A3 !important;
    }
}

.bice {
    .payment-method-box-big, .payment-method-box-small {
        background: #396085 !important;
    }
}

.bbva {
    .payment-method-box-big, .payment-method-box-small {
        background: #5799AB !important;
    }
}

.abcdin {
    .payment-method-box-big, .payment-method-box-small {
        background: #DB034C !important;
    }
}

.corpbanca {
    .payment-method-box-big, .payment-method-box-small {
        background: #2E7CC1 !important;
    }
}

.rappi {
    .payment-method-box-big, .payment-method-box-small {
        background: #121212 !important;
    }
}

.neat-blue-btn {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 12px 24px !important;
  height: 44px !important;
  box-sizing: border-box !important;
  background: #4168E8 !important;
  border-radius: 100px !important;
  box-shadow: unset !important;
  span {
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    text-align: center !important;
    color: #FFFFFF !important;
    @media screen and (max-width: 767px) {
      font-size: 16px !important;
    }
  }
}

.neat-white-btn {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 12px 24px !important;
    height: 44px !important;
    box-sizing: border-box !important;
    background: #F4F8FE !important;
    border: 1px solid #3369F0 !important;
    border-radius: 100px !important;
    box-shadow: unset !important;
    span {
        font-family: 'Poppins' !important;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 16px !important;
        line-height: 140% !important;
        letter-spacing: 0.001em !important;
        color: #3369F0 !important;

        @media screen and (max-width: 767px) {
        font-size: 16px !important;
        }
    }
}

.neat-blue-btn:disabled {
    background: #4168E8 !important;
    opacity: 0.2 !important;
}  
  
.neat-white-btn:disabled {
    background: #4168E8 !important;
    opacity: 0.2 !important;
}

.neat-toggle {
    
    .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
        background-color: rgba(244, 248, 254, 1) !important;
    }
    
    .mat-slide-toggle .mat-slide-toggle-bar {
        width: 44px !important;
        height: 22px !important;
        border-radius: 20px !important;    
        background: rgba(244, 248, 254, 1) !important;
        border: 0.808824px solid rgba(51, 105, 240, 1) !important;
    }
    
    .mat-slide-toggle-thumb {
        background: #B7D3F9 !important;
        border: 0.173529px solid #3369F0 !important;
    }
    
    .mat-slide-toggle-thumb-container {
        top: 0px !important;
    }

    .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
        transform: translate3d(22px, 0, 0) !important;
    }
    
    .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
        border-color: #3368f0 !important;
        background: #3368f0 !important;
    }

}

.fw-regular {
    font-weight: $fw-regular !important;
}

.fw-medium {
    font-weight: $fw-medium !important;
}

.fw-semi {
    font-weight: $fw-semi !important;
}

.fw-bold {
    font-weight: $fw-bold !important;
}

.position-absolute-center {
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

.tenpo-alert {
  &__title {
    font-size: 20px !important;
    line-height: 30px !important;
    align-content: center !important;
    color: #151F6D !important;
    font-weight: 700 !important;
    text-wrap: balance;
  }

  &__body {
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #151F6D !important;
    text-wrap: balance;
  }
}


.header-box {
    background: var(--primary-5); 
    padding: 32px !important;
  }

.confetti-container {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 100vw !important;
    height: 100vh !important;
    pointer-events: none !important;
    z-index: 99999999999999 !important;
}
  
canvas {
    width: 100%;
    height: 100%;
}

.cursor-pointer {
    cursor: pointer;
}