.neat-card-header {
    border-color: rgba(51, 104, 240, 1) !important;
    background-color: rgba(51, 104, 240, 1) !important;
    color: #fff !important;
    border-top: 2px solid rgba(51, 104, 240, 1) !important;
    border-bottom: 2px solid rgba(51, 104, 240, 1) !important;
    border-left: 2px solid rgba(51, 104, 240, 1) !important;
    border-right: 2px solid rgba(51, 104, 240, 1) !important;
}

.neat-card {
    border-color: rgba(51, 104, 240, 1) !important;
    border-top: 3px solid rgba(51, 104, 240, 1) !important;
    border-bottom: 3px solid rgba(51, 104, 240, 1) !important;
    border-left: 3px solid rgba(51, 104, 240, 1) !important;
    border-right: 3px solid rgba(51, 104, 240, 1) !important;
    background-color: #fff !important;
}

.card-header:first-child {
    border-radius: 0 !important;
}


/* CARD CASCADE */

.card.card-cascade .view.view-cascade {
    -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    -webkit-border-radius: 0.25rem;
    border-radius: 0.25rem;
}

.card.card-cascade .view.view-cascade.gradient-card-header {
    padding: 1.6rem 1rem;
    text-align: center;
}

.card.card-cascade .view.view-cascade.gradient-card-header .card-header-title {
    font-weight: 500;
}

.card.card-cascade .view.view-cascade.gradient-card-header .btn-floating {
    background-color: rgba(255, 255, 255, 0.2);
}

.card.card-cascade.wider {
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: transparent;
}

.card.card-cascade.wider .view.view-cascade {
    z-index: 2;
}

.card.card-cascade.wider .card-body.card-body-cascade {
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    margin-left: 4%;
    margin-right: 4%;
    background: #fff;
    z-index: 1;
    -webkit-border-radius: 0 0 0.25rem 0.25rem;
    border-radius: 0 0 0.25rem 0.25rem;
}

.card.card-cascade.wider .card-body.card-body-cascade .card-footer {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
}

.card.card-cascade.wider.reverse .card-body.card-body-cascade {
    z-index: 3;
    margin-top: -1rem;
    -webkit-border-radius: 0.25rem;
    border-radius: 0.25rem;
    -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.card.card-cascade.narrower {
    margin-top: 1.25rem;
}

.card.card-cascade.narrower .view.view-cascade {
    margin-left: 4%;
    margin-right: 4%;
    margin-top: -1.25rem;
}

.card.card-cascade.panel-cascade .view {
    text-align: center;
    color: #fff;
}

.card.card-cascade.panel-cascade .list-group .list-group-item {
    margin-bottom: 0;
    border: 0;
    border-bottom: 1px solid #eee;
    color: #495057;
}

.card.card-cascade.panel-cascade .list-group .list-group-item:hover {
    background-color: #eee;
}

.view-cascade-position-md {
    margin-top: -100px;
    position: relative;
    /* z-index: 2; */
    padding: 2rem 0;
}

.card.view-cascade-position-md .view.view-cascade {
    position: absolute;
    /* border-radius: 0; */
    width: 350px;
    height: 80px;
    top: -30px;
}

.view-cascade-position-lg {
    margin-top: -100px;
    position: relative;
    /* z-index: 2; */
    padding: 2rem 0;
}

.card.view-cascade-position-lg .view.view-cascade {
    position: absolute;
    /* border-radius: 0; */
    width: 90%;
    height: 80px;
    top: -30px;
}

.view-cascade-wizard {
    margin-top: -100px;
    position: relative;
    /* z-index: 2; */
    padding: 2rem 0 0 0;
}

.card.view-cascade-wizard .view.view-cascade {
    position: absolute;
    /* border-radius: 0; */
    width: 350px;
    height: 80px;
    top: -30px;
}

@media (max-width: 740px) {
    .view-cascade-wizard,
    .view-cascade-position-lg,
    .view-cascade-position-md {
        margin-top: -15px;
    }
    .card.view-cascade-wizard .view.view-cascade,
    .card.view-cascade-position-lg .view.view-cascade,
    .card.view-cascade-position-md .view.view-cascade {
        width: 90%;
    }
}

@media (max-width: 375px) {
    .card.view-cascade-wizard .view.view-cascade h5,
    .card.view-cascade-position-md .view.view-cascade h5,
    .card.view-cascade-position-lg .view.view-cascade h5 {
        font-size: 16px;
    }
}


/* CARD HEADERS */

.card .card-header.card-header-image.no-shadow.shadow-normal {
    box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.card .card-header.card-header-image.no-shadow .colored-shadow {
    display: none !important;
}

.card .card-header-dark .card-icon,
.card .card-header-dark .card-text,
.card .card-header-dark:not(.card-header-icon):not(.card-header-text),
.card.bg-dark,
.card.card-rotate.bg-dark .front,
.card.card-rotate.bg-dark .back {
    background: linear-gradient(60deg, #1e263b, rgba(30, 38, 59, 0.8));
}

.card .card-header-secondary .card-icon,
.card .card-header-secondary .card-text,
.card .card-header-secondary:not(.card-header-icon):not(.card-header-text),
.card.bg-secondary,
.card.card-rotate.bg-secondary .front,
.card.card-rotate.bg-secondary .back {
    background: linear-gradient(60deg, #545d66, #77838f);
}

.bg-neat,
.card .card-header-neat .card-icon,
.card .card-header-neat .card-text,
.card .card-header-neat:not(.card-header-icon):not(.card-header-text),
.card.bg-neat,
.card.card-rotate.bg-neat .front,
.card.card-rotate.bg-neat .back {
    background: linear-gradient(60deg, #244fbd, rgba(51, 104, 240, 1));
}


/* color-neat: #3368f0 */

.card .card-header-warning-1 .card-icon,
.card .card-header-warning-1 .card-text,
.card .card-header-warning-1:not(.card-header-icon):not(.card-header-text),
.card.bg-warning-1,
.card.card-rotate.bg-warning-1 .front,
.card.card-rotate.bg-warning-1 .back {
    background: linear-gradient(60deg, #f5700c, #ff9800);
}

.card .card-header-success-1 .card-icon,
.card .card-header-success-1 .card-text,
.card .card-header-success-1:not(.card-header-icon):not(.card-header-text),
.card.bg-success-1,
.card.card-rotate.bg-success-1 .front,
.card.card-rotate.bg-success-1 .back {
    background: linear-gradient(60deg, #288c6c, #4ea752);
}

.card .card-header-danger-1 .card-icon,
.card .card-header-danger-1 .card-text,
.card .card-header-danger-1:not(.card-header-icon):not(.card-header-text),
.card.bg-danger-1,
.card.card-rotate.bg-danger-1 .front,
.card.card-rotate.bg-danger-1 .back {
    background: linear-gradient(60deg, #d22824, #da3a36);
}

.card .card-header-info-1 .card-icon,
.card .card-header-info-1 .card-text,
.card .card-header-info-1:not(.card-header-icon):not(.card-header-text),
.card.bg-info-1,
.card.card-rotate.bg-info-1 .front,
.card.card-rotate.bg-info-1 .back {
    background: linear-gradient(60deg, #029eb1, #25b1c3);
}

.card .card-header-violet-1 .card-icon,
.card .card-header-violet-1 .card-text,
.card .card-header-violet-1:not(.card-header-icon):not(.card-header-text),
.card.bg-violet-1,
.card.card-rotate.bg-violet-1 .front,
.card.card-rotate.bg-violet-1 .back {
    background: linear-gradient(60deg, #7b1fa2, #913f9e);
}

.card.bg-violet-2,
.card .card-header-violet-2 .card-icon,
.card .card-header-violet-2 .card-text,
.card .card-header-violet-2:not(.card-header-icon):not(.card-header-text),
.card.card-rotate.bg-violet-2 .back,
.card.card-rotate.bg-violet-2 .front {
    background: linear-gradient(60deg, #ab47bc, #8e24aa);
}

.card.bg-info-2,
.card .card-header-info-2 .card-icon,
.card .card-header-info-2 .card-text,
.card .card-header-info-2:not(.card-header-icon):not(.card-header-text),
.card.card-rotate.bg-info-2 .back,
.card.card-rotate.bg-info-2 .front {
    background: linear-gradient(60deg, #26c6da, #00acc1);
}

.card.bg-success-2,
.card .card-header-success-2 .card-icon,
.card .card-header-success-2 .card-text,
.card .card-header-success-2:not(.card-header-icon):not(.card-header-text),
.card.card-rotate.bg-success-2 .back,
.card.card-rotate.bg-success-2 .front {
    background: linear-gradient(60deg, #66bb6a, #43a047);
}

.card.bg-warning-2,
.card .card-header-warning-2 .card-icon,
.card .card-header-warning-2 .card-text,
.card .card-header-warning-2:not(.card-header-icon):not(.card-header-text),
.card.card-rotate.bg-warning-2 .back,
.card.card-rotate.bg-warning-2 .front {
    background: linear-gradient(60deg, #ffa726, #fb8c00);
}

.card.bg-danger-2,
.card .card-header-danger-2 .card-icon,
.card .card-header-danger-2 .card-text,
.card .card-header-danger-2:not(.card-header-icon):not(.card-header-text),
.card.card-rotate.bg-danger-2 .back,
.card.card-rotate.bg-danger-2 .front {
    background: linear-gradient(60deg, #ef5350, #e53935);
}

.card.bg-rose,
.card .card-header-rose .card-icon,
.card .card-header-rose .card-text,
.card .card-header-rose:not(.card-header-icon):not(.card-header-text),
.card.card-rotate.bg-rose .back,
.card.card-rotate.bg-rose .front {
    background: linear-gradient(60deg, #ec407a, #d81b60);
}

.card .bg-dark,
.card .card-header-dark .card-icon,
.card .card-header-dark .card-text,
.card .card-header-dark:not(.card-header-icon):not(.card-header-text),
.card .card-header-dark .card-icon,
.card .card-header-dark .card-text,
.card .card-header-dark:not(.card-header-icon):not(.card-header-text) {
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(30, 38, 59, 0.8);
}

.card .bg-secondary,
.card .card-header-secondary .card-icon,
.card .card-header-secondary .card-text,
.card .card-header-secondary:not(.card-header-icon):not(.card-header-text),
.card .card-header-secondary .card-icon,
.card .card-header-secondary .card-text,
.card .card-header-secondary:not(.card-header-icon):not(.card-header-text) {
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px #77838fc5;
}

.card .bg-neat,
.card .card-header-neat .card-icon,
.card .card-header-neat .card-text,
.card .card-header-neat:not(.card-header-icon):not(.card-header-text),
.card .card-header-neat .card-icon,
.card .card-header-neat .card-text,
.card .card-header-neat:not(.card-header-icon):not(.card-header-text) {
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgb(40, 78, 175) !important;
}

.card .card-header-violet-1 .card-icon,
.card .card-header-violet-1 .card-text,
.card .card-header-violet-1:not(.card-header-icon):not(.card-header-text),
.card .card-header-violet-2 .card-icon,
.card .card-header-violet-2 .card-text,
.card .card-header-violet-2:not(.card-header-icon):not(.card-header-text) {
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(156, 39, 176, 0.4);
}

.card .card-header-danger-1 .card-icon,
.card .card-header-danger-1 .card-text,
.card .card-header-danger-1:not(.card-header-icon):not(.card-header-text),
.card .card-header-danger-2 .card-icon,
.card .card-header-danger-2 .card-text,
.card .card-header-danger-2:not(.card-header-icon):not(.card-header-text) {
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(244, 67, 54, 0.4);
}

.card .card-header-rose .card-icon,
.card .card-header-rose .card-text,
.card .card-header-rose:not(.card-header-icon):not(.card-header-text) {
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4);
}

.card .card-header-warning-1 .card-icon,
.card .card-header-warning-1 .card-text,
.card .card-header-warning-1:not(.card-header-icon):not(.card-header-text),
.card .card-header-warning-2 .card-icon,
.card .card-header-warning-2 .card-text,
.card .card-header-warning-2:not(.card-header-icon):not(.card-header-text) {
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(255, 152, 0, 0.4);
}

.card .card-header-info-1 .card-icon,
.card .card-header-info-1 .card-text,
.card .card-header-info-1:not(.card-header-icon):not(.card-header-text),
.card .card-header-info-2 .card-icon,
.card .card-header-info-2 .card-text,
.card .card-header-info-2:not(.card-header-icon):not(.card-header-text) {
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(0, 188, 212, 0.4);
}

.card .card-header-success-1 .card-icon,
.card .card-header-success-1 .card-text,
.card .card-header-success-1:not(.card-header-icon):not(.card-header-text),
.card .card-header-success-2 .card-icon,
.card .card-header-success-2 .card-text,
.card .card-header-success-2:not(.card-header-icon):not(.card-header-text) {
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(76, 175, 80, 0.4);
}


/* CARD HEADERS */


/* CARD STATS */

.card .card-stats {
    background: transparent;
    display: flex;
}

.card .card-stats .author,
.card .card-stats .stats {
    display: inline-flex;
}

.card-stats .card-header.card-header-icon,
.card-stats .card-header.card-header-text {
    text-align: right;
}

.card-stats .card-header .card-icon+.card-category,
.card-stats .card-header .card-icon+.card-title {
    padding-top: 10px;
}

.card-stats .card-header.card-header-icon .card-category,
.card-stats .card-header.card-header-icon .card-title,
.card-stats .card-header.card-header-text .card-category,
.card-stats .card-header.card-header-text .card-title {
    margin: 0;
}

.card-stats .card-header .card-category {
    margin-bottom: 0;
    margin-top: 0;
}

.card-stats .card-header .card-category:not([class*='text-']) {
    color: #999;
    font-size: 14px;
}


/* .card-stats .card-header+.card-footer {
    border-top: 1px solid #eee;
    margin-top: 20px;
} */

.card-stats .card-header.card-header-icon i {
    font-size: 36px;
    line-height: 56px;
    width: 56px;
    height: 56px;
    text-align: center;
}

.card-stats .card-body {
    text-align: right;
}

.card .card-body+.card-footer .stats,
.card .card-footer .stats {
    color: #999;
    font-size: 12px;
    line-height: 22px;
}

.card .card-body+.card-footer .stats .card-category,
.card .card-footer .stats .card-category {
    padding-top: 7px;
    padding-bottom: 7px;
    margin: 0;
}

.card .card-body+.card-footer .stats i,
.card .card-footer .stats i {
    position: relative;
    top: 4px;
    font-size: 16px;
}

.card [class*='card-header-']:not(.card-header-icon):not(.card-header-text):not(.card-header-image):not(.card-header-icon):not(.card-header-text):not(.card-header-image):not(.mat-card-header):not(.mat-card-header-text) {
    border-radius: 3px;
    margin-top: -20px;
    padding: 15px;
}

.card [class*='card-header-'] .card-icon,
.card [class*='card-header-'] .card-text {
    border-radius: 3px;
    background-color: #999;
    padding: 15px;
    margin-top: -20px;
    margin-right: 15px;
    float: left;
}

.card [class*='card-header-']:not(.mat-card-header):not(.mat-card-header-text) {
    margin: 0 15px;
    padding: 0;
    position: relative;
}

.card .card-header {
    z-index: 3 !important;
}

.card .card-category:not([class*='text-']) {
    color: #999;
}

.card .card-category {
    margin-top: 10px;
}

.card .card-category i {
    position: relative;
    top: 8px;
    line-height: 0;
}

.card[class*='bg-'],
.card[class*='bg-'] .card-title,
.card[class*='bg-'] .card-title a,
.card[class*='bg-'] .icon i,
.card [class*='card-header-'],
.card [class*='card-header-'] .card-title,
.card [class*='card-header-'] .card-title a,
.card [class*='card-header-'] .icon i {
    color: #fff;
}

.card[class*='bg-'] .icon i,
.card [class*='card-header-'] .icon i {
    border-color: hsla(0, 0%, 100%, 0.25);
}

.card[class*='bg-'] .author a,
.card[class*='bg-'] .card-category,
.card[class*='bg-'] .card-description,
.card[class*='bg-'] .stats,
.card [class*='card-header-'] .author a,
.card [class*='card-header-'] .card-category,
.card [class*='card-header-'] .card-description,
.card [class*='card-header-'] .stats {
    color: hsla(0, 0%, 100%, 0.8);
}

.card .card-header.card-header-icon .card-title,
.card .card-header.card-header-text .card-title {
    margin-top: 15px;
    color: #3c4858;
}

.card .card-header .card-category {
    margin: 0;
}

.card.card-plain .card-header.card-header-icon+.card-body .card-category,
.card.card-plain .card-header.card-header-icon+.card-body .card-title {
    margin-top: -20px;
}

.card .card-body+.card-footer .stats .card-category,
.card .card-footer .stats .card-category {
    padding-top: 7px;
    padding-bottom: 7px;
    margin: 0;
}

.card [class*='card-header-'] .card-title+.card-category {
    color: hsla(0, 0%, 100%, 0.8);
}

.card [class*='card-header-'] .card-title+.card-category a {
    color: #fff;
}

.card-stats .card-header.card-header-icon,
.card-stats .card-header.card-header-text {
    text-align: right;
}

.card-stats .card-header .card-icon+.card-category,
.card-stats .card-header .card-icon+.card-title {
    padding-top: 10px;
}

.card-stats .card-header.card-header-icon .card-category,
.card-stats .card-header.card-header-icon .card-title,
.card-stats .card-header.card-header-text .card-category,
.card-stats .card-header.card-header-text .card-title {
    margin: 0;
}

.card-stats .card-header .card-category {
    margin-bottom: 0;
    margin-top: 0;
}

.card-stats .card-header .card-category:not([class*='text-']) {
    color: #999;
    font-size: 14px;
}

.card-stats>.card-footer {
    border-top: none !important;
}

@media (max-width: 991px) {
    .card.card-stats {
        margin: 15px 0 !important;
    }
}


/* CARD STATS */


/* CARD VIDEO */

.card .card-video {
    overflow: hidden;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

mat-card .card-video {
    overflow: hidden;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    transform-style: preserve-3d;
}


/* FIN CARD VIDEO */


/* Material card */

.mat-card.card-video {
    padding: 0 !important;
    .mat-card-content {
        padding: 16px !important;
    }
    .mat-card-footer {
        margin-left: 5px !important;
        margin-right: 5px !important;
        padding: 8px 0 !important;
    }
}

.mat-card-title.black {
    color: #212121 !important;
}

.mat-card-title.neat-blue {
    color: $neat-color-blue !important;
}


/* Material card */


/* Collapse */

.card-collapse {
    padding: 0;
    transition: 0.3s ease-in-out;
}

.card-collapse:hover {
    background-color: #f8fafd;
}

.card-collapse .card-body {
    color: #77838f;
}

.card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #e7eaf3;
    border-radius: 0.25rem;
}

.card>hr {
    margin-right: 0;
    margin-left: 0;
}

.card>.list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.card>.list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem;
}

.card-title {
    margin-bottom: 1rem;
}

.card-subtitle {
    margin-top: -0.5rem;
    margin-bottom: 0;
}

.card-text:last-child {
    margin-bottom: 0;
}

.card-link:hover {
    text-decoration: none;
}

.card-link+.card-link {
    margin-left: 1rem;
}

.card-header {
    padding: 1rem 1rem;
    margin-bottom: 0;
    background-color: #fff;
    border-bottom: 1px solid #e7eaf3;
}

.card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header+.list-group .list-group-item:first-child {
    border-top: 0;
}

.card-footer {
    padding: 1rem 1rem;
    background-color: #fff;
    border-top: 1px solid #e7eaf3;
}

.card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
    margin-right: -0.5rem;
    margin-bottom: -1rem;
    margin-left: -0.5rem;
    border-bottom: 0;
}

.card-header-pills {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
}

.card-img {
    width: 100%;
    border-radius: calc(0.25rem - 1px);
}

.card-img-top {
    width: 100%;
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-bottom {
    width: 100%;
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
}

.card-deck .card {
    margin-bottom: 15px;
}

@media (min-width: 576px) {
    .card-deck {
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        margin-right: -15px;
        margin-left: -15px;
    }
    .card-deck .card {
        display: -ms-flexbox;
        display: flex;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
        -ms-flex-direction: column;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px;
    }
}

.card-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
}

.card-group>.card {
    margin-bottom: 15px;
}

@media (min-width: 576px) {
    .card-group {
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    }
    .card-group>.card {
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
        margin-bottom: 0;
    }
    .card-group>.card+.card {
        margin-left: 0;
        border-left: 0;
    }
    .card-group>.card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .card-group>.card:not(:last-child) .card-img-top,
    .card-group>.card:not(:last-child) .card-header {
        border-top-right-radius: 0;
    }
    .card-group>.card:not(:last-child) .card-img-bottom,
    .card-group>.card:not(:last-child) .card-footer {
        border-bottom-right-radius: 0;
    }
    .card-group>.card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .card-group>.card:not(:first-child) .card-img-top,
    .card-group>.card:not(:first-child) .card-header {
        border-top-left-radius: 0;
    }
    .card-group>.card:not(:first-child) .card-img-bottom,
    .card-group>.card:not(:first-child) .card-footer {
        border-bottom-left-radius: 0;
    }
}

.card-columns .card {
    margin-bottom: 1rem;
}

@media (min-width: 576px) {
    .card-columns {
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3;
        -webkit-column-gap: 1.25rem;
        -moz-column-gap: 1.25rem;
        column-gap: 1.25rem;
        orphans: 1;
        widows: 1;
    }
    .card-columns .card {
        display: inline-block;
        width: 100%;
    }
}

.accordion>.card {
    overflow: hidden;
}

.accordion>.card:not(:first-of-type) .card-header:first-child {
    border-radius: 0;
}

.accordion>.card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0;
}

.accordion>.card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.accordion>.card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.accordion>.card .card-header {
    margin-bottom: -1px;
}

.card-btn {
    color: #1e2022;
    text-align: left;
    white-space: inherit;
}

.card-btn-arrow {
    display: inline-block;
    color: #377dff;
    margin-left: 1rem;
    transition: 0.3s ease-in-out;
}

.collapsed .card-btn-arrow {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
}


/* IE image rendering fix */

.card-img-top,
.card-img-bottom {
    min-height: 1px;
}


/*------------------------------------
  Card Gutters
------------------------------------*/

@media (min-width: 576px) {
    .card-sm-gutters-1 {
        margin-right: -0.25rem;
        margin-left: -0.25rem;
    }
    .card-sm-gutters-1 .card {
        margin-right: 0.25rem;
        margin-left: 0.25rem;
    }
    .card-sm-gutters-2 {
        margin-right: -0.5rem;
        margin-left: -0.5rem;
    }
    .card-sm-gutters-2 .card {
        margin-right: 0.5rem;
        margin-left: 0.5rem;
    }
    .card-sm-gutters-3 {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem;
    }
    .card-sm-gutters-3 .card {
        margin-right: 0.9375rem;
        margin-left: 0.9375rem;
    }
}

@media (min-width: 768px) {
    .card-md-gutters-1 {
        margin-right: -0.25rem;
        margin-left: -0.25rem;
    }
    .card-md-gutters-1 .card {
        margin-right: 0.25rem;
        margin-left: 0.25rem;
    }
    .card-md-gutters-2 {
        margin-right: -0.5rem;
        margin-left: -0.5rem;
    }
    .card-md-gutters-2 .card {
        margin-right: 0.5rem;
        margin-left: 0.5rem;
    }
    .card-md-gutters-3 {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem;
    }
    .card-md-gutters-3 .card {
        margin-right: 0.9375rem;
        margin-left: 0.9375rem;
    }
}

@media (min-width: 992px) {
    .card-lg-gutters-1 {
        margin-right: -0.25rem;
        margin-left: -0.25rem;
    }
    .card-lg-gutters-1 .card {
        margin-right: 0.25rem;
        margin-left: 0.25rem;
    }
    .card-lg-gutters-2 {
        margin-right: -0.5rem;
        margin-left: -0.5rem;
    }
    .card-lg-gutters-2 .card {
        margin-right: 0.5rem;
        margin-left: 0.5rem;
    }
    .card-lg-gutters-3 {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem;
    }
    .card-lg-gutters-3 .card {
        margin-right: 0.9375rem;
        margin-left: 0.9375rem;
    }
}


/*------------------------------------
  Card Group - Break
------------------------------------*/

@media (max-width: 767.98px) {
    .card-group-sm-break {
        display: block;
    }
    .card-group-sm-break>.card:not(:last-child) {
        margin-bottom: -1px;
    }
    .card-group-sm-break>.card+.card {
        border-left: 1px solid #e7eaf3;
    }
    .card-group-sm-break>.card:first-child {
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
    .card-group-sm-break>.card:first-child .card-img-top,
    .card-group-sm-break>.card:first-child .card-header {
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
    }
    .card-group-sm-break>.card:first-child .card-img-bottom,
    .card-group-sm-break>.card:first-child .card-footer {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
    .card-group-sm-break>.card:last-child {
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    .card-group-sm-break>.card:last-child .card-img-top,
    .card-group-sm-break>.card:last-child .card-header {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    .card-group-sm-break>.card:last-child .card-img-bottom,
    .card-group-sm-break>.card:last-child .card-footer {
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
    }
}

@media (max-width: 991.98px) {
    .card-group-md-break {
        display: block;
    }
    .card-group-md-break>.card:not(:last-child) {
        margin-bottom: -1px;
    }
    .card-group-md-break>.card+.card {
        border-left: 1px solid #e7eaf3;
    }
    .card-group-md-break>.card:first-child {
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
    .card-group-md-break>.card:first-child .card-img-top,
    .card-group-md-break>.card:first-child .card-header {
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
    }
    .card-group-md-break>.card:first-child .card-img-bottom,
    .card-group-md-break>.card:first-child .card-footer {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
    .card-group-md-break>.card:last-child {
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    .card-group-md-break>.card:last-child .card-img-top,
    .card-group-md-break>.card:last-child .card-header {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    .card-group-md-break>.card:last-child .card-img-bottom,
    .card-group-md-break>.card:last-child .card-footer {
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
    }
}

@media (max-width: 1199.98px) {
    .card-group-lg-break {
        display: block;
    }
    .card-group-lg-break>.card:not(:last-child) {
        margin-bottom: -1px;
    }
    .card-group-lg-break>.card+.card {
        border-left: 1px solid #e7eaf3;
    }
    .card-group-lg-break>.card:first-child {
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
    .card-group-lg-break>.card:first-child .card-img-top,
    .card-group-lg-break>.card:first-child .card-header {
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
    }
    .card-group-lg-break>.card:first-child .card-img-bottom,
    .card-group-lg-break>.card:first-child .card-footer {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
    .card-group-lg-break>.card:last-child {
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    .card-group-lg-break>.card:last-child .card-img-top,
    .card-group-lg-break>.card:last-child .card-header {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    .card-group-lg-break>.card:last-child .card-img-bottom,
    .card-group-lg-break>.card:last-child .card-footer {
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
    }
}


/* FIN CARD CASCADE */