/* Dropdown */

.dropdown .dropdown-menu .dropdown-item:active {
    background-color: #757575;
}

.dropdown .dropdown-menu.dropdown-primary .dropdown-item.active,
.dropdown .dropdown-menu.dropdown-primary .dropdown-item:active,
.dropdown .dropdown-menu.dropdown-primary .dropdown-item:hover,
.dropleft .dropdown-menu.dropdown-primary .dropdown-item.active,
.dropleft .dropdown-menu.dropdown-primary .dropdown-item:active,
.dropleft .dropdown-menu.dropdown-primary .dropdown-item:hover,
.dropright .dropdown-menu.dropdown-primary .dropdown-item.active,
.dropright .dropdown-menu.dropdown-primary .dropdown-item:active,
.dropright .dropdown-menu.dropdown-primary .dropdown-item:hover,
.dropup .dropdown-menu.dropdown-primary .dropdown-item.active,
.dropup .dropdown-menu.dropdown-primary .dropdown-item:active,
.dropup .dropdown-menu.dropdown-primary .dropdown-item:hover {
    background-color: #3368f0 !important;
    color: #fff !important;
    // -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    // box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    -webkit-border-radius: 0.125rem;
    border-radius: 0.125rem;
}

.dropdown .dropdown-menu.dropdown-primary .dropdown-item.active.disabled,
.dropdown .dropdown-menu.dropdown-primary .dropdown-item:active.disabled,
.dropdown .dropdown-menu.dropdown-primary .dropdown-item:hover.disabled,
.dropleft .dropdown-menu.dropdown-primary .dropdown-item.active.disabled,
.dropleft .dropdown-menu.dropdown-primary .dropdown-item:active.disabled,
.dropleft .dropdown-menu.dropdown-primary .dropdown-item:hover.disabled,
.dropright .dropdown-menu.dropdown-primary .dropdown-item.active.disabled,
.dropright .dropdown-menu.dropdown-primary .dropdown-item:active.disabled,
.dropright .dropdown-menu.dropdown-primary .dropdown-item:hover.disabled,
.dropup .dropdown-menu.dropdown-primary .dropdown-item.active.disabled,
.dropup .dropdown-menu.dropdown-primary .dropdown-item:active.disabled,
.dropup .dropdown-menu.dropdown-primary .dropdown-item:hover.disabled {
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.dropdown .dropdown-menu.dropdown-danger .dropdown-item.active,
.dropdown .dropdown-menu.dropdown-danger .dropdown-item:active,
.dropdown .dropdown-menu.dropdown-danger .dropdown-item:hover,
.dropleft .dropdown-menu.dropdown-danger .dropdown-item.active,
.dropleft .dropdown-menu.dropdown-danger .dropdown-item:active,
.dropleft .dropdown-menu.dropdown-danger .dropdown-item:hover,
.dropright .dropdown-menu.dropdown-danger .dropdown-item.active,
.dropright .dropdown-menu.dropdown-danger .dropdown-item:active,
.dropright .dropdown-menu.dropdown-danger .dropdown-item:hover,
.dropup .dropdown-menu.dropdown-danger .dropdown-item.active,
.dropup .dropdown-menu.dropdown-danger .dropdown-item:active,
.dropup .dropdown-menu.dropdown-danger .dropdown-item:hover {
    background-color: #c00 !important;
    -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    -webkit-border-radius: 0.125rem;
    border-radius: 0.125rem;
}

.dropdown .dropdown-menu.dropdown-danger .dropdown-item.active.disabled,
.dropdown .dropdown-menu.dropdown-danger .dropdown-item:active.disabled,
.dropdown .dropdown-menu.dropdown-danger .dropdown-item:hover.disabled,
.dropleft .dropdown-menu.dropdown-danger .dropdown-item.active.disabled,
.dropleft .dropdown-menu.dropdown-danger .dropdown-item:active.disabled,
.dropleft .dropdown-menu.dropdown-danger .dropdown-item:hover.disabled,
.dropright .dropdown-menu.dropdown-danger .dropdown-item.active.disabled,
.dropright .dropdown-menu.dropdown-danger .dropdown-item:active.disabled,
.dropright .dropdown-menu.dropdown-danger .dropdown-item:hover.disabled,
.dropup .dropdown-menu.dropdown-danger .dropdown-item.active.disabled,
.dropup .dropdown-menu.dropdown-danger .dropdown-item:active.disabled,
.dropup .dropdown-menu.dropdown-danger .dropdown-item:hover.disabled {
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.dropdown .dropdown-menu.dropdown-default .dropdown-item.active,
.dropdown .dropdown-menu.dropdown-default .dropdown-item:active,
.dropdown .dropdown-menu.dropdown-default .dropdown-item:hover,
.dropleft .dropdown-menu.dropdown-default .dropdown-item.active,
.dropleft .dropdown-menu.dropdown-default .dropdown-item:active,
.dropleft .dropdown-menu.dropdown-default .dropdown-item:hover,
.dropright .dropdown-menu.dropdown-default .dropdown-item.active,
.dropright .dropdown-menu.dropdown-default .dropdown-item:active,
.dropright .dropdown-menu.dropdown-default .dropdown-item:hover,
.dropup .dropdown-menu.dropdown-default .dropdown-item.active,
.dropup .dropdown-menu.dropdown-default .dropdown-item:active,
.dropup .dropdown-menu.dropdown-default .dropdown-item:hover {
    background-color: #2bbbad !important;
    -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    -webkit-border-radius: 0.125rem;
    border-radius: 0.125rem;
}

.dropdown .dropdown-menu.dropdown-default .dropdown-item.active.disabled,
.dropdown .dropdown-menu.dropdown-default .dropdown-item:active.disabled,
.dropdown .dropdown-menu.dropdown-default .dropdown-item:hover.disabled,
.dropleft .dropdown-menu.dropdown-default .dropdown-item.active.disabled,
.dropleft .dropdown-menu.dropdown-default .dropdown-item:active.disabled,
.dropleft .dropdown-menu.dropdown-default .dropdown-item:hover.disabled,
.dropright .dropdown-menu.dropdown-default .dropdown-item.active.disabled,
.dropright .dropdown-menu.dropdown-default .dropdown-item:active.disabled,
.dropright .dropdown-menu.dropdown-default .dropdown-item:hover.disabled,
.dropup .dropdown-menu.dropdown-default .dropdown-item.active.disabled,
.dropup .dropdown-menu.dropdown-default .dropdown-item:active.disabled,
.dropup .dropdown-menu.dropdown-default .dropdown-item:hover.disabled {
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.dropdown .dropdown-menu.dropdown-secondary .dropdown-item.active,
.dropdown .dropdown-menu.dropdown-secondary .dropdown-item:active,
.dropdown .dropdown-menu.dropdown-secondary .dropdown-item:hover,
.dropleft .dropdown-menu.dropdown-secondary .dropdown-item.active,
.dropleft .dropdown-menu.dropdown-secondary .dropdown-item:active,
.dropleft .dropdown-menu.dropdown-secondary .dropdown-item:hover,
.dropright .dropdown-menu.dropdown-secondary .dropdown-item.active,
.dropright .dropdown-menu.dropdown-secondary .dropdown-item:active,
.dropright .dropdown-menu.dropdown-secondary .dropdown-item:hover,
.dropup .dropdown-menu.dropdown-secondary .dropdown-item.active,
.dropup .dropdown-menu.dropdown-secondary .dropdown-item:active,
.dropup .dropdown-menu.dropdown-secondary .dropdown-item:hover {
    background-color: #a6c !important;
    -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    -webkit-border-radius: 0.125rem;
    border-radius: 0.125rem;
}

.dropdown .dropdown-menu.dropdown-secondary .dropdown-item.active.disabled,
.dropdown .dropdown-menu.dropdown-secondary .dropdown-item:active.disabled,
.dropdown .dropdown-menu.dropdown-secondary .dropdown-item:hover.disabled,
.dropleft .dropdown-menu.dropdown-secondary .dropdown-item.active.disabled,
.dropleft .dropdown-menu.dropdown-secondary .dropdown-item:active.disabled,
.dropleft .dropdown-menu.dropdown-secondary .dropdown-item:hover.disabled,
.dropright .dropdown-menu.dropdown-secondary .dropdown-item.active.disabled,
.dropright .dropdown-menu.dropdown-secondary .dropdown-item:active.disabled,
.dropright .dropdown-menu.dropdown-secondary .dropdown-item:hover.disabled,
.dropup .dropdown-menu.dropdown-secondary .dropdown-item.active.disabled,
.dropup .dropdown-menu.dropdown-secondary .dropdown-item:active.disabled,
.dropup .dropdown-menu.dropdown-secondary .dropdown-item:hover.disabled {
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.dropdown .dropdown-menu.dropdown-success .dropdown-item.active,
.dropdown .dropdown-menu.dropdown-success .dropdown-item:active,
.dropdown .dropdown-menu.dropdown-success .dropdown-item:hover,
.dropleft .dropdown-menu.dropdown-success .dropdown-item.active,
.dropleft .dropdown-menu.dropdown-success .dropdown-item:active,
.dropleft .dropdown-menu.dropdown-success .dropdown-item:hover,
.dropright .dropdown-menu.dropdown-success .dropdown-item.active,
.dropright .dropdown-menu.dropdown-success .dropdown-item:active,
.dropright .dropdown-menu.dropdown-success .dropdown-item:hover,
.dropup .dropdown-menu.dropdown-success .dropdown-item.active,
.dropup .dropdown-menu.dropdown-success .dropdown-item:active,
.dropup .dropdown-menu.dropdown-success .dropdown-item:hover {
    background-color: #00c851 !important;
    -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    -webkit-border-radius: 0.125rem;
    border-radius: 0.125rem;
}

.dropdown .dropdown-menu.dropdown-success .dropdown-item.active.disabled,
.dropdown .dropdown-menu.dropdown-success .dropdown-item:active.disabled,
.dropdown .dropdown-menu.dropdown-success .dropdown-item:hover.disabled,
.dropleft .dropdown-menu.dropdown-success .dropdown-item.active.disabled,
.dropleft .dropdown-menu.dropdown-success .dropdown-item:active.disabled,
.dropleft .dropdown-menu.dropdown-success .dropdown-item:hover.disabled,
.dropright .dropdown-menu.dropdown-success .dropdown-item.active.disabled,
.dropright .dropdown-menu.dropdown-success .dropdown-item:active.disabled,
.dropright .dropdown-menu.dropdown-success .dropdown-item:hover.disabled,
.dropup .dropdown-menu.dropdown-success .dropdown-item.active.disabled,
.dropup .dropdown-menu.dropdown-success .dropdown-item:active.disabled,
.dropup .dropdown-menu.dropdown-success .dropdown-item:hover.disabled {
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.dropdown .dropdown-menu.dropdown-info .dropdown-item.active,
.dropdown .dropdown-menu.dropdown-info .dropdown-item:active,
.dropdown .dropdown-menu.dropdown-info .dropdown-item:hover,
.dropleft .dropdown-menu.dropdown-info .dropdown-item.active,
.dropleft .dropdown-menu.dropdown-info .dropdown-item:active,
.dropleft .dropdown-menu.dropdown-info .dropdown-item:hover,
.dropright .dropdown-menu.dropdown-info .dropdown-item.active,
.dropright .dropdown-menu.dropdown-info .dropdown-item:active,
.dropright .dropdown-menu.dropdown-info .dropdown-item:hover,
.dropup .dropdown-menu.dropdown-info .dropdown-item.active,
.dropup .dropdown-menu.dropdown-info .dropdown-item:active,
.dropup .dropdown-menu.dropdown-info .dropdown-item:hover {
    background-color: #33b5e5 !important;
    -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    -webkit-border-radius: 0.125rem;
    border-radius: 0.125rem;
}

.dropdown .dropdown-menu.dropdown-info .dropdown-item.active.disabled,
.dropdown .dropdown-menu.dropdown-info .dropdown-item:active.disabled,
.dropdown .dropdown-menu.dropdown-info .dropdown-item:hover.disabled,
.dropleft .dropdown-menu.dropdown-info .dropdown-item.active.disabled,
.dropleft .dropdown-menu.dropdown-info .dropdown-item:active.disabled,
.dropleft .dropdown-menu.dropdown-info .dropdown-item:hover.disabled,
.dropright .dropdown-menu.dropdown-info .dropdown-item.active.disabled,
.dropright .dropdown-menu.dropdown-info .dropdown-item:active.disabled,
.dropright .dropdown-menu.dropdown-info .dropdown-item:hover.disabled,
.dropup .dropdown-menu.dropdown-info .dropdown-item.active.disabled,
.dropup .dropdown-menu.dropdown-info .dropdown-item:active.disabled,
.dropup .dropdown-menu.dropdown-info .dropdown-item:hover.disabled {
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.dropdown .dropdown-menu.dropdown-warning .dropdown-item.active,
.dropdown .dropdown-menu.dropdown-warning .dropdown-item:active,
.dropdown .dropdown-menu.dropdown-warning .dropdown-item:hover,
.dropleft .dropdown-menu.dropdown-warning .dropdown-item.active,
.dropleft .dropdown-menu.dropdown-warning .dropdown-item:active,
.dropleft .dropdown-menu.dropdown-warning .dropdown-item:hover,
.dropright .dropdown-menu.dropdown-warning .dropdown-item.active,
.dropright .dropdown-menu.dropdown-warning .dropdown-item:active,
.dropright .dropdown-menu.dropdown-warning .dropdown-item:hover,
.dropup .dropdown-menu.dropdown-warning .dropdown-item.active,
.dropup .dropdown-menu.dropdown-warning .dropdown-item:active,
.dropup .dropdown-menu.dropdown-warning .dropdown-item:hover {
    background-color: #fb3 !important;
    -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    -webkit-border-radius: 0.125rem;
    border-radius: 0.125rem;
}

.dropdown .dropdown-menu.dropdown-warning .dropdown-item.active.disabled,
.dropdown .dropdown-menu.dropdown-warning .dropdown-item:active.disabled,
.dropdown .dropdown-menu.dropdown-warning .dropdown-item:hover.disabled,
.dropleft .dropdown-menu.dropdown-warning .dropdown-item.active.disabled,
.dropleft .dropdown-menu.dropdown-warning .dropdown-item:active.disabled,
.dropleft .dropdown-menu.dropdown-warning .dropdown-item:hover.disabled,
.dropright .dropdown-menu.dropdown-warning .dropdown-item.active.disabled,
.dropright .dropdown-menu.dropdown-warning .dropdown-item:active.disabled,
.dropright .dropdown-menu.dropdown-warning .dropdown-item:hover.disabled,
.dropup .dropdown-menu.dropdown-warning .dropdown-item.active.disabled,
.dropup .dropdown-menu.dropdown-warning .dropdown-item:active.disabled,
.dropup .dropdown-menu.dropdown-warning .dropdown-item:hover.disabled {
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.dropdown .dropdown-menu.dropdown-dark .dropdown-item.active,
.dropdown .dropdown-menu.dropdown-dark .dropdown-item:active,
.dropdown .dropdown-menu.dropdown-dark .dropdown-item:hover,
.dropleft .dropdown-menu.dropdown-dark .dropdown-item.active,
.dropleft .dropdown-menu.dropdown-dark .dropdown-item:active,
.dropleft .dropdown-menu.dropdown-dark .dropdown-item:hover,
.dropright .dropdown-menu.dropdown-dark .dropdown-item.active,
.dropright .dropdown-menu.dropdown-dark .dropdown-item:active,
.dropright .dropdown-menu.dropdown-dark .dropdown-item:hover,
.dropup .dropdown-menu.dropdown-dark .dropdown-item.active,
.dropup .dropdown-menu.dropdown-dark .dropdown-item:active,
.dropup .dropdown-menu.dropdown-dark .dropdown-item:hover {
    background-color: #2e2e2e !important;
    -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    -webkit-border-radius: 0.125rem;
    border-radius: 0.125rem;
}

.dropdown .dropdown-menu.dropdown-dark .dropdown-item.active.disabled,
.dropdown .dropdown-menu.dropdown-dark .dropdown-item:active.disabled,
.dropdown .dropdown-menu.dropdown-dark .dropdown-item:hover.disabled,
.dropleft .dropdown-menu.dropdown-dark .dropdown-item.active.disabled,
.dropleft .dropdown-menu.dropdown-dark .dropdown-item:active.disabled,
.dropleft .dropdown-menu.dropdown-dark .dropdown-item:hover.disabled,
.dropright .dropdown-menu.dropdown-dark .dropdown-item.active.disabled,
.dropright .dropdown-menu.dropdown-dark .dropdown-item:active.disabled,
.dropright .dropdown-menu.dropdown-dark .dropdown-item:hover.disabled,
.dropup .dropdown-menu.dropdown-dark .dropdown-item.active.disabled,
.dropup .dropdown-menu.dropdown-dark .dropdown-item:active.disabled,
.dropup .dropdown-menu.dropdown-dark .dropdown-item:hover.disabled {
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.dropdown .dropdown-menu.dropdown-ins .dropdown-item.active,
.dropdown .dropdown-menu.dropdown-ins .dropdown-item:active,
.dropdown .dropdown-menu.dropdown-ins .dropdown-item:hover,
.dropleft .dropdown-menu.dropdown-ins .dropdown-item.active,
.dropleft .dropdown-menu.dropdown-ins .dropdown-item:active,
.dropleft .dropdown-menu.dropdown-ins .dropdown-item:hover,
.dropright .dropdown-menu.dropdown-ins .dropdown-item.active,
.dropright .dropdown-menu.dropdown-ins .dropdown-item:active,
.dropright .dropdown-menu.dropdown-ins .dropdown-item:hover,
.dropup .dropdown-menu.dropdown-ins .dropdown-item.active,
.dropup .dropdown-menu.dropdown-ins .dropdown-item:active,
.dropup .dropdown-menu.dropdown-ins .dropdown-item:hover {
    background-color: #2e5e86 !important;
    -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    -webkit-border-radius: 0.125rem;
    border-radius: 0.125rem;
}

.dropdown .dropdown-menu.dropdown-ins .dropdown-item.active.disabled,
.dropdown .dropdown-menu.dropdown-ins .dropdown-item:active.disabled,
.dropdown .dropdown-menu.dropdown-ins .dropdown-item:hover.disabled,
.dropleft .dropdown-menu.dropdown-ins .dropdown-item.active.disabled,
.dropleft .dropdown-menu.dropdown-ins .dropdown-item:active.disabled,
.dropleft .dropdown-menu.dropdown-ins .dropdown-item:hover.disabled,
.dropright .dropdown-menu.dropdown-ins .dropdown-item.active.disabled,
.dropright .dropdown-menu.dropdown-ins .dropdown-item:active.disabled,
.dropright .dropdown-menu.dropdown-ins .dropdown-item:hover.disabled,
.dropup .dropdown-menu.dropdown-ins .dropdown-item.active.disabled,
.dropup .dropdown-menu.dropdown-ins .dropdown-item:active.disabled,
.dropup .dropdown-menu.dropdown-ins .dropdown-item:hover.disabled {
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.dropdown .dropdown-menu .dropdown-item,
.dropleft .dropdown-menu .dropdown-item,
.dropright .dropdown-menu .dropdown-item,
.dropup .dropdown-menu .dropdown-item {
    padding: 0.5rem;
    margin-left: 0;
    font-size: 0.9rem;
}

.dropdown .dropdown-menu .dropdown-item.disabled,
.dropleft .dropdown-menu .dropdown-item.disabled,
.dropright .dropdown-menu .dropdown-item.disabled,
.dropup .dropdown-menu .dropdown-item.disabled {
    color: #868e96;
}

.dropdown .dropdown-menu .dropdown-item.disabled:active,
.dropdown .dropdown-menu .dropdown-item.disabled:focus,
.dropdown .dropdown-menu .dropdown-item.disabled:hover,
.dropleft .dropdown-menu .dropdown-item.disabled:active,
.dropleft .dropdown-menu .dropdown-item.disabled:focus,
.dropleft .dropdown-menu .dropdown-item.disabled:hover,
.dropright .dropdown-menu .dropdown-item.disabled:active,
.dropright .dropdown-menu .dropdown-item.disabled:focus,
.dropright .dropdown-menu .dropdown-item.disabled:hover,
.dropup .dropdown-menu .dropdown-item.disabled:active,
.dropup .dropdown-menu .dropdown-item.disabled:focus,
.dropup .dropdown-menu .dropdown-item.disabled:hover {
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #868e96 !important;
    background-color: transparent !important;
}

.dropdown .dropdown-menu .dropdown-item:active,
.dropdown .dropdown-menu .dropdown-item:hover,
.dropleft .dropdown-menu .dropdown-item:active,
.dropleft .dropdown-menu .dropdown-item:hover,
.dropright .dropdown-menu .dropdown-item:active,
.dropright .dropdown-menu .dropdown-item:hover,
.dropup .dropdown-menu .dropdown-item:active,
.dropup .dropdown-menu .dropdown-item:hover {
    -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    background-color: #3368f0;
    color: #fff;
    -webkit-border-radius: 0.125rem;
    border-radius: 0.125rem;
    -webkit-transition: all 0.1s linear;
    -o-transition: all 0.1s linear;
    transition: all 0.1s linear;
}

.navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
}

.dropdown-menu.animated {
    -webkit-animation-duration: 0.55s;
    animation-duration: 0.55s;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease;
}

.dropdown .dropdown-menu {
    -webkit-transition: all 0.6s;
    -moz-transition: all 0.6s;
    -ms-transition: all 0.6s;
    -o-transition: all 0.6s;
    transition: all 0.6s;
    max-height: 0;
    display: block;
    overflow: hidden;
    opacity: 0;
}

.dropdown.show .dropdown-menu {
    max-height: 330px;
    opacity: 1;
}

.dropdown.show .dropdown-menu.dropdown-menu-lg {
    max-height: 600px;
    opacity: 1;
}

.dropdown .dropdown-menu {
    border: none;
}

.dropdown .dropdown-menu .dropdown-item {
    color: $neat-color-blue;
    letter-spacing: 1.2px;
}

.dropdown-toggle[aria-expanded='true']:after {
    transform: rotate(180deg);
}


/*for the animation*/

.dropdown-toggle:after {
    transition: 0.5s;
}

@media (max-width: 767) {
    .dropdown-toggle,
    .dropdown-toggle::after {
        display: flex !important;
        justify-content: space-between !important;
    }
}

.dropdown .dropdown-menu,
.dropleft .dropdown-menu,
.dropright .dropdown-menu,
.dropup .dropdown-menu {
    padding: 0;
}

.dropdown.show .dropdown-menu,
.dropleft.show .dropdown-menu,
.dropright.show .dropdown-menu,
.dropup.show .dropdown-menu {
    padding: 0.5rem;
}


/* Dropdown */