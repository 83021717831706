[tabindex='-1']:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

.w-fit-content {
  width: fit-content !important;
}

.swal-max-width {
  max-width: 400px !important;
}

.swal-max-width-430 {
  max-width: 430px !important;
}

.extended-swal-width {
  max-width: 500px !important;
}

.normal-text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  align-items: center;
  color: #3b4086;
}

.swal2-border-radius {
  -webkit-border-radius: 24px !important;
  -moz-border-radius: 24px !important;
  border-radius: 24px !important;
}

.min-h-100 {
  min-height: 100vh !important;
}

.custom-modal-h2 {
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 26px !important;
  display: flex !important;
  align-items: center !important;
  color: #3b4086 !important;
}

.custom-modal-falabella-title {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 23px !important;
  text-align: center !important;
  color: #3369f0 !important;
  @media (max-width: 767px) {
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 23px !important;
  }
}

.custom-modal-falabella-title-2 {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 32px !important;
  text-align: center !important;
  color: #2f2f2f !important;
  @media (max-width: 767px) {
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 23px !important;
  }
}

.custom-modal-servipag-title {
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 28px !important;
  text-align: left !important;
  color: #151f6d !important;
  width: 100% !important;
  margin: 0 !important;
  @media (max-width: 767px) {
    font-size: 16px !important;
  }
}

.custom-modal-servipag-list-title {
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  text-align: left !important;
  color: #f04e98 !important;
  width: 100% !important;
  margin-bottom: 8px !important;
  @media (max-width: 767px) {
  }
}

.custom-modal-import-list-title {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-align: left !important;
  color: #151f6d !important;
  width: 100% !important;
  @media (max-width: 767px) {
  }
}

.custom-modal-import-container {
  padding: 20px !important;
  background-color: #fff !important;
  border-radius: 16px !important;
  @media (max-width: 767px) {
  }
}

.custom-modal-import-list-subtitle {
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  text-align: left !important;
  color: #a1a5c5 !important;
  width: 100% !important;
  @media (max-width: 767px) {
  }
}

.custom-modal-import-list-value {
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  text-align: right !important;
  color: #151f6d !important;
  @media (max-width: 767px) {
  }
}

.custom-modal-logo-container {
  width: 44px !important;
  height: 44px !important;
  padding-left: 8px;
  padding-right: 8px;
  background-color: #fff !important;
  border-radius: 4px;
  margin-right: 8px;
  @media (max-width: 767px) {
  }
}

.custom-modal-logo {
  max-width: 28px;
  max-height: 28px;
  @media (max-width: 767px) {
  }
}

.custom-modal-list-entity {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f5f8fe;
  border-top-color: #d6e1fc;
  border-top-width: 1px;
  border-top-style: solid;
  border-bottom-color: #d6e1fc;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  padding: 16px;
  @media (max-width: 767px) {
  }
}

.custom-modal-servipag-list-text {
  text-align: left !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #151f6d !important;
  margin: 0 !important;
  @media (max-width: 767px) {
  }
}

.custom-modal-chat-disclaimer {
  text-align: center !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #3369f0 !important;
  width: 100% !important;
  cursor: pointer;
  margin: 0 !important;
}

.custom-modal-servipag-notification-subtitle {
  text-align: left !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #a1a5c5 !important;
  width: 100% !important;
  margin-top: 16px !important;
}

.custom-modal-error-notification-subtitle {
  text-align: center !important;
  font-weight: 500;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #a1a5c5 !important;
  width: 100% !important;
  margin-top: 16px !important;
  border-top: #f5f8fe 1px solid;
}

.swal2-title {
  font-size: 1rem !important;
}
.swal2-title-left {
  text-align: left !important;
  width: 100% !important;
}

.falabella-btn-modal {
  border-radius: 100px !important;
  font-weight: 600 !important;
  color: #fcfdff !important;
  background-image: linear-gradient(
    259.4deg,
    #43b02a 32.58%,
    #007a33 85.81%
  ) !important;
}

.gray-color {
  color: #848484 !important;
}

.secundary-text-fidelity-modal {
  font-family: Poppins !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 150% !important;
  text-align: center !important;
  color: #848484 !important;
}

abbr[data-original-title],
abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: $neat-color-blue;
  /* text-decoration: underline; */
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

/* Seccion estilo para background */

.view {
  position: relative;
  overflow: hidden;
  cursor: default;
  /* height: calc(100vh-72px); */
}

.view .mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-attachment: fixed;
}

/* .view img,
.view video {
    position: relative;
    display: block;
} */

.rgba-black-slight,
.rgba-black-slight:after {
  background-color: rgba(0, 0, 0, 0.1);
}

.rgba-black-light-dos,
.rgba-black-light-dos:after {
  background-color: rgba(0, 0, 0, 0.2);
}

.rgba-black-light,
.rgba-black-light:after {
  background-color: rgba(0, 0, 0, 0.3);
}

.rgba-black-medium,
.rgba-black-medium:after {
  background-color: rgba(0, 0, 0, 0.45);
}

.rgba-black-strong,
.rgba-black-strong:after {
  background-color: rgba(0, 0, 0, 0.7);
}

.rgba-black-gradient,
.rgba-black-gradient:after {
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.2)
  );
  @media (max-width: 767px) {
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.7),
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.2)
    );
  }
}

/* Fin Seccion estilo para background */

.cool-link::after {
  content: '';
  display: block;
  width: 0px;
  height: 2px;
  background: #3d6ce9;
  transition: width 0.3s;
}

.cool-link:hover::after {
  width: 100%;
  transition: width 0.3s;
}

.pointer {
  cursor: pointer !important;
}

.shadow-hover {
  -webkit-transition: all 0.6s ease-out;
  -o-transition: all 0.6s ease-out;
  transition: all 0.6s ease-out;
}

.vh-90 {
  height: 90vh !important;
}

.vh-80 {
  height: 80vh !important;
}

.vh-70 {
  height: 70vh !important;
}

/* GOOGLE RECAPTCH */

.grecaptcha-badge {
  visibility: hidden !important;
}

/* FIN GOOGLE RECAPTCH */

/* BORDERS */

.border-left-neat {
  border-left: 1px solid #377dff !important;
}

.border-right-neat {
  border-right: 1px solid #377dff !important;
}

.border-top-neat {
  border-top: 1px solid #377dff !important;
}

.border-bottom-neat {
  border-bottom: 1px solid #377dff !important;
}

.border-neat {
  border: 1px solid #377dff !important;
}

.border-left-2 {
  border-left: 2px solid #e7eaf3 !important;
}

.border-right-2 {
  border-right: 2px solid #e7eaf3 !important;
}

.border-top-2 {
  border-top: 2px solid #e7eaf3 !important;
}

.border-bottom-2 {
  border-bottom: 2px solid #e7eaf3 !important;
}

.border-2 {
  border: 2px solid #e7eaf3 !important;
}

/* BORDERS */

/* @import '~ngx-toastr/toastr.css'; */

.arrow-blue {
  color: #377dff !important;
  background-color: lightgray;
}

.arrow-blue:hover {
  color: #fff !important;
}

.typewriter span {
  font-family: monospace;
  /* Web-safe typewriter-like font */
  overflow: hidden;
  /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid orange;
  /* The typwriter cursor */
  white-space: nowrap;
  /* Keeps the content on a single line */
  margin: 0 auto;
  /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.15em;
  /* Adjust as needed */
  animation: typing 3.5s steps(30, end), blinking-cursor 0.5s step-end infinite;
}

/* The typing effect */

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */

@keyframes blinking-cursor {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: orange;
  }
}

.swal-text {
  /* color: rgb(145, 3, 3); */
  font-size: 14px;
}

.custombox-overlay,
.custombox-content {
  z-index: 9999999;
}

/* le quité un 9 */

.ui-datepicker-div {
  z-index: 99999999;
}

/* .custombox-overlay{
    z-index:99999999
} */

/* width: calc(100% - 100px); */

/* @media (max-width: 350px) {
    .swal-modal {
        width: 80%;
        left: 0 !important;
        margin-left: 0 !important;
    }
} */

.flex-center {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
}

/* BOTONES */

.close.white {
  color: #fff !important;
  opacity: 0.9 !important;
}

.close:hover.white {
  opacity: 0.7 !important;
}

/* .btn:not([href]):not([type]):not(:disabled):not(.disabled) {
    cursor: pointer !important;
} */

.btn {
  cursor: pointer !important;
}

.btn-neat-transparent {
  border: none !important;
  background-color: transparent !important;
  color: #fff !important;
}

.btn-neat-transparent i {
  color: rgba(51, 104, 240, 1) !important;
}

.btn-neat-transparent:hover i {
  color: rgb(45, 89, 199) !important;
}

.btn-neat-1 {
  background-color: rgba(51, 104, 240, 1) !important;
  color: #fff !important;
  -webkit-box-shadow: 0 5px 20px 0 rgba(51, 104, 240, 0.5);
  box-shadow: 0 5px 20px 0 rgba(51, 104, 240, 0.5);
}

.btn-neat-1.active,
.btn-neat-1:hover {
  -webkit-box-shadow: 0 5px 20px 0 rgba(51, 104, 240, 0.9);
  box-shadow: 0 5px 20px 0 rgba(51, 104, 240, 0.9);
  background-color: rgb(0, 50, 175) !important;
}

.btn-neat-2 {
  background-color: #fff !important;
  color: rgba(51, 104, 240, 1) !important;
  border: 2px solid rgba(51, 104, 240, 1) !important;
  -webkit-box-shadow: 0 5px 20px 0 rgba(51, 104, 240, 0.5);
  box-shadow: 0 5px 20px 0 rgba(51, 104, 240, 0.5);
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.btn-neat-2:hover {
  /* border: 2px solid rgb(156, 154, 154) !important; */
  background-color: rgba(51, 104, 240, 1) !important;
  color: #fff !important;
  font-weight: 400 !important;
  -webkit-box-shadow: 0 5px 20px 0 rgba(51, 104, 240, 0.9);
  box-shadow: 0 5px 20px 0 rgba(51, 104, 240, 0.9);
}

.btn-neat-3 {
  background-color: #fff !important;
  color: rgba(51, 104, 240, 1) !important;
  border: 2px solid rgba(51, 104, 240, 1) !important;
  -webkit-box-shadow: 0 5px 20px 0 rgba(51, 104, 240, 0.5);
  box-shadow: 0 5px 20px 0 rgba(51, 104, 240, 0.5);
}

.btn-neat-3:hover {
  -webkit-box-shadow: 0 5px 20px 0 rgba(51, 104, 240, 0.9);
  box-shadow: 0 5px 20px 0 rgba(51, 104, 240, 0.9);
}

.btn-neat-sky {
  background-color: #fff;
  color: rgba(51, 104, 240, 1) !important;
  border-color: rgba(51, 104, 240, 1) !important;
  -webkit-box-shadow: 0 5px 20px 0 rgba(51, 104, 240, 0.5);
  box-shadow: 0 5px 20px 0 rgba(51, 104, 240, 0.5);
}

.btn-neat-sky.active,
.btn-neat-sky:hover {
  background-color: rgba(51, 104, 240, 1) !important;
  border-color: rgba(51, 104, 240, 1) !important;
  color: #fff !important;
}

.radioCommissionButton.btn-neat-1.active > .selector-input > .selector {
  position: absolute;
  bottom: 0;
}

.radioCommissionButton.btn-neat-1.active > .selector-input > .selector::before,
.radioCommissionButton.btn-neat-1.active > .selector-input > .selector::after {
  content: 'ok';
  color: #fff !important;
}

/* BOTONES */

/* COLORES */

.rgba-neat-slight,
.rgba-neat-slight:after {
  background-color: rgba(51, 104, 240, 0.1);
}

.rgba-neat-light,
.rgba-neat-light:after {
  background-color: rgba(51, 104, 240, 0.3);
}

.rgba-neat-strong,
.rgba-neat-strong:after {
  background-color: rgba(51, 104, 240, 0.7);
}

.white-text {
  color: #fff !important;
}

.black-text {
  color: #000 !important;
}

.neat-fill-svg {
  fill: #3368f0 !important;
}

.neat-text {
  /* #3368F0 */
  color: #3368f0 !important;
}

.neat-text-dark {
  color: #404044 !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
i,
small {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.neat-text-hover {
  /* #3368F0 */
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #404044;
}

.neat-text-hover:hover {
  /* #3368F0 */
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #377dff;
}

.neat-error-text {
  color: #cc0000 !important;
}

.neat-text-light {
  font-weight: 300;
  color: rgba(51, 104, 240, 1) !important;
}

.neat-text {
  color: rgba(51, 104, 240, 1) !important;
}

.nav-link.neat-text {
  color: rgba(51, 104, 240, 1) !important;
  &:hover {
    color: lighten($neat-color-blue, 30%);
  }
}

.neat-text-normal {
  font-weight: 400;
  color: rgba(51, 104, 240, 1) !important;
}

.neat-text-medium-bold {
  font-weight: 500;
  color: rgba(51, 104, 240, 1) !important;
}

.neat-text-semi-bold {
  font-weight: 600;
  color: rgba(51, 104, 240, 1) !important;
}

.neat-text-bold {
  font-weight: bold;
  color: rgba(51, 104, 240, 1) !important;
}

a.neat-text:hover {
  color: rgba(51, 104, 240, 0.8) !important;
  font-weight: 500;
}

.neat-sky-text {
  color: rgba(146, 213, 255, 1) !important;
}

.neat-yellow-text {
  color: rgba(247, 217, 72, 1) !important;
}

.neat-green-text {
  color: rgba(160, 198, 0, 1) !important;
}

.neat-bg {
  background-color: rgba(51, 104, 240, 1) !important;
}

.neat-fill-svg-dark {
  fill: #2f2f2f !important;
}

.neat-dark-text {
  color: #2f2f2f !important;
}

.neat-dark-bg {
  background-color: #2f2f2f !important;
}

.neat-bg-transparent {
  background-color: rgba(51, 104, 240, 0) !important;
}

.neat-sky-bg {
  background-color: rgba(146, 213, 255, 1) !important;
}

.neat-yellow-bg {
  background-color: rgba(247, 217, 72, 1) !important;
}

.neat-green-bg {
  background-color: rgba(160, 198, 0, 1) !important;
}

.neat-green-bg-gradient {
  background: linear-gradient(to right, #a0c600, lighten(#a0c600, 10%));
}

.neat-shadow {
  border: none !important;
  -webkit-box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.7);
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.7);
}

.neat-shadow-soft {
  border: none !important;
  -webkit-box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.6);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.6);
}

.neat-shadow-hover {
  border: none !important;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.neat-shadow-hover:hover {
  -webkit-box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.9);
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.9);
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.bg-grey-light {
  background: #e9e8e8 !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.hr-neat {
  border-top: 1px solid rgba(51, 104, 240, 1) !important;
}

/* COLORES */

.li-sin-estilo {
  list-style: none;
}

/* icon */

.active-icon:before {
  content: '\f00c';
}

/* icon */

/* TRANSICIONES */

.transition-ease-03 {
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.transition-ease-05 {
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.transition-ease-07 {
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.transition-ease-in-05 {
  -webkit-transition: all 0.5s ease-in;
  -o-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;
}

.transition-ease-out-05 {
  -webkit-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}

/* TRANSICIONES */

/* FLEX CARD */

.card-flex {
  max-width: 1200px;
  margin: auto;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
}

.card-flex-content {
  flex: 25%;
  padding: 10px;
  width: 330px;
  max-width: 330px;
  box-sizing: border-box;
}

@media (max-width: 767px) {
  .card-flex-content {
    flex: 50%;
  }
}

/* FLEX CARD */

/* FIX MODAL PROBLEMS*/

/* body{

} */

body.custombox-lock {
  /* block scroll for mobile;
    causes underlying page to jump to top;
    prevents scrolling on all screens */
  overflow: hidden;
  /* position: fixed; */
}

html {
  /* overflow: scroll; */
  /* -webkit-overflow-scrolling: touch;
    overflow-x: hidden; */
}

/* FIX MODAL PROBLEMS*/

/* CSS talk bubble */

.talk-bubble {
  margin: 40px;
  display: inline-block;
  position: relative;
  width: 300px;
  height: auto;
  background-color: #fff;
  /* border: 8px solid #666 !important; */
}

.talk-bubble.border {
  border: 8px solid rgba(51, 104, 240, 1) !important;
}

.round {
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
}

/*Right triangle, placed bottom left side slightly in*/

.tri-right.border.btm-left-in:before {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: 30px;
  right: auto;
  top: auto;
  bottom: -40px;
  border: 20px solid;
  border-color: rgba(51, 104, 240, 1) transparent transparent
    rgba(51, 104, 240, 1);
}

.tri-right.btm-left-in:after {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: 38px;
  right: auto;
  top: auto;
  bottom: -20px;
  border: 12px solid;
  border-color: #fff transparent transparent #fff;
}

/*Right triangle, placed bottom right side slightly in*/

.tri-right.border.btm-right-in:before {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: 30px;
  bottom: -40px;
  border: 20px solid;
  border-color: rgba(51, 104, 240, 1) rgba(51, 104, 240, 1) transparent
    transparent;
}

.tri-right.btm-right-in:after {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: 38px;
  bottom: -20px;
  border: 12px solid;
  border-color: #fff #fff transparent transparent;
}

/* talk bubble contents */

.talktext {
  padding: 1em;
  text-align: left;
  line-height: 1.5em;
}

.talktext p {
  /* remove webkit p margins */
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
}

@media (max-width: 767px) {
  .talk-bubble {
    width: 200px;
  }
}

@media (min-width: 150px) and (max-width: 575px) {
  .d-xs-none {
    display: none !important;
  }
  .d-xs-inline {
    display: inline !important;
  }
  .d-xs-inline-block {
    display: inline-block !important;
  }
  .d-xs-block {
    display: block !important;
  }
  .d-xs-table {
    display: table !important;
  }
  .d-xs-table-row {
    display: table-row !important;
  }
  .d-xs-table-cell {
    display: table-cell !important;
  }
  .d-xs-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xs-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

/* TOOLTIPS */

/**
 * Tooltip Styles
 */

/* Add this attribute to the element that needs a tooltip */

/* [data-tooltip] {
    position: relative;
    z-index: 999;
    cursor: help !important;
} */

/* Hide the tooltip content by default */

/* [data-tooltip]:before,
[data-tooltip]:after {
    visibility: hidden;
    // -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
    // filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    pointer-events: none;
} */

/* Position tooltip above the element */

/* [data-tooltip]:before {
    position: absolute;
    bottom: 150%;
    left: 50%;
    margin-bottom: 5px;
    margin-left: -80px;
    padding: 4px;
    max-width: 220px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background-color: #fff !important;
    background-color: hsla(0, 0%, 20%, 0.9);
    color: rgba(51, 104, 240, 1) !important;
    border-top: 2px solid rgba(51, 104, 240, 1) !important;
    border-left: 2px solid rgba(51, 104, 240, 1) !important;
    border-right: 2px solid rgba(51, 104, 240, 1) !important;
    border-bottom: 2px solid rgba(51, 104, 240, 1) !important;
    content: attr(data-tooltip);
    text-align: center;
    font-size: 14px;
    line-height: 1.2;
    word-break: break-all;
    word-wrap: break-word;
} */

.btn-pointer-none {
  pointer-events: none !important;
}

/* Triangle hack to make tooltip look like a speech bubble */

/* [data-tooltip]:after {
    position: absolute;
    bottom: 150%;
    left: 50%;
    margin-left: -5px;
    width: 0;
    border-top: 5px solid rgba(51, 104, 240, 1) !important;
    border-top: 5px solid hsla(0, 0%, 20%, 0.9);
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    content: ' ';
    font-size: 0;
    line-height: 0;
}
 */

/* Show tooltip content on hover */

/* [data-tooltip]:hover:before,
[data-tooltip]:hover:after {
    visibility: visible;
    // -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
    // filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
} */

/* @media (max-width: 370px) {
    #btn-info-account-number[data-tooltip]:before {
        margin: -100px;
    }
}
 */

/* FIN TOOLTIPS */

@media (max-width: 360px) {
  .font-pagination {
    font-size: 10px;
  }
}

/* FONTS SIZES */

.font-size-xl {
  font-size: 1.5rem !important;
}

.font-size-lg {
  font-size: 1.25rem !important;
}

.font-size-md {
  font-size: 1rem !important;
}

.font-size-sm {
  font-size: 0.875rem !important;
}

.font-size-xs {
  font-size: 0.75rem !important;
}

/* FONTS SIZES */

/* WIDTH XS */

.w-10 {
  width: 10% !important;
}

.w-20 {
  width: 20% !important;
}

@media (min-width: 1200px) {
  .w-xl-10 {
    width: 10% !important;
  }
  .w-xl-20 {
    width: 20% !important;
  }
}

@media (min-width: 992px) {
  .w-lg-10 {
    width: 10% !important;
  }
  .w-lg-20 {
    width: 20% !important;
  }
}

@media (min-width: 768px) {
  .w-md-10 {
    width: 10% !important;
  }
  .w-md-20 {
    width: 20% !important;
  }
}

@media (max-width: 575px) {
  .w-xs-20 {
    width: 20% !important;
  }
  .w-xs-25 {
    width: 25% !important;
  }
  .w-xs-50 {
    width: 50% !important;
  }
  .w-xs-75 {
    width: 75% !important;
  }
  .w-xs-100 {
    width: 100% !important;
  }
  .w-xs-auto {
    width: auto !important;
  }
  .w-xs-15 {
    width: 15% !important;
  }
  .w-xs-30 {
    width: 30% !important;
  }
  .w-xs-35 {
    width: 35% !important;
  }
  .w-xs-40 {
    width: 40% !important;
  }
  .w-xs-60 {
    width: 60% !important;
  }
  .w-xs-65 {
    width: 65% !important;
  }
  .w-xs-80 {
    width: 80% !important;
  }
  .w-xs-85 {
    width: 85% !important;
  }
}

/* WIDTH XS */

/* CHECKBOX */

/* .custom-switch .custom-control-label::before {
    border: 1px solid rgba( 51, 104, 240, 1) !important;
} */

/* CHECKBOX */

/* UTILS */

.uppercase-only-first-letter::first-letter {
  text-transform: uppercase !important;
}

.hr-neat-1p {
  border-top: 1px solid rgba(51, 104, 240, 1) !important;
}

.hr-neat-2p {
  border-top: 2px solid rgba(51, 104, 240, 1) !important;
}

.hr-neat-3p {
  border-top: 3px solid rgba(51, 104, 240, 1) !important;
}

.hr-neat-4p {
  border-top: 4px solid rgba(51, 104, 240, 1) !important;
}

.hr-neat-5p {
  border-top: 5px solid rgba(51, 104, 240, 1) !important;
}

/* UTILS */

/* INPUTS */

.custom-append {
  position: relative;
}

/* .custom-append input[type=text] {
    border: none;
    width: 100%;
    padding-right: 123px;
} */

.custom-append .input-group-append {
  position: absolute;
  right: -10px;
  top: 4px;
  bottom: 4px;
  z-index: 9;
}

.autocomplete-container {
  box-shadow: none !important;
}

.input-container input {
  border-bottom: 1px solid #9e9e9e !important;
  padding: 0px 0px 0px 5px !important;
  height: 43px !important;
  /* background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #ddd; */
}

/* CAROUSEL */

.u-slick__arrow {
  background-color: #fff !important;
  color: rgba(51, 104, 240, 1) !important;
}

.u-slick__arrow:hover {
  color: #fff !important;
  background-color: rgba(51, 104, 240, 1) !important;
}

/* CAROUSEL */

/* INTERCOM */

.intercom-namespace .intercom-1yfj91 {
  background: rgba(51, 104, 240, 1) !important;
}

/* INTERCOM */

/* TEXT */

.letter-spacing-1_2 {
  letter-spacing: 1.2px;
}

/* TEXT */

/* IMG EFFECTS */

.img-bw-to-color {
  /* filter: url(filters.svg#grayscale); Firefox 3.5+ */
  filter: gray;
  /* IE5+ */
  -webkit-filter: grayscale(1);
  /* Webkit Nightlies & Chrome Canary */
  -moz-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.img-bw-to-color:hover {
  filter: none;
  -webkit-filter: grayscale(0);
  -webkit-transform: scale(1);
  transform: scale(1);
}

/*------------------------------------
  Got to Section - Wave
------------------------------------*/

.u-go-to-wave {
  width: 13.125rem;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 575.98px) {
  .u-go-to-wave {
    width: 10rem;
  }
}

.u-go-to-wave__icon {
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* FIN IMG EFFECTS */

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-15 {
  width: 15% !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-55 {
  width: 55% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-80 {
  width: 80% !important;
}

.w-85 {
  width: 85% !important;
}

@media (min-width: 576px) {
  .w-sm-25 {
    width: 25% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
  .w-sm-auto {
    width: auto !important;
  }
  .w-sm-15 {
    width: 15% !important;
  }
  .w-sm-30 {
    width: 30% !important;
  }
  .w-sm-35 {
    width: 35% !important;
  }
  .w-sm-40 {
    width: 40% !important;
  }
  .w-sm-60 {
    width: 60% !important;
  }
  .w-sm-65 {
    width: 65% !important;
  }
  .w-sm-80 {
    width: 80% !important;
  }
  .w-sm-85 {
    width: 85% !important;
  }
}

@media (min-width: 768px) {
  .w-md-25 {
    width: 25% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
  .w-md-auto {
    width: auto !important;
  }
  .w-md-15 {
    width: 15% !important;
  }
  .w-md-30 {
    width: 30% !important;
  }
  .w-md-35 {
    width: 35% !important;
  }
  .w-md-40 {
    width: 40% !important;
  }
  .w-md-55 {
    width: 55% !important;
  }
  .w-md-60 {
    width: 60% !important;
  }
  .w-md-65 {
    width: 65% !important;
  }
  .w-md-80 {
    width: 80% !important;
  }
  .w-md-85 {
    width: 85% !important;
  }
}

@media (min-width: 992px) {
  .w-lg-25 {
    width: 25% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }
  .w-lg-auto {
    width: auto !important;
  }
  .w-lg-15 {
    width: 15% !important;
  }
  .w-lg-30 {
    width: 30% !important;
  }
  .w-lg-35 {
    width: 35% !important;
  }
  .w-lg-40 {
    width: 40% !important;
  }
  .w-lg-60 {
    width: 60% !important;
  }
  .w-lg-65 {
    width: 65% !important;
  }
  .w-lg-80 {
    width: 80% !important;
  }
  .w-lg-85 {
    width: 85% !important;
  }
}

@media (min-width: 1200px) {
  .w-xl-25 {
    width: 25% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-100 {
    width: 100% !important;
  }
  .w-xl-auto {
    width: auto !important;
  }
  .w-xl-15 {
    width: 15% !important;
  }
  .w-xl-30 {
    width: 30% !important;
  }
  .w-xl-35 {
    width: 35% !important;
  }
  .w-xl-40 {
    width: 40% !important;
  }
  .w-xl-60 {
    width: 60% !important;
  }
  .w-xl-65 {
    width: 65% !important;
  }
  .w-xl-80 {
    width: 80% !important;
  }
  .w-xl-85 {
    width: 85% !important;
  }
}

.space-0,
.space-top-0 {
  padding-top: 0 !important;
}

.space-0,
.space-bottom-0 {
  padding-bottom: 0 !important;
}

.space-1,
.space-top-1 {
  padding-top: 2rem !important;
}

.space-1,
.space-bottom-1 {
  padding-bottom: 2rem !important;
}

.space-2,
.space-top-2 {
  padding-top: 4rem !important;
}

.space-2,
.space-bottom-2 {
  padding-bottom: 4rem !important;
}

.space-3,
.space-top-3 {
  padding-top: 8rem !important;
}

.space-3,
.space-bottom-3 {
  padding-bottom: 8rem !important;
}

.space-4,
.space-top-4 {
  padding-top: 12.5rem !important;
}

.space-4,
.space-bottom-4 {
  padding-bottom: 12.5rem !important;
}

.space-5,
.space-top-5 {
  padding-top: 15rem !important;
}

.space-5,
.space-bottom-5 {
  padding-bottom: 15rem !important;
}

@media (min-width: 576px) {
  .space-sm-0,
  .space-top-sm-0 {
    padding-top: 0 !important;
  }
  .space-sm-0,
  .space-bottom-sm-0 {
    padding-bottom: 0 !important;
  }
  .space-sm-1,
  .space-top-sm-1 {
    padding-top: 2rem !important;
  }
  .space-sm-1,
  .space-bottom-sm-1 {
    padding-bottom: 2rem !important;
  }
  .space-sm-2,
  .space-top-sm-2 {
    padding-top: 4rem !important;
  }
  .space-sm-2,
  .space-bottom-sm-2 {
    padding-bottom: 4rem !important;
  }
  .space-sm-3,
  .space-top-sm-3 {
    padding-top: 8rem !important;
  }
  .space-sm-3,
  .space-bottom-sm-3 {
    padding-bottom: 8rem !important;
  }
  .space-sm-4,
  .space-top-sm-4 {
    padding-top: 12.5rem !important;
  }
  .space-sm-4,
  .space-bottom-sm-4 {
    padding-bottom: 12.5rem !important;
  }
  .space-sm-5,
  .space-top-sm-5 {
    padding-top: 15rem !important;
  }
  .space-sm-5,
  .space-bottom-sm-5 {
    padding-bottom: 15rem !important;
  }
}

@media (min-width: 768px) {
  .space-md-0,
  .space-top-md-0 {
    padding-top: 0 !important;
  }
  .space-md-0,
  .space-bottom-md-0 {
    padding-bottom: 0 !important;
  }
  .space-md-1,
  .space-top-md-1 {
    padding-top: 2rem !important;
  }
  .space-md-1,
  .space-bottom-md-1 {
    padding-bottom: 2rem !important;
  }
  .space-md-2,
  .space-top-md-2 {
    padding-top: 4rem !important;
  }
  .space-md-2,
  .space-bottom-md-2 {
    padding-bottom: 4rem !important;
  }
  .space-md-3,
  .space-top-md-3 {
    padding-top: 8rem !important;
  }
  .space-md-3,
  .space-bottom-md-3 {
    padding-bottom: 8rem !important;
  }
  .space-md-4,
  .space-top-md-4 {
    padding-top: 12.5rem !important;
  }
  .space-md-4,
  .space-bottom-md-4 {
    padding-bottom: 12.5rem !important;
  }
  .space-md-5,
  .space-top-md-5 {
    padding-top: 15rem !important;
  }
  .space-md-5,
  .space-bottom-md-5 {
    padding-bottom: 15rem !important;
  }
}

@media (min-width: 992px) {
  .space-lg-0,
  .space-top-lg-0 {
    padding-top: 0 !important;
  }
  .space-lg-0,
  .space-bottom-lg-0 {
    padding-bottom: 0 !important;
  }
  .space-lg-1,
  .space-top-lg-1 {
    padding-top: 2rem !important;
  }
  .space-lg-1,
  .space-bottom-lg-1 {
    padding-bottom: 2rem !important;
  }
  .space-lg-2,
  .space-top-lg-2 {
    padding-top: 4rem !important;
  }
  .space-lg-2,
  .space-bottom-lg-2 {
    padding-bottom: 4rem !important;
  }
  .space-lg-3,
  .space-top-lg-3 {
    padding-top: 8rem !important;
  }
  .space-lg-3,
  .space-bottom-lg-3 {
    padding-bottom: 8rem !important;
  }
  .space-lg-4,
  .space-top-lg-4 {
    padding-top: 12.5rem !important;
  }
  .space-lg-4,
  .space-bottom-lg-4 {
    padding-bottom: 12.5rem !important;
  }
  .space-lg-5,
  .space-top-lg-5 {
    padding-top: 15rem !important;
  }
  .space-lg-5,
  .space-bottom-lg-5 {
    padding-bottom: 15rem !important;
  }
}

@media (min-width: 1200px) {
  .space-xl-0,
  .space-top-xl-0 {
    padding-top: 0 !important;
  }
  .space-xl-0,
  .space-bottom-xl-0 {
    padding-bottom: 0 !important;
  }
  .space-xl-1,
  .space-top-xl-1 {
    padding-top: 2rem !important;
  }
  .space-xl-1,
  .space-bottom-xl-1 {
    padding-bottom: 2rem !important;
  }
  .space-xl-2,
  .space-top-xl-2 {
    padding-top: 4rem !important;
  }
  .space-xl-2,
  .space-bottom-xl-2 {
    padding-bottom: 4rem !important;
  }
  .space-xl-3,
  .space-top-xl-3 {
    padding-top: 8rem !important;
  }
  .space-xl-3,
  .space-bottom-xl-3 {
    padding-bottom: 8rem !important;
  }
  .space-xl-4,
  .space-top-xl-4 {
    padding-top: 12.5rem !important;
  }
  .space-xl-4,
  .space-bottom-xl-4 {
    padding-bottom: 12.5rem !important;
  }
  .space-xl-5,
  .space-top-xl-5 {
    padding-top: 15rem !important;
  }
  .space-xl-5,
  .space-bottom-xl-5 {
    padding-bottom: 15rem !important;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-weight: 300;
  line-height: 1.5;
}

h1,
.h1 {
  font-size: 2.5rem;
}

h2,
.h2 {
  font-size: 2rem;
}

h3,
.h3 {
  font-size: 1.75rem;
}

h4,
.h4 {
  font-size: 1.5rem;
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #e7eaf3;
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

/*------------------------------------
  Typography Font Size
------------------------------------*/

.font-size-1 {
  font-size: 0.875rem;
}

.font-size-1-0 {
  font-size: 1rem;
}

.font-size-1-5 {
  font-size: 1.25rem;
}

.font-size-2 {
  font-size: 1.5rem;
}

.font-size-3 {
  font-size: 2rem;
}

.font-size-4 {
  font-size: 2.5rem;
}

.font-size-5 {
  font-size: 3rem;
}

@media (max-width: 991.98px) {
  .font-size-md-down-5 {
    font-size: 3rem;
  }
}

/*------------------------------------
  Font Weight
------------------------------------*/

.font-weight-medium {
  font-weight: 500 !important;
}

.font-weight-semi-bold {
  font-weight: 600 !important;
}

/*------------------------------------
  Divider
------------------------------------*/

.u-divider {
  position: relative;
  display: inline-block;
}

.u-divider::before,
.u-divider::after {
  position: absolute;
  top: 50%;
  content: '';
}

.u-divider::before {
  right: 100%;
  background-image: linear-gradient(45deg, transparent 0%, #bdc5d1 100%);
  background-repeat: repeat-x;
}

.u-divider::after {
  left: 100%;
  background-image: linear-gradient(45deg, #bdc5d1 0%, transparent 100%);
  background-repeat: repeat-x;
}

/*------------------------------------
  Divider Sizes
------------------------------------*/

/* Extra Small */

.u-divider--xs {
  font-size: 0.75rem;
}

.u-divider--xs::before,
.u-divider--xs::after {
  width: 2rem;
  height: 0.0625rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.u-divider--xs::before {
  margin-right: 0.625rem;
}

.u-divider--xs::after {
  margin-left: 0.625rem;
}

.u-divider--xs::before,
.u-divider--xs::after {
  width: 2rem;
}

/*------------------------------------
  Divider Styles
------------------------------------*/

/* Text */

.u-divider--text {
  color: #8c98a4;
}

/*------------------------------------
  Vertical Divider
------------------------------------*/

.u-ver-divider {
  position: relative;
}

.u-ver-divider::after {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  border-right: 1px solid #e7eaf3;
  content: '';
}

/* Breakpoint */

@media (max-width: 575.98px) {
  .u-ver-divider--none-sm::after {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .u-ver-divider--none-md::after {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .u-ver-divider--none-lg::after {
    display: none;
  }
}

/*------------------------------------
  Divider Sizes
------------------------------------*/

/* Extra Small */

.u-ver-divider--xs::after {
  top: 50%;
  height: 1rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* Small */

.u-ver-divider--sm::after {
  top: 50%;
  height: 2rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* Large */

.u-ver-divider--lg::after {
  top: 50%;
  height: 3rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* Extra Large */

.u-ver-divider--xl::after {
  top: 50%;
  height: 4rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
}

.media-body {
  -ms-flex: 1;
  flex: 1;
}

/*------------------------------------
  SVG IE10+ specific styles go here
------------------------------------*/

@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  .u-header__promo-icon {
    height: 48px;
  }
  .ie-height-24 {
    height: 24px;
  }
  .ie-height-40 {
    height: 40px;
  }
  .ie-height-48 {
    height: 48px;
  }
  .ie-height-56 {
    height: 56px;
  }
  .ie-height-72 {
    height: 72px;
  }
  .ie-main-hero {
    height: 686px;
  }
  .ie-soft-triangle-shape {
    height: 610px;
  }
  .ie-browser {
    height: 565px;
  }
  .ie-wave-1-bottom {
    height: 100px;
  }
  .ie-vault,
  .ie-responsive,
  .ie-easy-payment {
    height: 210px;
  }
  .ie-marketing-strategy,
  .ie-app-development,
  .ie-business-analysis {
    height: 328px;
  }
  .ie-devices-v1-tablet {
    height: 38.5rem;
  }
  .ie-devices-v1-phone {
    height: 27.5rem;
  }
  .ie-devices-v2-tablet {
    height: 37.875rem;
  }
  .ie-devices-v2-iphone {
    height: 31rem;
  }
  .ie-devices-v3-iphone {
    height: 633px;
  }
  .ie-devices-v4-tablet-horizontal {
    height: 16.875rem;
  }
  .ie-devices-v4-tablet {
    height: 25.4375rem;
  }
  .ie-devices-v4-laptop {
    height: 20.75rem;
  }
  .ie-devices-v4-iphone {
    height: 13.0625rem;
  }
  .ie-wave-6-top-left {
    height: 379px;
  }
  .ie-double-ellipse-top-right {
    height: 431px;
  }
  .ie-double-ellipse-top-left {
    height: 788px;
  }
  .ie-double-ellipse-bottom-right {
    height: 252px;
  }
  .ie-ellipse-mockup {
    height: 656px;
  }
  .ie-irregular-shape-2-right {
    height: 660px;
  }
  .ie-irregular-shape-3-bottom {
    height: 255px;
  }
  .ie-circle-chart {
    height: 219px;
  }
  .ie-curved-shape {
    height: 55.8px;
  }
  .ie-subscribe-illustration {
    height: 329px;
  }
  .ie-subscribe-1 {
    height: 315px;
  }
  .ie-subscribe-2 {
    height: 295px;
  }
  .ie-subscribe-2-flat-icons {
    height: 200px;
  }
  .ie-color-gradient {
    height: 566px;
  }
  .ie-for-sale,
  .ie-buyer {
    height: 208px;
  }
  .ie-events,
  .ie-data-report,
  .ie-image-upload {
    height: 219px;
  }
  .ie-analysis,
  .ie-in-the-office,
  .ie-make-it-rain {
    height: 200px;
  }
  .ie-house-agency {
    height: 381px;
  }
  .ie-laptop-and-iphone {
    height: 421px;
  }
  .ie-get-answered {
    height: 386px;
  }
  .ie-bg-elements-1 {
    height: 420px;
  }
  .ie-bg-elements-2 {
    height: 374px;
  }
  .ie-bg-elements-3 {
    height: 583px;
  }
  .ie-bg-elements-4 {
    height: 850px;
  }
  .ie-circle-1 {
    height: 379px;
  }
  .ie-go-to-wave {
    height: 46px;
  }
  .ie-graphic-illustration-1 {
    height: 270px;
  }
  .ie-app-development {
    height: 328px;
  }
  .ie-we-have-an-idea {
    height: 335px;
  }
  .ie-chatting-girl,
  .ie-chatting-boy {
    height: 328px;
  }
  .ie-virtual-reality {
    height: 320px;
  }
  .ie-maintenance-mode {
    height: 200px;
  }
  .ie-non-standard-hero-shape {
    height: 556px;
  }
  .ie-enterprise-2 {
    height: 267px;
  }
  .ie-abstract-shapes-1 {
    height: 554px;
  }
  .ie-abstract-shapes-2,
  .ie-abstract-shapes-3,
  .ie-abstract-shapes-4 {
    height: 532px;
  }
  .ie-abstract-shapes-6 {
    height: 187px;
  }
  .ie-abstract-shapes-7 {
    height: 624px;
  }
  .ie-abstract-shapes-9 {
    height: 182px;
  }
  .ie-abstract-shapes-10 {
    height: 573px;
  }
  .ie-abstract-shapes-11 {
    height: 192px;
  }
  .ie-showcase-mockup-1 {
    height: 384px;
  }
  .ie-showcase-mockup-2 {
    height: 371px;
  }
  .ie-showcase-mockup-3 {
    height: 535px;
  }
  .ie-knowledgebase-community {
    height: 447px;
  }
  .ie-knowledgebase-community-2 {
    height: 542px;
  }
  .ie-files {
    height: 293px;
  }
}

/*------------------------------------
  Box Shadow
------------------------------------*/

.shadow-primary-lg {
  box-shadow: 0 0 50px rgba(55, 125, 255, 0.4) !important;
}

.shadow-soft {
  box-shadow: 0 3px 6px 0 rgba(140, 152, 164, 0.25) !important;
}

/*------------------------------------
  Width
------------------------------------*/

.min-width-3 {
  min-width: 1rem !important;
}

.min-width-4 {
  min-width: 1.5rem !important;
}

.max-width-3 {
  max-width: 1rem !important;
}

.max-width-4 {
  max-width: 1.5rem !important;
}

.max-width-5 {
  max-width: 2rem !important;
}

.max-width-6 {
  max-width: 2.5rem !important;
}

.max-width-8 {
  max-width: 3.5rem;
}

.max-width-9 {
  max-width: 4rem;
}

.max-width-10 {
  max-width: 4.5rem;
}

.max-width-15 {
  max-width: 7rem;
}

.max-width-27 {
  max-width: 13rem;
}

.max-width-35 {
  max-width: 17rem;
}

.max-width-40 {
  max-width: 19.5rem;
}

/*------------------------------------
  Height
------------------------------------*/

.height-40vh {
  height: 40vh;
}

.height-60vh {
  height: 60vh;
}

.height-100vh {
  height: 100vh;
}

.height-4 {
  height: 0.25rem;
}

.height-380 {
  height: 23.75rem;
}

.height-400 {
  height: 25rem;
}

.height-450 {
  height: 28.125rem;
}

.min-height-100vh {
  min-height: 100vh;
}

.min-height-155 {
  min-height: 9.6875rem;
}

.min-height-300 {
  min-height: 18.75rem;
}

.min-height-380 {
  min-height: 23.75rem;
}

.min-height-450 {
  min-height: 28.125rem;
}

.min-height-550 {
  min-height: 34.375rem;
}

.min-height-600 {
  min-height: 37.5rem;
}

.min-height-620 {
  min-height: 38.75rem;
}

@media (min-width: 576px) {
  .height-sm-100vh {
    height: 100vh;
  }
}

@media (min-width: 768px) {
  .height-md-60vh {
    height: 60vh;
  }
  .height-md-100vh {
    height: 100vh;
  }
  .min-height-md-100vh {
    min-height: 100vh;
  }
}

@media (min-width: 992px) {
  .height-lg-100vh {
    height: 100vh;
  }
  /* CUSTOM */
  .height-lg-115vh {
    height: 115vh;
  }
  .min-height-lg-auto {
    min-height: auto;
  }
  .min-height-lg-100vh {
    min-height: 100vh;
  }
}

@media (min-width: 1200px) {
  .min-height-xl-100vh {
    min-height: 100vh;
  }
}

/*------------------------------------
  Typography Font Size
------------------------------------*/

.font-size-1 {
  font-size: 0.875rem;
}

.font-size-1-0 {
  font-size: 1rem;
}

.font-size-1-5 {
  font-size: 1.25rem;
}

.font-size-2 {
  font-size: 1.5rem;
}

.font-size-3 {
  font-size: 2rem;
}

.font-size-4 {
  font-size: 2.5rem;
}

.font-size-5 {
  font-size: 3rem;
}

@media (max-width: 991.98px) {
  .font-size-md-down-5 {
    font-size: 3rem;
  }
}

/* Video */

.youtube-wrapper {
  // max-width: 680px;
  margin: 60px auto;
  padding: 0 20px;
  // background-color: rgba( 0, 0, 0, 0.9);
  position: relative;
  width: 100%;
  border-radius: 8px;
  transition: all 0.3s ease;
}

// .youtube-wrapper:hover {
//     background-color: rgba( 0, 0, 0, 0.6);
//     transition: all .3s ease;
// }
.youtube {
  // background-color: #000;
  // background-color: rgba( 0, 0, 0, 0.9);
  margin-bottom: 30px;
  position: relative;
  padding-top: 56.25%;
  overflow: hidden;
  cursor: pointer;
}

.youtube:hover {
  // background-color: rgba( 0, 0, 0, 0.6);
  transition: all 0.3s ease;
}

.youtube img {
  width: 100%;
  top: -16.82%;
  left: 0;
  opacity: 0.7;
}

.youtube .play-button {
  width: 90px;
  height: 60px;
  background-color: #333;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.6);
  z-index: 1;
  opacity: 0.8;
  border-radius: 6px;
}

.youtube .play-button:before {
  content: '';
  border-style: solid;
  border-width: 15px 0 15px 26px;
  border-color: transparent transparent transparent #fff;
}

.youtube img,
.youtube .play-button {
  cursor: pointer;
}

.youtube img,
.youtube iframe,
.youtube .play-button,
.youtube .play-button:before {
  position: absolute;
}

.youtube .play-button,
.youtube .play-button:before {
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.youtube iframe {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

/* Fin Video */

/* Titles */

.landing-title {
  font-size: 2rem;
  letter-spacing: 1.2px;
  font-weight: 500;
  color: #2f2f2f;
  @media (max-width: 385px) {
    font-size: 1.5rem;
  }
}

/* End Titles */

/* Alerts */

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 0 solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3.75rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #1d4185;
  background-color: #d7e5ff;
  border-color: #c7dbff;
}

.alert-primary hr {
  border-top-color: #aecbff;
}

.alert-primary .alert-link {
  color: #142d5b;
}

.alert-secondary {
  color: #3e444a;
  background-color: #e4e6e9;
  border-color: #d9dce0;
}

.alert-secondary hr {
  border-top-color: #cbcfd5;
}

.alert-secondary .alert-link {
  color: #272b2e;
}

.alert-success {
  color: #006957;
  background-color: #ccf4ed;
  border-color: #b8f0e6;
}

.alert-success hr {
  border-top-color: #a3ecdf;
}

.alert-success .alert-link {
  color: #00362d;
}

.alert-info {
  color: #007483;
  background-color: #ccf9fe;
  border-color: #b8f6fe;
}

.alert-info hr {
  border-top-color: #9ff3fe;
}

.alert-info .alert-link {
  color: #004750;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #73231d;
  background-color: #f8dad7;
  border-color: #f6cbc7;
}

.alert-danger hr {
  border-top-color: #f2b7b1;
}

.alert-danger .alert-link {
  color: #4a1713;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #101112;
  background-color: #d2d2d3;
  border-color: #c0c1c1;
}

.alert-dark hr {
  border-top-color: #b3b4b4;
}

.alert-dark .alert-link {
  color: black;
}

.alert-white {
  color: #858585;
  background-color: white;
  border-color: white;
}

.alert-white hr {
  border-top-color: #f2f2f2;
}

.alert-white .alert-link {
  color: #6c6c6c;
}

.alert-indigo {
  color: #170b44;
  background-color: #d5d0e6;
  border-color: #c4bddc;
}

.alert-indigo hr {
  border-top-color: #b5acd3;
}

.alert-indigo .alert-link {
  color: #080418;
}

.alert-text {
  color: inherit;
}

.alert-success-neat {
  color: #fff;
  // background-color: #006957;
  // border-color: #006957;
  background-color: #42ba3f;
  border-color: #42ba3f;
}

.alert-error-neat {
  color: #fff;
  background-color: #ffb22b;
  border-color: #ffb22b;
}

.alert-blue-neat {
  color: #fff;
  background-color: #3368f0;
  border-color: #3368f0;
}

/* End Alerts */

.white-space-pre {
  white-space: pre !important;
}

/* popover */

.fade:not(.show) {
  z-index: -1 !important;
}

.popover {
  &.popover-xl {
    max-width: 100vw;
    @media (min-width: 768px) {
      width: 60%;
    }
    @media (max-width: 767px) {
      width: 100%;
    }
  }
  &.fade:not(.show) {
    z-index: -1 !important;
  }
  @media (max-width: 768px) {
    width: 90% !important;
  }
  border: 3px solid $neat-color-blue !important;
  background-color: #fff !important;
  .popover-header {
    background-color: $neat-color-blue !important;
    color: #fff;
  }
}

/* end popover */

/* border radius */

.border-radius-3 {
  border-radius: 3px !important;
}

.border-radius-2 {
  border-radius: 2px !important;
}

.border-radius-1 {
  border-radius: 1px !important;
}

/* end border radius */

/* Img to Gray*/

.to-gray {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}

.starter-category {
  border-width: 3px;
  border-style: solid;
  -moz-border-image: -moz-linear-gradient(
    270deg,
    rgba(48, 151, 211, 1) 0%,
    rgba(51, 105, 240, 1) 100%
  );
  -webkit-border-image: -webkit-linear-gradient(
    270deg,
    rgba(48, 151, 211, 1) 0%,
    rgba(51, 105, 240, 1) 100%
  );
  border-image: linear-gradient(
    270deg,
    rgba(48, 151, 211, 1) 0%,
    rgba(51, 105, 240, 1) 100%
  );
  -webkit-border-image-slice: 2;
  border-image-slice: 2;
}
.gold-category {
  border-width: 3px;
  border-style: solid;
  -moz-border-image: -moz-linear-gradient(
    270deg,
    rgba(250, 249, 157, 1) 0%,
    rgba(205, 150, 60, 1) 100%
  );
  -webkit-border-image: -webkit-linear-gradient(
    270deg,
    rgba(250, 249, 157, 1) 0%,
    rgba(205, 150, 60, 1) 100%
  );
  border-image: linear-gradient(
    270deg,
    rgba(250, 249, 157, 1) 0%,
    rgba(205, 150, 60, 1) 100%
  );
  -webkit-border-image-slice: 2;
  border-image-slice: 2;
  background-image: linear-gradient(
    to right,
    rgba(205, 150, 60, 1),
    rgba(250, 249, 157, 1)
  );
  .col-4 {
    span {
      color: rgba(59, 64, 134, 1);
      font-weight: 700;
    }
  }
  .normal-text {
    font-weight: 700;
  }
}
.platinum-category {
  border-width: 3px;
  border-style: solid;
  -moz-border-image: -moz-linear-gradient(
    270deg,
    rgba(193, 214, 222, 1) 0%,
    rgba(157, 157, 157, 1) 100%
  );
  -webkit-border-image: -webkit-linear-gradient(
    270deg,
    rgba(193, 214, 222, 1) 0%,
    rgba(157, 157, 157, 1) 100%
  );
  border-image: linear-gradient(
    270deg,
    rgba(193, 214, 222, 1) 0%,
    rgba(157, 157, 157, 1) 100%
  );
  -webkit-border-image-slice: 2;
  border-image-slice: 2;
  background-image: linear-gradient(
    to right,
    rgba(157, 157, 157, 1),
    rgba(193, 214, 222, 1)
  );
  .col-4 {
    span {
      color: rgba(59, 64, 134, 1);
      font-weight: 700;
    }
  }
  .normal-text {
    font-weight: 700;
  }
}
.black-category {
  border-width: 3px;
  border-style: solid;
  -moz-border-image: -moz-linear-gradient(
    270deg,
    rgba(69, 68, 68, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  -webkit-border-image: -webkit-linear-gradient(
    270deg,
    rgba(69, 68, 68, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  border-image: linear-gradient(
    270deg,
    rgba(69, 68, 68, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  -webkit-border-image-slice: 2;
  border-image-slice: 2;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 1),
    rgba(69, 68, 68, 1)
  );
  .col-4 {
    span {
      color: white;
      font-weight: 700;
    }
  }
  .normal-text {
    font-weight: 700;
  }
}
.diamond-category {
  border-width: 3px;
  border-style: solid;
  -moz-border-image: -moz-linear-gradient(270deg, #eb5e4f 0%, #ba4058 100%);
  -webkit-border-image: -webkit-linear-gradient(
    270deg,
    #eb5e4f 0%,
    #ba4058 100%
  );
  border-image: linear-gradient(270deg, #eb5e4f 0%, #ba4058 100%);
  -webkit-border-image-slice: 2;
  border-image-slice: 2;
  background-image: linear-gradient(to right, #ba4058, #eb5e4f);
  .col-4 {
    span {
      color: white;
      font-weight: 700;
    }
  }
  .normal-text {
    font-weight: 700;
  }
}
.infinite-category {
  border-width: 3px;
  border-style: solid;
  -moz-border-image: -moz-linear-gradient(
    270deg,
    rgba(49, 109, 201, 1) 0%,
    rgba(29, 74, 137, 1) 100%
  );
  -webkit-border-image: -webkit-linear-gradient(
    270deg,
    rgba(49, 109, 201, 1) 0%,
    rgba(29, 74, 137, 1) 100%
  );
  border-image: linear-gradient(
    270deg,
    rgba(49, 109, 201, 1) 0%,
    rgba(29, 74, 137, 1) 100%
  );
  -webkit-border-image-slice: 2;
  border-image-slice: 2;
  background-image: linear-gradient(
    to right,
    rgba(29, 74, 137, 1),
    rgba(49, 109, 201, 1)
  );
  .col-4 {
    span {
      color: white;
      font-weight: 700;
    }
  }
  .normal-text {
    font-weight: 700;
  }
}
.prime-category {
  border-width: 3px;
  border-style: solid;
  -moz-border-image: -moz-linear-gradient(
    270deg,
    rgba(107, 25, 142, 1) 0%,
    rgba(33, 2, 49, 1) 100%
  );
  -webkit-border-image: -webkit-linear-gradient(
    270deg,
    rgba(107, 25, 142, 1) 0%,
    rgba(33, 2, 49, 1) 100%
  );
  border-image: linear-gradient(
    270deg,
    rgba(107, 25, 142, 1) 0%,
    rgba(33, 2, 49, 1) 100%
  );
  -webkit-border-image-slice: 2;
  border-image-slice: 2;
  background-image: linear-gradient(
    to right,
    rgba(33, 2, 49, 1),
    rgba(107, 25, 142, 1)
  );
  .col-4 {
    span {
      color: white;
      font-weight: 700;
    }
  }
  .normal-text {
    font-weight: 700;
  }
}
