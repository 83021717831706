@use '@angular/material' as mat;
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
$neat-color-blue: rgba(51,
104,
240,
1);

/* #3368f0 */


/* $link-color: #3368f0; */


/* $link-hover-color: currentColor; */


/* $link-hover-color: rgb(42, 91, 212); */

$link-hover-decoration: none;
$label-margin-bottom: 0;
// $grid-breakpoints: ( xs: 0, // handset portrait (small, medium, large) | handset landscape (small)
// sm: 600px, // handset landscape (medium, large) | tablet portrait(small, large)
// md: 960px, //  tablet landscape (small, large)
// lg: 1280px, // laptops and desktops
// xl: 1600px // large desktops
// );
// $container-max-widths: ( sm: 600px, md: 960px, lg: 1280px, xl: 1600px);
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';
// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$mat-neat-primary: ( 50: #e8eaf6,
100: #c5cae9,
200: #7a9df5,
300: #638bf3,
400: #4b7af2,
500: #3368f0,
600: #1b56ee,
700: #104be0,
800: #0f43c8,
900: #1a237e,
A100: #8c9eff,
A200: #536dfe,
A400: #3d5afe,
A700: #304ffe,
contrast: ( 50: $dark-primary-text, 100: $dark-primary-text, 200: $dark-primary-text, 300: $light-primary-text, 400: $light-primary-text, 500: $light-primary-text, 600: $light-primary-text, 700: $light-primary-text, 800: $light-primary-text, 900: $light-primary-text, A100: $dark-primary-text, A200: $light-primary-text, A400: $light-primary-text, A700: $light-primary-text));
$neat-app-primary: mat.define-palette($mat-neat-primary);
$neat-app-accent: mat.define-palette(mat.$yellow-palette,
A200,
A100,
A400);
// The warn palette is optional (defaults to red).
$neat-app-warn: mat.define-palette(mat.$red-palette);
// Create the theme object (a Sass map containing all of the palettes).
$neat-app-theme: mat.define-light-theme( $neat-app-primary,
$neat-app-accent,
$neat-app-warn);
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($neat-app-theme);

/* DASHBOARD VARIABLES */

// Variables
$mainfont: 'Poppins',
sans-serif;
$bodyfont: 'Poppins',
sans-serif;
$headingfont: 'Poppins',
sans-serif;
$font-roboto: Roboto,
'Helvetica Neue',
sans-serif;

/*Theme Colors*/

$topbar: rgba(51,
104,
240,
1);
$sidebar: rgba(51,
104,
240,
1);
$sidebar-white: #e5edef;
$sidebar-alt: #edf0f5;
$bodycolor: #f4f6f9;
$headingtext: #455a64;
$bodytext: #67757c;
$sidebar-text: #fff;
$sidebar-icons: #fff;
$light-text: #a6b7bf;
$themecolor: darken(#fff,
20%);
$themecolor-alt: lighten(#fff,
10%);
$themecolor-dark: darken(#fff,
10%);

/*bootstrap Color*/

$danger: #ef5350;
$success: #06d79c;
$warning: #ffb22b;
$primary: rgba(51,
104,
240,
1);
$info: #398bf7;
$inverse: #2f3d4a;
$secondary: #cccccc;
$muted: #99abb4;
$dark: #263238;
$light: #e9edf2;
$extra-light: #ebf3f5;
$bglight: rgba(0,
0,
0,
0.02);

/*Light colors*/

$light-danger: #f9e7eb;
$light-success: #e8fdeb;
$light-warning: #fff8ec;
$light-primary: #f1effd;
$light-info: #cfecfe;
$light-inverse: #f6f6f6;
$light-megna: #e0f2f4;
$danger-dark: #e6294b;
$success-dark: #04b381;
$warning-dark: #e9ab2e;
$primary-dark: #6352ce;
$info-dark: #028ee1;
$red-dark: #d61f1f;
$inverse-dark: #232a37;
$dark-transparent: rgba(0,
0,
0,
0.05);
$info-shadow: 0 2px 2px 0 rgba(66,
165,
245,
0.14),
0 3px 1px -2px rgba(66,
165,
245,
0.2),
0 1px 5px 0 rgba(66,
165,
245,
0.12);
$info-shadow-hover: 0 14px 26px -12px rgba(23,
105,
255,
0.42),
0 4px 23px 0 rgba(0,
0,
0,
0.12),
0 8px 10px -5px rgba(23,
105,
255,
0.2);
$warning-shadow: 0 2px 2px 0 rgba(248,
194,
0,
0.14),
0 3px 1px -2px rgba(248,
194,
0,
0.2),
0 1px 5px 0 rgba(248,
194,
0,
0.12);
$warning-shadow-hover: 0 14px 26px -12px rgba(248,
194,
0,
0.42),
0 4px 23px 0 rgba(0,
0,
0,
0.12),
0 8px 10px -5px rgba(248,
194,
0,
0.2);
$danger-shadow: 0 2px 2px 0 rgba(239,
83,
80,
0.14),
0 3px 1px -2px rgba(239,
83,
80,
0.2),
0 1px 5px 0 rgba(239,
83,
80,
0.12);
$danger-shadow-hover: 0 14px 26px -12px rgba(239,
83,
80,
0.42),
0 4px 23px 0 rgba(0,
0,
0,
0.12),
0 8px 10px -5px rgba(239,
83,
80,
0.2);
$success-shadow: 0 2px 2px 0 rgba(40,
190,
189,
0.14),
0 3px 1px -2px rgba(40,
190,
189,
0.2),
0 1px 5px 0 rgba(40,
190,
189,
0.12);
$success-shadow-hover: 0 14px 26px -12px rgba(40,
190,
189,
0.42),
0 4px 23px 0 rgba(0,
0,
0,
0.12),
0 8px 10px -5px rgba(40,
190,
189,
0.2);
$primary-shadow: 0 2px 2px 0 rgba(116,
96,
238,
0.14),
0 3px 1px -2px rgba(116,
96,
238,
0.2),
0 1px 5px 0 rgba(116,
96,
238,
0.12);
$primary-shadow-hover: 0 14px 26px -12px rgba(116,
96,
238,
0.42),
0 4px 23px 0 rgba(0,
0,
0,
0.12),
0 8px 10px -5px rgba(116,
96,
238,
0.2);
$default-shadow: 0 2px 2px 0 rgba(169,
169,
169,
0.14),
0 3px 1px -2px rgba(169,
169,
169,
0.2),
0 1px 5px 0 rgba(169,
169,
169,
0.12);
$default-shadow-hover: 0 14px 26px -12px rgba(169,
169,
169,
0.42),
0 4px 23px 0 rgba(0,
0,
0,
0.12),
0 8px 10px -5px rgba(169,
169,
169,
0.2);

/*Normal Color*/

$white: #ffffff;
$red: #fb3a3a;
$yellow: #a0aec4;
$purple: #7460ee;
$blue: #02bec9;
$megna: #56c0d8;

/*Extra Variable*/

$rgt: right;
$lft: left;
$border: rgba(120,
130,
140,
0.13);
$table-border: #f3f1f1;
$card-brd: #d7dfe3;
$dark-text: #848a96;
$radius: 4px;
$form-brd: #b1b8bb;

/*Font Weigth Variable*/
$fw-regular:400;
$fw-medium:500;
$fw-semi:600;
$fw-bold:700;

// new design system colors

$new-text-dark: #151F6D;
$new-neat-secondary: #F04E98;
$new-text-gray: #A1A5C5;

$new-warning-1: #FFECD1;
$new-warning-2: #FF9E1B;
$new-warning-3: #CC7E16;