/* a.neat-green-btn-gradient,
button.neat-green-btn-gradient, */

a.neat-green-btn,
button.neat-green-btn,
a.mat-raised-button,
button.mat-raised-button,
a.mat-stroked-button.mat-accent,
button.mat-stroked-button.mat-accent,
a.mat-raised-button.mat-primary,
button.mat-raised-button.mat-primary,
a.mat-stroked-button.mat-primary,
button.mat-stroked-button.mat-primary {
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

a.mat-stroked-button.mat-primary:hover,
button.mat-stroked-button.mat-primary:hover {
    background-color: darken(#fff, 2%) !important;
}

a.mat-stroked-button.mat-accent:hover,
button.mat-stroked-button.mat-accent:hover {
    background-color: darken(#fff, 2%) !important;
}

a.mat-stroked-button.mat-warn:hover,
button.mat-stroked-button.mat-warn:hover {
    background-color: darken(#fff, 2%) !important;
}

a.mat-stroked-button.mat-secundary-custom-custom:hover,
button.mat-stroked-button.mat-secundary-custom:hover {
    background-color: #F7F9FD !important;
}

a.mat-raised-button.mat-primary,
button.mat-raised-button.mat-primary {
    color: #fff !important;
}

a.mat-raised-button.mat-primary:hover,
button.mat-raised-button.mat-primary:hover {
    background-color: darken($neat-color-blue, 10%) !important;
}

.custom-btn {
    color: white;
    background: #3369F0 !important;
    border: 0.648961px solid #3369F0 !important;
    box-sizing: border-box !important;
    box-shadow: 0px 3.2448px 6.48961px rgba(0, 0, 0, 0.15) !important;
    border-radius: 64.8961px !important;
    padding: 1.3834px 46.3626px !important;
}

.custom-btn-no-color {
    box-sizing: border-box !important;
    box-shadow: 0px 3.2448px 6.48961px rgba(0, 0, 0, 0.15) !important;
    border-radius: 64.8961px !important;
}

.custom-btn-big {
    color: white;
    background: #3369F0 !important;
    border: 0.648961px solid #3369F0 !important;
    box-sizing: border-box !important;
    box-shadow: 0px 3.2448px 6.48961px rgba(0, 0, 0, 0.15) !important;
    border-radius: 64.8961px !important;
    padding: 4px 46.3626px !important;
    font-weight: 600 !important;
    font-size: 16px !important;
}

.custom-btn-no-color-big {
    box-sizing: border-box !important;
    box-shadow: 0px 3.2448px 6.48961px rgba(0, 0, 0, 0.15) !important;
    border-radius: 64.8961px !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    padding: 4px 46.3626px !important;
}

.custom-btn-secundary {
    color: #3369F0 !important;
    background: #F4F8FE !important;
    border: 0.648961px solid #3369F0 !important;
    box-sizing: border-box !important;
    border-radius: 64.8961px !important;
}

.custom-btn-secundary-2 {
    min-height: 39px !important;
    color: #3B4086 !important;
    background: #FFFFFF !important;
    border: 0.648961px solid #3369F0 !important;
    box-sizing: border-box !important;
    border-radius: 64.8961px !important;
}

.swal-custom-btn {
    min-height: 40px !important;
    border: 1px solid #3369F0 !important;
    box-sizing: border-box !important;
    background: #3369F0 !important;
    border-radius: 100px !important;
    padding: 1.5px 32px !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #FCFDFF !important;
}

.swal-custom-btn-secundary-2 {
    padding: 1.5px 32px !important;
    min-height: 40px !important;
    background: #F4F8FE !important;
    border: 1px solid #3369F0 !important;
    border-radius: 100px !important;
    box-sizing: border-box !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #3B4086 !important;
}

.custom-min-width {
    min-width: 130px !important;
}

.swal-custom-img {
    display: flex !important;
    width: 100% !important;
    max-width: 100px !important;
    margin-bottom: 5px !important;
    height: unset !important;
}

.fixed-width {
    width: 100%;
    max-width: 210px;
}

a.mat-raised-button.mat-accent-neat.mat-accent,
button.mat-raised-button.mat-accent-neat.mat-accent {
    background-color: darken(#ffeb3b, 15%) !important;
    color: $neat-color-blue !important;
    &:hover {
        background-color: darken(#ffeb3b, 20%) !important;
        color: $neat-color-blue !important;
    }
}

a.mat-raised-button.mat-accent:hover,
button.mat-raised-button.mat-accent:hover {
    background-color: darken(#ffeb3b, 10%) !important;
    color: #fff !important;
}

a.mat-raised-button.mat-warn:hover,
button.mat-raised-button.mat-warn:hover {
    background-color: darken(#f44336, 10%) !important;
}

a.mat-raised-button.mat-secundary-custom,
button.mat-raised-button.mat-secundary-custom {
    border: 0.648961px solid #026BEA;
    color: #3368f0;
    background-color: #F7F9FD !important;
}


a.mat-raised-button.mat-secundary-custom:hover,
button.mat-raised-button.mat-secundary-custom:hover {
    border: 0.648961px solid #026BEA;
    color: #3368f0;
    background-color: darken(#F7F9FD, 2%) !important;
}

.neat-green-btn-gradient {
    background-image: linear-gradient( to right, darken(#a0c600, 2%), lighten(#a0c600, 4%));
    color: #fff !important;
    &[disabled],
    &:disabled {
        background: rgba(black, 0.12) !important;
    }
}

.btn-hover {
    background-size: 300% 100%;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

.btn-hover:hover {
    background-position: 100% 0;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

.btn-hover:focus {
    outline: none;
}

.neat-green-btn {
    color: #fff !important;
    background-color: #a0c600;
    &[disabled],
    &:disabled {
        background-color: rgba(black, 0.12) !important;
    }
}

.neat-green-btn:hover {
    background-color: darken(#a0c600, 5%);
}

.neat-green-cmr {
    color: #fff !important;
    background-color: #42ba3f;
    &[disabled],
    &:disabled {
        background-color: rgba(black, 0.12) !important;
    }
    &:hover {
        background-color: lighten(#42ba3f, 5%);
    }
}

.mat-button.mat-btn-sm,
.mat-raised-button.mat-btn-sm,
.mat-stroked-button.mat-btn-sm {
    min-width: 1px;
    min-height: 1px;
    height: 1px;
    width: 1px;
}

.mat-button.mat-btn-tooltip,
.mat-raised-button.mat-btn-tooltip,
.mat-stroked-button.mat-btn-tooltip {
    position: absolute;
    top: -12px;
    right: 10%;
    z-index: 9;
}

.btn-sm {
    min-width: unset !important;
    padding: 0 10px !important;
    @media (max-width: 991px) {
        width: 35px;
        height: 35px;
    }
    i {
        @media (max-width: 991px) {
            font-size: 1rem;
        }
    }
}


/* ::ng-deep */

.mat-checkbox-layout {
    white-space: normal !important;
}

// @media (max-width: 479px) {
@media (max-width: 767px) {
    .mat-horizontal-stepper-header .mat-step-icon,
    .mat-horizontal-stepper-header .mat-step-icon-not-touched {
        margin-right: 0 !important;
    }
    .mat-step-label {
        display: none !important;
    }
}

.mat-tooltip {
    @media (min-width: 768px) {
        font-size: 0.875rem !important;
    }
    @media (max-width: 767px) {
        font-size: 0.75rem !important;
    }
    display: flex;
    position: fixed;
}

input.mat-input-element {
    @media (max-width: 767px) {
        font-size: 0.75rem !important;
    }
}

.mat-select-value-text {
    @media (max-width: 767px) {
        font-size: 0.75rem !important;
    }
}

.mat-button-base {
    &.btn-long-text {
        .mat-button-wrapper {
            white-space: normal !important;
            /* @media (max-width: 767px) {
            } */
        }
    }
    font-size: 0.75rem !important;
}


/* Style Placeholders */

::-webkit-input-placeholder {
    font-size: 0.75rem !important;
}

::-moz-placeholder {
    font-size: 0.75rem !important;
}

:-ms-input-placeholder {
    font-size: 0.75rem !important;
}

::-ms-input-placeholder {
    font-size: 0.75rem !important;
}

::placeholder {
    font-size: 0.75rem !important;
}

::ng-deep .mat-select-placeholder {
    @media (max-width: 767px) {
        font-size: 0.75rem !important;
    }
}

::ng-deep .mat-input-placeholder {
    @media (max-width: 767px) {
        font-size: 0.75rem !important;
    }
}

.mat-form-field-label {
    @media (max-width: 767px) {
        font-size: 0.75rem !important;
    }
}

.mat-form-field-appearance-legacy .mat-form-field-label {
    @media (max-width: 767px) {
        top: 1.625em !important;
    }
}

.dashboard-payment {
    .mat-horizontal-content-container {
        padding: 6px 24px 24px 24px !important;
        @media (max-width: 767px) {
            padding: unset !important;
        }
    }
}



.dashboard-payment-final {
    .mat-horizontal-content-container {
        @media (min-width: 768px) {
            padding: unset !important;
        }
    }
}

.mat-error {
    font-size: 0.625rem !important;
}

.mat-hint {
    padding: 1px 0;
}

.mat-tab-label {
    opacity: 1 !important;
    border: 2px solid rgba(0, 0, 0, 0.6) !important;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.mat-tab-label.mat-tab-label-active {
    background-color: $neat-color-blue !important;
    color: #fff !important;
    border: 2px solid #fff !important;
}

.mat-step-label.mat-step-label-active.mat-step-label-selected {
    font-size: calc(100% + 4px);
    color: $neat-color-blue;
}

.mat-step-icon-state-number.mat-step-icon.mat-step-icon-selected {
    font-size: calc(100% + 4px);
}

.mat-step-icon-state-edit.mat-step-icon {
    background-color: rgb(15, 157, 88) !important;
}

.payment {

@mixin styleStepLine($index) {
    .mat-horizontal-stepper-header {
        &+.mat-stepper-horizontal-line:nth-child(#{$index}) {
            height: 2px;
            background-image: linear-gradient(to right, #00aeea, #3369F0);
        }
    }
}

@mixin styleEditedStepIcon($index) {
    .mat-horizontal-stepper-header:nth-child(#{$index}) {
        .mat-step-icon:not(.mat-step-icon-selected) {
            background-color: red;
        }
    }
}

@mixin styleUnEditedStepIcon($index) {
    .mat-horizontal-stepper-header:nth-child(#{$index}) {
        .mat-step-icon:not(.mat-step-icon-selected) {
            background-color: #e8e8e8;
        }
    }
}

.last-edited-step-1 {
    @include styleStepLine(2);
}

.last-edited-step-2 {
    @include styleStepLine(2);
    @include styleStepLine(4);
}
.mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::before, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::after, [dir=rtl] .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::after, [dir=rtl] .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::before {width: 0!important;}


.mat-step-header .mat-step-header-ripple {display: none;}
.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover {
    background-color: #fff;
}
.mat-stepper-label-position-bottom .mat-horizontal-stepper-header {
    padding: 8px 0 8px 0 !important;
    // width: 35px !important;
}
.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 20px !important;
}

}


.mat-button,
.mat-raised-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-fab,
.mat-mini-fab {
    font-family: $mainfont !important;
}

.rounded-btn {
    border-radius: 100px !important;
}

.confirm-btn {
    color: white !important;
    background: #3369F0 !important;
    border: 0.648961px solid #3369F0 !important;
    box-sizing: border-box !important;
    box-shadow: 0px 3.2448px 6.48961px rgba(0, 0, 0, 0.15) !important;
    border-radius: 100px !important;
    padding: 1.3834px 46.3626px !important;
    font-size: 14px !important;
    font-weight: 300 !important;
    @media (max-width: 767px) {
        font-size: 12px !important;
    }
}

.off-white-btn {
    color: #3B4086 !important;
    box-sizing: border-box !important;
    box-shadow: 0px 3.2448px 6.48961px rgba(0, 0, 0, 0.15) !important;
    padding: 1.3834px 46.3626px !important;
    background: #F4F8FE !important;
    border: 1px solid #3369F0 !important;
    border-radius: 100px !important;
    :hover {
        background: unset !important;
    }
}

