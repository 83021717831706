// Base class
.tooltip {
    position: absolute;
    z-index: 1070;
    display: flex;
    justify-content: center;
    margin: 0;
    // Our parent element can be arbitrary since tooltips are by default inserted as a sibling of their target element.
    // So reset our font and text properties to avoid inheriting weird values.
    @include reset-text();
    @media (min-width: 768px) {
        font-size: 0.875rem !important;
    }
    @media (max-width: 767px) {
        font-size: 0.75rem !important;
    }
    // Allow breaking very long words so they don't overflow the tooltip's bounds
    word-wrap: break-word;
    opacity: 0;
    &.show {
        opacity: 1;
        // zoom: 1;
    }
    .arrow {
        position: absolute;
        // display: block;
        display: none;
        width: 0.8rem;
        height: 0.4rem;
        &::before {
            position: absolute;
            content: '';
            border-color: transparent;
            border-style: solid;
        }
    }
}

.bs-tooltip-top {
    padding: 0.4rem 0;
    .arrow {
        bottom: 0;
        &::before {
            top: 0;
            border-width: 0.4rem (0.8rem * 0.5) 0;
            border-top-color: rgba(0, 0, 0, 0.6);
        }
    }
}

.bs-tooltip-right {
    padding: 0 0.4rem;
    .arrow {
        left: 0;
        width: 0.4rem;
        height: 0.8rem;
        &::before {
            right: 0;
            border-width: (0.8rem * 0.5) 0.4rem (0.8rem * 0.5) 0;
            border-right-color: rgba(0, 0, 0, 0.6);
        }
    }
}

.bs-tooltip-bottom {
    padding: 0.4rem 0;
    .arrow {
        top: 0;
        &::before {
            bottom: 0;
            border-width: 0 (0.8rem * 0.5) 0.4rem;
            border-bottom-color: rgba(0, 0, 0, 0.6);
        }
    }
}

.bs-tooltip-left {
    padding: 0 0.4rem;
    .arrow {
        right: 0;
        width: 0.4rem;
        height: 0.8rem;
        &::before {
            left: 0;
            border-width: (0.8rem * 0.5) 0 (0.8rem * 0.5) 0.4rem;
            border-left-color: rgba(0, 0, 0, 0.6);
        }
    }
}

.bs-tooltip-auto {
    &[x-placement^='top'] {
        @extend .bs-tooltip-top;
    }
    &[x-placement^='right'] {
        @extend .bs-tooltip-right;
    }
    &[x-placement^='bottom'] {
        @extend .bs-tooltip-bottom;
    }
    &[x-placement^='left'] {
        @extend .bs-tooltip-left;
    }
}

// Wrapper for the tooltip content
.tooltip-inner {
    /* max-width: 300px;
    color: #fff;
    border-radius: 0.25rem; */
    font-weight: 300;
    // padding: 0.25rem 0.5rem;
    background-color: rgba(97, 97, 97, 0.9);
    color: #fff;
    // font-family: Roboto, 'Helvetica Neue', sans-serif;
    font-family: $font-roboto;
    padding-top: 6px;
    align-items: left;
    padding-bottom: 6px;
    border-radius: 4px;
    // margin: 14px;
    max-width: 250px;
    padding-left: 8px;
    padding-right: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
}